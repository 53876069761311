export const useLocalSearchView = (namespace) => {
	const storageKey = generateStorageViewKey(namespace);

	const clearSearchView = () => localStorage.removeItem(storageKey);

	const storeSearchView = (viewParameters) => {
		localStorage.setItem(storageKey, JSON.stringify(viewParameters));
	};

	const getStoredSearchView = () => {
		let view = JSON.parse(localStorage.getItem(storageKey));

		if (view?.columns && namespace === "accidents-and-incidents") {
			const replacementDictionary = {
				event_accident_or_incident: "write_off",
			};

			const originalColumns = view.columns.split(",");
			const containsOldColumns = Object.entries(
				replacementDictionary,
			).some(([oldName]) => originalColumns.includes(oldName));

			if (containsOldColumns) {
				view.columns = originalColumns
					.map((column) => replacementDictionary[column] ?? column)
					.join(",");
				storeSearchView(view);
			}
		}

		return view;
	};

	return {
		getStoredSearchView,
		storeSearchView,
		clearSearchView,
	};
};

export const generateStorageViewKey = (namespace) =>
	`search_view_data-${namespace}`;
