import React from "react";
import useSelectAll from "../../criteria/components/useSelectAll";
import useSearchState from "../../useSearchState";
import useFavorites from "../useFavorites";

export function FavoritesSelectAllCell() {
	const { rows } = useSearchState([""]);
	const { selectedCodes, onInputChange } = useFavorites();

	const codes = rows
		.map((row) => ({ key: row.getIn(["airline", "code"]) }))
		.toArray();
	const { allChecked, onSelectAll } = useSelectAll(
		codes,
		selectedCodes,
		onInputChange,
	);

	return (
		<th className="select-all">
			<div className="checkbox-input">
				<input
					type="checkbox"
					id="selectAll"
					title="Select all on page"
					aria-label="Select all on page"
					onChange={(e) => onSelectAll(e.target.checked)}
					checked={allChecked}
				/>
			</div>
		</th>
	);
}
