import { parseISO } from "date-fns";
import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { formatInput, inputToDate, isInputValidDate } from "../range/inputs";
import { DatePickerHeader } from "./DatePickerHeader";

export const DatePickerWrapper = ({
	id,
	filterDate,
	onChange,
	value,
	years,
}) => {
	const selected = value ? parseISO(value) : "";
	const [input, setInput] = useState(() => formatInput(selected));

	useEffect(() => {
		if (value === "") {
			setInput(value);
		}
	}, [value]);

	const handleChange = (e) => {
		const input = e.target.value.toUpperCase();

		setInput(input);

		if (isInputValidDate(input)) {
			onChange(format(inputToDate(input), "yyyy-MM-dd"));
		}
	};

	const handleDatePickerChange = (date) => {
		setInput(formatInput(date));
		onChange(format(date, "yyyy-MM-dd"));
	};

	const handleBlur = () => {
		if (!isInputValidDate(input)) {
			setInput(formatInput(selected));
		}
	};

	return (
		<>
			<InputMask
				type="text"
				id={id}
				name={id}
				value={input}
				mask="99aaa9999"
				maskChar=""
				onChange={handleChange}
				onBlur={handleBlur}
				data-criterion
			/>
			<DatePicker
				dateFormat="yyyy-MM-dd"
				disabledKeyboardNavigation
				data-criterion
				filterDate={filterDate}
				id={id}
				inline
				onChange={handleDatePickerChange}
				renderCustomHeader={(props) => (
					<DatePickerHeader {...props} years={years} />
				)}
				selected={selected}
				shouldCloseOnSelect={false}
				showMonthDropdown={true}
				showYearDropdown={true}
			/>
		</>
	);
};

DatePickerWrapper.propTypes = {
	id: PropTypes.string.isRequired,
	filterDate: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	years: PropTypes.array.isRequired,
};
