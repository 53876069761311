import {
	USER_ROLE_FLEET_HISTORY,
	USER_ROLE_FLEET_HISTORY_BIZAV,
	USER_ROLE_FLEET_IFE_IFC,
	USER_ROLE_FLEET_OWNERSHIP,
	USER_ROLE_FLEET_OWNERSHIP_BIZAV,
	USER_ROLE_FLEETS,
	USER_ROLE_FLEETS_BIZAV,
	USER_ROLE_HOURS_AND_CYCLES,
} from "./constants";

export const rolesDependencies = {
	[USER_ROLE_FLEETS]: [
		USER_ROLE_FLEET_HISTORY,
		USER_ROLE_FLEET_OWNERSHIP,
		USER_ROLE_HOURS_AND_CYCLES,
		USER_ROLE_FLEET_IFE_IFC,
	],
	[USER_ROLE_FLEETS_BIZAV]: [
		USER_ROLE_FLEET_HISTORY_BIZAV,
		USER_ROLE_FLEET_OWNERSHIP_BIZAV,
	],
};
