import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

export function Loader({ classes, label = "Loading" }) {
	return <span className={clsx("loader", classes)}>{label}</span>;
}

Loader.propTypes = {
	classes: PropTypes.string,
	label: PropTypes.string,
};
