import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenuButton } from "../../../search/actions/ActionsMenuButton";
import { childrenType } from "../../types";
import { Logo } from "../Logo";
import { CloseButton } from "./CloseButton";
import useEscapeKey from "./useEscapeKey";
import useModal from "./useModal";

export function Modal({
	additionalClasses,
	alignContent,
	modalKey,
	title,
	children,
}) {
	const { openedModalsExist, onCloseModal } = useModal();
	useEscapeKey(() => onCloseModal(modalKey));

	const classes = clsx("modal", additionalClasses, {
		open: openedModalsExist,
	});

	return (
		<div role="dialog" aria-labelledby="modalTitle" className={classes}>
			<div
				className={clsx("modal__container", alignContent)}
				onClick={(e) => e.stopPropagation()}
			>
				<header className="modal__header">
					<Logo />
					<ActionsMenuButton
						onClick={() => onCloseModal(modalKey)}
						type="btn--back"
						name="Back"
					/>
					<h2 id="modalTitle" className="modal__title">
						{title}
					</h2>
					<CloseButton
						classes="btn--close"
						onClick={onCloseModal}
						modalKey={modalKey}
					/>
				</header>

				{children}
			</div>
		</div>
	);
}

Modal.propTypes = {
	additionalClasses: PropTypes.string,
	alignContent: PropTypes.string,
	children: childrenType.isRequired,
	modalKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
