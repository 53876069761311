import React from "react";
import noResultsImage from "../../../../www/static/img/icons/icon-no-search-results.svg";
import { additionalSearchLinkType, errorType } from "../common/types";

export function NoSearchResults({ error, additionalSearchLink }) {
	const getNoResultsContent = () => {
		if (additionalSearchLink) {
			return additionalSearchLink;
		}

		if (error) {
			return (
				<>
					<h5>{error.message}</h5>
					<p>
						Please change your search parameters or contact us on{" "}
						<a href="mailto: support@ch-aviation.com">
							support@ch-aviation.com
						</a>
						.
					</p>
				</>
			);
		}

		return (
			<>
				<h5>No results</h5>
				<p>
					There are no results at the moment. <br />
					Start searching by filter.
				</p>
			</>
		);
	};

	return (
		<div className="no-search-results-wrapper">
			<div className="no-search-results grid">
				<img src={noResultsImage} alt="No results" />
				{getNoResultsContent()}
			</div>
		</div>
	);
}

NoSearchResults.propTypes = {
	error: errorType,
	additionalSearchLink: additionalSearchLinkType,
};
