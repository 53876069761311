import { onContentLoad } from "./onContentLoad";
import { addOverlay, removeOverlay } from "./utils";

export function initModals() {
	onContentLoad(function () {
		const modalButtons = document.querySelectorAll("[data-modal]");

		modalButtons.forEach((button) => {
			button.addEventListener("click", (e) => {
				e.preventDefault();
				initOpenedModal(button.dataset.modal);
				button.blur();
			});
		});
	});

	function initOpenedModal(id) {
		const modal = document.getElementById(id);
		const closeButtons = modal.querySelectorAll('[data-dismiss="modal"]');

		// Close other active modals
		const openedModals = document.querySelectorAll(
			'[aria-expanded="true"]',
		);
		openedModals.forEach((modal) => {
			closeModal(modal.getAttribute("id"));
		});

		openModal(id);

		closeButtons.forEach((close) => {
			close.addEventListener("click", (e) => {
				e.preventDefault();
				closeModal(id);
			});
		});

		if (modal.classList.contains("modal")) {
			modal.addEventListener("click", (e) => {
				if (!e.target.closest(".modal__container")) {
					closeModal(id);
				}
			});
		}

		document.addEventListener("keydown", (event) => {
			if (event.key === "Escape") {
				closeModal(id);
			}
		});
	}
}

export function openModal(id) {
	const modal = document.getElementById(id);

	if (!modal.classList.contains("open")) {
		modal.classList.add("open");
		modal.setAttribute("aria-expanded", "true");
		addOverlay();
	}
}

export function closeModal(id) {
	const modal = document.getElementById(id);

	if (modal.classList.contains("open")) {
		const errors = modal.querySelectorAll(".error");

		modal.classList.remove("open");
		modal.setAttribute("aria-expanded", "false");
		removeOverlay();

		if (errors.length > 0) {
			errors.forEach((error) => {
				error.classList.remove("error");
			});
		}
	}
}
