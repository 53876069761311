import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useAccessWindowSize } from "../../common/layout/useAccessWindowSize";
import { TextInput } from "../criteria/TextInput";

export function SearchNameInput({ error, onChange }) {
	const { isMobile } = useAccessWindowSize();

	return (
		<div className={clsx("form-group", { error })}>
			<TextInput
				id="search-name"
				name="search"
				placeholder="Enter search name"
				label="Search name"
				error={error}
				autoFocus={!isMobile}
				onChange={onChange}
			/>
		</div>
	);
}

SearchNameInput.propTypes = {
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
