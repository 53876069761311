import { ValidationResult } from "../../validation/validationResult";

export const forecastingValidation = (currentInput, key) => {
	const input = currentInput.get(key);
	const inputExists = input && !input.isEmpty();

	if (inputExists && amountCheck(input).isError()) {
		return amountCheck(input);
	}

	if (inputExists && dateRangeCheck(input).isError()) {
		return dateRangeCheck(input);
	}

	return ValidationResult.success();
};

export const amountCheck = (input) => {
	const amount = input.get("amount");
	const type = input.get("type");

	if (!amount) {
		return ValidationResult.error(
			"Flight Hours or Cycles is mandatory value and has to be higher than zero.",
		);
	}

	if (type === "hours" && amount <= 0) {
		return ValidationResult.error(
			"Flight hours is mandatory value and has to be higher than zero.",
		);
	}

	if (type === "cycles" && amount < 1) {
		return ValidationResult.error(
			"Flight cycles is mandatory value and has to be whole number higher than zero.",
		);
	}

	if (type === "cycles" && amount.indexOf(".") !== -1) {
		return ValidationResult.error("Flight cycles has to be whole number.");
	}

	if (!isFinite(amount)) {
		return ValidationResult.error(
			"Flight Hours or Cycles value has to be number.",
		);
	}

	return ValidationResult.success();
};

export const dateRangeCheck = (input) => {
	const toYear = parseInt(input.get("to_year"));
	const toMonth = parseInt(input.get("to_month"));
	const fromYear = parseInt(input.get("from_year"));
	const fromMonth = parseInt(input.get("from_month"));

	if (toYear < fromYear || (toYear === fromYear && toMonth < fromMonth)) {
		return ValidationResult.error("Date range is not correct.");
	}

	return ValidationResult.success();
};
