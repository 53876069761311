import React from "react";
import { Provider } from "react-redux";
import { SearchContainer } from "./SearchContainer";

export function renderSearch(
	store,
	searchDefinition,
	Container = SearchContainer,
) {
	return (
		<Provider store={store}>
			<Container definition={searchDefinition} />
		</Provider>
	);
}
