import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	receiveFavorites,
	receiveFavoritesFailed,
	requestFavorites,
} from "./actions";
import { api } from "./api";
import {
	getFavoritesFetchError,
	isFetchingFavorites,
	shouldFetchFavorites,
} from "./selectors";

export default function useLoadFavorites() {
	const dispatch = useDispatch();
	const shouldFetch = useSelector(shouldFetchFavorites);
	const fetching = useSelector(isFetchingFavorites);
	const fetchError = useSelector(getFavoritesFetchError);

	useEffect(() => {
		fetchFavorites();
	}, [shouldFetch]);

	function fetchFavorites() {
		if (shouldFetch) {
			dispatch(requestFavorites());

			api().load(
				(data) => {
					dispatch(receiveFavorites(data));
				},
				(error) => {
					dispatch(receiveFavoritesFailed(error));
				},
			);
		}
	}

	return { fetching, fetchError };
}
