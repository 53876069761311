import PropTypes from "prop-types";
import React from "react";

export default function CriterionSelectAll({
	criterionKey,
	quickSearchId,
	isVisible,
	allChecked,
	onSelectAll,
}) {
	if (!isVisible) {
		return null;
	}

	return (
		<div className="checkbox-input">
			<input
				type="checkbox"
				id={`select_all_${criterionKey}${quickSearchId}`}
				name={`select_all_${criterionKey}`}
				value={`select_all_${criterionKey}`}
				checked={allChecked}
				onChange={(e) => onSelectAll(e.target.checked)}
			/>
			<label htmlFor={`select_all_${criterionKey}${quickSearchId}`}>
				Select all
			</label>
		</div>
	);
}

CriterionSelectAll.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	quickSearchId: PropTypes.string.isRequired,
	isVisible: PropTypes.bool.isRequired,
	allChecked: PropTypes.bool.isRequired,
	onSelectAll: PropTypes.func.isRequired,
};
