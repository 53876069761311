import PropTypes from "prop-types";
import React from "react";
import { columnsVisibleType } from "../../../../common/types";

export const SearchResultsSubheaderRow = ({
	columnsVisible,
	total,
	columnSubheaderGenerator,
}) => {
	if (!columnSubheaderGenerator) {
		return null;
	}

	return (
		<tr className="results-table-subheader">
			{columnSubheaderGenerator(columnsVisible, total)}
		</tr>
	);
};

SearchResultsSubheaderRow.propTypes = {
	columnsVisible: columnsVisibleType,
	total: PropTypes.number,
	columnSubheaderGenerator: PropTypes.func,
};
