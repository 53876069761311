import { useSelector } from "react-redux";
import { hasOneOfRoles, hasRoles } from "./selectors";

export default function useRolesVisibility(requiredRoles) {
	const showForRoles = requiredRoles?.getShowForRoles() || [];

	const hasRequiredRoles = useSelector((state) =>
		hasRoles(state, showForRoles),
	);

	const hasOneOfRequiredRoles = useSelector((state) =>
		hasOneOfRoles(state, showForRoles),
	);

	return {
		hasRequiredRoles,
		hasOneOfRequiredRoles,
	};
}
