import { waitForElement } from "../../../ui/common/waitForElement";

export function toggleScrollbarVisibility() {
	const horizontalScrollSelector = ".results-scroller-track-x";

	waitForElement(horizontalScrollSelector).then(() => {
		const searchResults = document.querySelector(".results");
		const horizontalScroll = document.querySelector(
			horizontalScrollSelector,
		);
		const horizontalScrollVisible =
			document.querySelector(horizontalScrollSelector)?.style.display !==
			"none";

		if (horizontalScrollVisible && searchResults) {
			addEventListener("scroll", () => {
				if (
					horizontalScrollVisible &&
					isScrolledIntoView(searchResults)
				) {
					horizontalScroll.style.display = "block";
				} else {
					horizontalScroll.style.display = "none";
				}
			});
		}

		function isScrolledIntoView(el) {
			const rect = el.getBoundingClientRect();

			return rect.top < window.innerHeight && rect.bottom >= 0;
		}
	});
}
