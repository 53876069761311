import PropTypes from "prop-types";
import React from "react";
import useSearchState from "../useSearchState";

export const SearchesActionsSaveButton = ({ updateSearch, current }) => {
	const { currentInput } = useSearchState();

	const handleSaveSearchClick = () => {
		updateSearch(current, currentInput.toJS());
	};

	return (
		<button
			type="button"
			className="btn btn--secondary btn--divided btn--has-icon btn--save"
			onClick={handleSaveSearchClick}
		>
			<span>Save...</span>
		</button>
	);
};

SearchesActionsSaveButton.propTypes = {
	updateSearch: PropTypes.func.isRequired,
	current: PropTypes.object.isRequired,
};
