import React from "react";
import { MODAL_ADVANCED_SEARCH } from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";

export function AdvancedSearch() {
	const { onOpenModal } = useModal();

	const handleAdvancedSearchClick = (e) => {
		e.preventDefault();
		onOpenModal(MODAL_ADVANCED_SEARCH);
	};

	return (
		<button
			type="button"
			id="btn--advanced"
			className="btn btn--primary btn--has-icon btn--advanced"
			onClick={handleAdvancedSearchClick}
		>
			<span>Advanced Search</span>
		</button>
	);
}
