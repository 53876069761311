import { useDispatch, useSelector } from "react-redux";
import { addToast, clearToasts, deleteToast } from "./actions";
import { getToasts } from "./selectors";

export default function useToasts() {
	const dispatch = useDispatch();
	const toasts = useSelector(getToasts);

	const onAddToast = (key) => {
		dispatch(addToast(key));
	};

	const onCloseToast = (key) => {
		dispatch(deleteToast(key));
	};

	const onClearToasts = () => {
		dispatch(clearToasts());
	};

	return {
		toasts,
		onAddToast,
		onCloseToast,
		onClearToasts,
	};
}
