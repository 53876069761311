import React from "react";
import {
	footnotesType,
	pagingType,
	resultTableConfigType,
} from "../../../common/types";

export const SearchResultsFactory = ({
	resultTableConfig,
	footnotes,
	paging,
}) => {
	const {
		columns,
		resultRenderConfig,
		resultsDisplay: Results,
	} = resultTableConfig;

	return (
		<>
			<Results
				resultRenderConfig={resultRenderConfig(columns)}
				footnotes={footnotes}
				paging={paging}
			/>

			{/*Copy of table with all results for printing*/}
			<Results
				resultRenderConfig={resultRenderConfig(columns)}
				footnotes={footnotes}
				forPrint
				paging={paging}
			/>
		</>
	);
};

SearchResultsFactory.propTypes = {
	footnotes: footnotesType,
	paging: pagingType.isRequired,
	resultTableConfig: resultTableConfigType.isRequired,
};
