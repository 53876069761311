import { useSelector } from "react-redux";
import { getQuickSearchCriteria, isLoadingCriteria } from "./selectors";

export const useAccessQuickSearchCriteria = (fallbackCriteria) => {
	const quickSearchCriteriaLoading = useSelector(isLoadingCriteria);
	const quickSearchCriteria = useSelector(getQuickSearchCriteria)?.toJS();

	return {
		quickSearchCriteriaLoading,
		quickSearchCriteria: quickSearchCriteria ?? fallbackCriteria,
	};
};
