export const FAVORITES_GET_LISTS = "FAVORITES_GET_LISTS";
export const FAVORITES_GET_LISTS_FAILED = "FAVORITES_GET_LISTS_FAILED";
export const FAVORITES_LISTS = "FAVORITES_LISTS";
export const FAVORITES_UPDATE_LISTS = "FAVORITES_UPDATE_LISTS";
export const FAVORITES_LISTS_UPDATED = "FAVORITES_LISTS_UPDATED";
export const FAVORITES_LIST_ADDED = "FAVORITES_LIST_ADDED";
export const FAVORITES_UPDATE_LISTS_FAILED = "FAVORITES_UPDATE_LISTS_FAILED";
export const FAVORITES_CODES_CHANGED = "FAVORITES_CODES_CHANGED";
export const FAVORITES_RESET_CODES_SELECTED = "FAVORITES_RESET_CODES_SELECTED";
export const FAVORITES_MODAL_CONTENT = "FAVORITES_MODAL_CONTENT";
export const FAVORITES_ADD_ALL_RESULTS = "FAVORITES_ADD_ALL_RESULTS";
export const FAVORITES_ADD_SELECTED_RESULTS = "FAVORITES_ADD_SELECTED_RESULTS";
export const FAVORITES_DELETE_ENTITIES = "FAVORITES_DELETE_ENTITIES";
export const FAVORITES_DELETE_ENTITIES_FAILED =
	"FAVORITES_DELETE_ENTITIES_FAILED";
export const FAVORITES_ENTITIES_DELETED = "FAVORITES_ENTITIES_DELETED";

export function requestFavorites() {
	return {
		type: FAVORITES_GET_LISTS,
		data: {},
	};
}

export function receiveFavoritesFailed(error) {
	return {
		type: FAVORITES_GET_LISTS_FAILED,
		data: {
			error,
		},
	};
}

export function receiveFavorites(data) {
	return {
		type: FAVORITES_LISTS,
		data: {
			favoriteLists: data,
		},
	};
}

export function favoritesCodesChanged(data) {
	return {
		type: FAVORITES_CODES_CHANGED,
		data: {
			codes: data,
		},
	};
}

export function setFavoritesModalContent(data) {
	return {
		type: FAVORITES_MODAL_CONTENT,
		data: {
			modal: data,
		},
	};
}

export function addAllResults() {
	return {
		type: FAVORITES_ADD_ALL_RESULTS,
		data: {},
	};
}

export function addSelectedResults() {
	return {
		type: FAVORITES_ADD_SELECTED_RESULTS,
		data: {},
	};
}

export function requestUpdateFavorites() {
	return {
		type: FAVORITES_UPDATE_LISTS,
		data: {},
	};
}

export function favoritesUpdated(favoriteLists) {
	return {
		type: FAVORITES_LISTS_UPDATED,
		data: {
			favoriteLists,
		},
	};
}

export function favoritesListAdded() {
	return {
		type: FAVORITES_LIST_ADDED,
		data: {},
	};
}

export function updateFavoritesFailed(error) {
	return {
		type: FAVORITES_UPDATE_LISTS_FAILED,
		data: {
			error,
		},
	};
}

export function requestDeleteEntities() {
	return {
		type: FAVORITES_DELETE_ENTITIES,
		data: {},
	};
}

export function receiveDeleteEntitiesFailed(error) {
	return {
		type: FAVORITES_DELETE_ENTITIES_FAILED,
		data: { error },
	};
}

export function receiveEntitiesDeleted() {
	return {
		type: FAVORITES_ENTITIES_DELETED,
		data: {},
	};
}
