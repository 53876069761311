import PropTypes from "prop-types";
import React from "react";
import useModal from "./useModal";

export function Modals({ modalsList, renderDefault }) {
	const { openedModalsExist, openedModals } = useModal();

	const renderModal = (name) => {
		if (!name || !modalsList[name]) {
			return null;
		}

		return modalsList[name];
	};

	return openedModalsExist
		? openedModals.map((name) => (
				<React.Fragment key={name}>{renderModal(name)}</React.Fragment>
			))
		: renderModal(renderDefault);
}

Modals.propTypes = {
	modalsList: PropTypes.object.isRequired,
	renderDefault: PropTypes.string,
};
