import PropTypes from "prop-types";

export function ConditionalWrapper({ condition, wrapper, children }) {
	return condition ? wrapper(children) : children;
}

ConditionalWrapper.propTypes = {
	condition: PropTypes.bool.isRequired,
	wrapper: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};
