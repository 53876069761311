import Immutable from "immutable";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "../../search/result/useSearch";
import { inputFieldChanged } from "../input/actions";
import { getCurrentFieldInput } from "../input/selectors";

export default function useFormComponentState(criterionKey, defaultValue) {
	const dispatch = useDispatch();
	const { onSearch } = useSearch();
	const currentInput =
		useSelector((state) => getCurrentFieldInput(state, criterionKey)) ||
		defaultValue;

	const onInputChange = (input) => {
		dispatch(inputFieldChanged(criterionKey, input));
	};

	const onInputChangeAndSearch = (input) => {
		onInputChange(input);
		onSearch();
	};

	const onClearInput = () => {
		dispatch(
			inputFieldChanged(
				criterionKey,
				Immutable.Iterable.isIterable(currentInput)
					? currentInput.clear()
					: defaultValue,
			),
		);
	};

	return {
		currentInput,
		onInputChange,
		onInputChangeAndSearch,
		onClearInput,
	};
}
