import clsx from "clsx";

export function getDropdownClasses(
	dropdownItems,
	dropdownIsOpened,
	loading,
	additionalClasses,
	isDropdownBelowViewport,
	isDropdownHidden,
) {
	const dropdownItemsCount = dropdownItems.length;
	const scrollable = additionalClasses?.dropdown.includes("dropdown--light")
		? dropdownItemsCount > 9
		: dropdownItemsCount > 7;

	const dropdownClasses = clsx("dropdown", additionalClasses?.dropdown, {
		"dropdown--scrollable": scrollable,
		loading,
	});
	const dropdownToggleClasses = clsx(
		"dropdown-toggle",
		additionalClasses?.dropdownToggle,
	);
	const dropdownMenuClasses = clsx("dropdown-menu", {
		"dropdown-menu--opened": dropdownIsOpened,
		"dropdown-menu--inverted-y": isDropdownBelowViewport,
		"dropdown-menu--hidden": isDropdownHidden,
	});

	return {
		dropdownClasses,
		dropdownToggleClasses,
		dropdownMenuClasses,
	};
}
