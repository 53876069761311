import {
	USER_ROLE_AIRPORTS,
	USER_ROLE_FLEET_HISTORY,
	USER_ROLE_FLEET_HISTORY_BIZAV,
	USER_ROLE_FLEET_IFE_IFC,
	USER_ROLE_FLEET_OWNERSHIP,
	USER_ROLE_FLEET_OWNERSHIP_BIZAV,
	USER_ROLE_FLEETS,
	USER_ROLE_FLEETS_BIZAV,
	USER_ROLE_HOURS_AND_CYCLES,
	USER_ROLE_PSS,
} from "./constants";

export const rolePackageNames = {
	[USER_ROLE_AIRPORTS]: "ch-aviation PRO",
	[USER_ROLE_FLEET_OWNERSHIP]: "fleets ownership",
	[USER_ROLE_FLEET_OWNERSHIP_BIZAV]: "fleets ownership for Business Aviation",
	[USER_ROLE_HOURS_AND_CYCLES]: "pro + fleets",
	[USER_ROLE_FLEETS]: "pro + fleets",
	[USER_ROLE_FLEETS_BIZAV]: "fleets for Business Aviation",
	[USER_ROLE_FLEET_IFE_IFC]: "pro + fleets",
	[USER_ROLE_FLEET_HISTORY]: "fleets history",
	[USER_ROLE_FLEET_HISTORY_BIZAV]: "fleets history for Business Aviation",
	[USER_ROLE_PSS]: "pro + pss",
};
