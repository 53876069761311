import PropTypes from "prop-types";
import React from "react";
import { actionsButtonDependsOnType } from "../../common/types";

export function ActionsMenuButton({
	actionsButtonDependsOn,
	onClick,
	name = "More",
	type = "btn--more",
}) {
	const showActionsButton = actionsButtonDependsOn
		? Object.entries(actionsButtonDependsOn)
				.map(([key, value]) => value()[key])
				.every((v) => v === true)
		: true;

	if (!showActionsButton) {
		return null;
	}

	return (
		<button
			type="button"
			className={`btn btn--secondary btn--icon btn--icon--lg ${type}`}
			onClick={onClick}
		>
			<span>{name}</span>
		</button>
	);
}

ActionsMenuButton.propTypes = {
	actionsButtonDependsOn: actionsButtonDependsOnType,
	name: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	type: PropTypes.string,
};
