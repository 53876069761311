import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import useValidation from "../../validation/useValidation";
import { CriterionLabel } from "../common/CriterionLabel";
import { SearchCriterion } from "../common/SearchCriterion";
import SimpleCheckbox from "./SimpleCheckbox";

export function BooleanCheckboxCriterion({
	choice,
	criterionKey,
	label,
	prefix,
	quickSearch = false,
	requiredRoles,
	visibilitySelector,
}) {
	const { currentInput, onInputChange } = useFormComponentState(
		criterionKey,
		"",
	);
	const { valid, message } = useValidation(criterionKey);
	const inputId = `${criterionKey}${quickSearch ? "_quick_search" : ""}`;

	const handleToggle = () => {
		const newInput = currentInput === "true" ? "false" : "true";
		onInputChange(newInput);
	};

	return (
		<SearchCriterion
			label={label}
			prefix={prefix}
			quickSearch={quickSearch}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<div
				className={clsx("criterion boolean-criterion", {
					error: !valid,
				})}
			>
				<label htmlFor={inputId}>
					<CriterionLabel label={label} prefix={prefix} />
				</label>

				{!valid && <span className="error-text">{message}</span>}

				<SimpleCheckbox
					itemKey={criterionKey}
					value={choice}
					checked={currentInput === "true"}
					onToggle={handleToggle}
					inputId={inputId}
				/>
			</div>
		</SearchCriterion>
	);
}

BooleanCheckboxCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	choice: PropTypes.string.isRequired,
	requiredRoles: requiredRolesType,
	visibilitySelector: visibilitySelectorType,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool,
};
