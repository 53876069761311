import Immutable from "immutable";
import { CLEAR_INPUT } from "../../common/input/actions";
import {
	FORM_SEARCHES_CREATE_SEARCH,
	FORM_SEARCHES_CREATE_SEARCH_FAILED,
	FORM_SEARCHES_DELETE_SEARCH,
	FORM_SEARCHES_DELETE_SEARCH_FAILED,
	FORM_SEARCHES_GET_SEARCHES,
	FORM_SEARCHES_GET_SEARCHES_FAILED,
	FORM_SEARCHES_SEARCH_CREATED,
	FORM_SEARCHES_SEARCH_DELETED,
	FORM_SEARCHES_SEARCH_UPDATED,
	FORM_SEARCHES_SEARCHES,
	FORM_SEARCHES_SELECT_SEARCH,
	FORM_SEARCHES_SELECT_SEARCH_TO_DELETE,
} from "./actions";

const initialState = Immutable.Map({
	isCreating: false,
	createError: undefined,

	searches: Immutable.Map(),
	isFetched: false,
	isFetching: false,
	fetchError: undefined,
	selectedSearchId: undefined,

	isDeleting: false,
	deleteError: undefined,
	searchToDeleteId: undefined,
});

export function searchesReducer(state = initialState, action) {
	switch (action.type) {
		case CLEAR_INPUT:
			return state.set("selectedSearchId", undefined);
		case FORM_SEARCHES_CREATE_SEARCH:
			return state.merge({
				isCreating: true,
				createError: undefined,
			});
		case FORM_SEARCHES_CREATE_SEARCH_FAILED:
			return state.merge({
				isCreating: false,
				createError: action.data.error,
			});
		case FORM_SEARCHES_SEARCH_CREATED:
			return state
				.setIn(["searches", action.data.search.id], action.data.search)
				.merge({
					isCreating: false,
					createError: undefined,
					selectedSearchId: action.data.search.id,
				});
		case FORM_SEARCHES_GET_SEARCHES:
			return state.merge({
				isFetching: true,
				fetchError: undefined,
			});
		case FORM_SEARCHES_GET_SEARCHES_FAILED:
			return state.merge({
				isFetched: false,
				isFetching: false,
				fetchError: action.data.error,
			});
		case FORM_SEARCHES_SEARCHES:
			return state.merge({
				searches: Immutable.Map(
					action.data.searches.map((s) => [s.id, s]),
				),
				isFetched: true,
				isFetching: false,
				fetchError: undefined,
			});
		case FORM_SEARCHES_SELECT_SEARCH:
			return state.set("selectedSearchId", action.data.search.id);
		case FORM_SEARCHES_SEARCH_UPDATED:
			return state.setIn(
				["searches", action.data.search.id],
				action.data.search,
			);
		case FORM_SEARCHES_SELECT_SEARCH_TO_DELETE:
			return state.merge({
				searchToDeleteId: action.data.id,
			});
		case FORM_SEARCHES_DELETE_SEARCH:
			return state.merge({
				isDeleting: true,
				deleteError: undefined,
				searchToDeleteId: undefined,
			});
		case FORM_SEARCHES_DELETE_SEARCH_FAILED:
			return state.merge({
				isDeleting: false,
				deleteError: action.data.error,
				searchToDeleteId: undefined,
			});
		case FORM_SEARCHES_SEARCH_DELETED:
			return state.removeIn(["searches", action.data.id]).merge({
				isDeleting: false,
				deleteError: undefined,
				searchToDeleteId: undefined,
			});
		default:
			return state;
	}
}
