import jquery from "jquery";
import { flattenHashData } from "../../common/hash/util";
import { failureToError, parametersToQuery } from "../api";

const url = "/api/v1/me/favorites";

export function api() {
	return {
		load: function (onSuccess, onError) {
			jquery
				.ajax({
					type: "GET",
					url,
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(
					failureToError(onError, "Failed to load favorites lists."),
				);
		},

		update: function (name, lists, codes, input, onSuccess, onError) {
			let updateUrl = url;

			let data = JSON.stringify({
				name,
				lists,
				codes,
			});

			if (input) {
				updateUrl = "/api/v1/me/favorites-from-search";
				const flattenedHashData = flattenHashData(input);
				const query = parametersToQuery(flattenedHashData);

				data = JSON.stringify({
					name,
					lists,
					query_params: query,
				});
			}

			jquery
				.ajax({
					type: "POST",
					url: updateUrl,
					data,
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(
					failureToError(
						onError,
						"Failed to update favorites lists.",
					),
				);
		},

		delete: function (lists, codes, onSuccess, onError) {
			let data = JSON.stringify({
				lists,
				codes,
			});

			jquery
				.ajax({
					type: "DELETE",
					url,
					data,
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				})
				.done(onSuccess)
				.fail(
					failureToError(
						onError,
						"Failed to delete airline from list.",
					),
				);
		},
	};
}
