import PropTypes from "prop-types";
import React from "react";
import {
	MODAL_LOAD_SEARCH,
	MODAL_SAVE_SEARCH,
} from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import { SearchesActionsSaveButton } from "./SearchesActionsSaveButton";

export function SearchesActions({ current, searchDirty, updateSearch }) {
	const { onOpenModal } = useModal();

	const openModal = (key) => {
		onOpenModal(key);
	};

	const handleSaveSearchAsClick = (e) => {
		e.currentTarget.blur();
		onOpenModal(MODAL_SAVE_SEARCH);
	};

	const handleLoadSearchClick = (e) => {
		e.currentTarget.blur();
		openModal(MODAL_LOAD_SEARCH);
	};

	return (
		<>
			<button
				type="button"
				className="btn btn--secondary btn--divided btn--has-icon btn--save"
				onClick={handleSaveSearchAsClick}
			>
				<span>Save as...</span>
			</button>

			{current && searchDirty && (
				<SearchesActionsSaveButton
					updateSearch={updateSearch}
					current={current}
				/>
			)}

			<button
				type="button"
				className="btn btn--secondary btn--divided btn--has-icon btn--load"
				onClick={handleLoadSearchClick}
			>
				<span>Load</span>
			</button>
		</>
	);
}

SearchesActions.propTypes = {
	current: PropTypes.object,
	searchDirty: PropTypes.bool.isRequired,
	updateSearch: PropTypes.func.isRequired,
};
