import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";

export default function ChoicesCounter({ currentInput }) {
	if (currentInput.isEmpty()) {
		return null;
	}

	return (
		<span className="dropdown-choices-counter">{currentInput.size}</span>
	);
}

ChoicesCounter.propTypes = {
	currentInput: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
};
