export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const MODAL_CLEAR_ALL = "MODAL_CLEAR_ALL";

export function addModal(key) {
	return {
		type: MODAL_OPEN,
		data: { key },
	};
}

export function deleteModal(key) {
	return {
		type: MODAL_CLOSE,
		data: { key },
	};
}

export function resetModals() {
	return {
		type: MODAL_CLEAR_ALL,
	};
}
