import PropTypes from "prop-types";
import React from "react";

export default function CriterionSearchInput({
	isVisible,
	value,
	criterionKey,
	dropdownIsOpened,
	isMobile,
	onInputChange,
}) {
	const ref = React.useCallback(
		(input) => !isMobile && input?.focus(),
		[dropdownIsOpened],
	);

	if (!isVisible) {
		return null;
	}

	return (
		<div className={`form-group`}>
			<label htmlFor={`search-within-${criterionKey}`}>
				Search within
			</label>
			<input
				ref={ref}
				type="text"
				name={`search-within-${criterionKey}`}
				value={value}
				id={`search-within-${criterionKey}`}
				placeholder="Search"
				autoComplete="off"
				onChange={onInputChange}
			/>
		</div>
	);
}

CriterionSearchInput.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	criterionKey: PropTypes.string.isRequired,
	dropdownIsOpened: PropTypes.bool.isRequired,
	isMobile: PropTypes.bool.isRequired,
	onInputChange: PropTypes.func.isRequired,
	value: PropTypes.string,
};
