import Immutable from "immutable";
import {
	TOAST_FILL_ONE_OF_REQUIRED_FIELDS,
	TOAST_FILL_REQUIRED_FIELDS,
} from "../../../common/components/toasts/constants";
import { ValidationResult } from "./validationResult";

export const criterionInInput = (currentInput, key) => {
	const input = currentInput.get(key);

	if (input instanceof Immutable.Map && input.has("free_text")) {
		return (
			input.get("free_text").trim().length > 0 ||
			!input.get("codes")?.isEmpty()
		);
	} else if (
		input instanceof Immutable.Map ||
		input instanceof Immutable.List
	) {
		return !input.isEmpty();
	} else {
		return input?.length > 0;
	}
};

export const requiredOneOf = (currentInput, key, requiredOneOfCriteria) => {
	const inInput = requiredOneOfCriteria.some((key) =>
		criterionInInput(currentInput, key),
	);

	if (inInput) {
		return ValidationResult.success();
	} else {
		return ValidationResult.error("This field is required.");
	}
};

export const validateForm = (
	rules,
	input,
	criteriaFilter = (criteria) => criteria,
) => {
	const validationRules = criteriaFilter(rules).map((item) =>
		item.getValidationRule(),
	);

	const requiredOneOfCriteria = [
		...validationRules.filter((item) => item === requiredOneOf).keys(),
	];
	const requiredIndividualCriteria = [
		...validationRules.filter((item) => item !== requiredOneOf).keys(),
	];

	const validationResult = validationRules.map((validation, key) =>
		validation(input, key, requiredOneOfCriteria),
	);

	const oneOfCriteriaIsRequired = requiredOneOfCriteria.some((item) =>
		validationResult.get(item).isError(),
	);
	const individualCriteriaIsRequired = requiredIndividualCriteria.some(
		(item) => validationResult.get(item).isError(),
	);

	const toast =
		!individualCriteriaIsRequired && oneOfCriteriaIsRequired
			? TOAST_FILL_ONE_OF_REQUIRED_FIELDS
			: TOAST_FILL_REQUIRED_FIELDS;

	return { validationResult, toast };
};

export const isFormValid = (validationResult) =>
	validationResult.isEmpty() ||
	validationResult.every((item) => item.isSuccess());
