import { useDispatch } from "react-redux";
import { updateSearchParameters } from "../../../common/hash/actions";
import { setViewSortChanged } from "../../views/actions";

export default function useChangeSort() {
	const dispatch = useDispatch();

	const handleSortClick = (columnKey, currentSort) => {
		const sortParameters = {
			sort: columnKey,
			ascending: currentSort === null || currentSort !== "asc",
		};

		dispatch(updateSearchParameters(sortParameters));
		dispatch(setViewSortChanged());
	};

	return { handleSortClick };
}
