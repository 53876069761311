import PropTypes from "prop-types";
import React from "react";

export function CloseButton({
	classes = "btn btn--primary",
	modalKey,
	label = "Close",
	onClick,
}) {
	return (
		<button
			type="button"
			className={classes}
			onClick={() => onClick(modalKey)}
		>
			{label}
		</button>
	);
}

CloseButton.propTypes = {
	classes: PropTypes.string,
	label: PropTypes.string,
	modalKey: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};
