import PropTypes from "prop-types";
import React from "react";
import { CloseButton } from "../../common/components/modals/CloseButton";
import { MODAL_DATA_SERVICES_REQUEST } from "../../common/components/modals/constants";
import { Modal } from "../../common/components/modals/Modal";
import useModal from "../../common/components/modals/useModal";
import { downloadType, namespaceType } from "../../common/types";
import { getDownloadDemoUrl } from "./api";
import { DownloadLink } from "./DownloadLink";
import useDownload from "./useDownload";

export function DownloadModal({ modalKey, namespace, download }) {
	const { downloadFormat } = useDownload();
	const { onOpenModal, onCloseModal } = useModal();

	const { downloadDemoData } = download;
	const format = downloadFormat.toJS();
	const label = downloadFormat.get("label_modal");

	const getDownloadUrl = (format, extraParameters) => {
		return getDownloadDemoUrl(
			namespace,
			format,
			downloadDemoData,
			extraParameters,
		);
	};

	const title = "Exporting search results as " + label + " file";

	return (
		<Modal modalKey={modalKey} title={title}>
			<div className="modal__content">
				<p>
					You are currently not subscribed to the download module. If
					you wish to have the ability to download data, please
					contact sales below.
				</p>

				<div className="modal__body">
					<DownloadLink
						format={format}
						label={`Download ${label} sample`}
						getDownloadUrl={getDownloadUrl}
						onLinkClick={() => onCloseModal(modalKey)}
						classes="btn btn--secondary btn--has-icon btn--download"
					/>
				</div>
			</div>

			<footer className="flex modal__actions">
				<CloseButton
					classes="btn btn--secondary"
					onClick={onCloseModal}
					modalKey={modalKey}
					label="Cancel"
				/>
				<button
					type="button"
					className="btn btn--primary"
					onClick={() =>
						onOpenModal(MODAL_DATA_SERVICES_REQUEST, true)
					}
				>
					Contact Sales
				</button>
			</footer>
		</Modal>
	);
}

DownloadModal.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
	download: downloadType.isRequired,
};
