import moment from "moment/moment";
import { ValidationResult } from "../../validation/validationResult";

export const minMaxRangeCheck = (min, max) => {
	if (!(min && max)) {
		return ValidationResult.success();
	}

	if (parseInt(min) > parseInt(max)) {
		return ValidationResult.error(
			"Maximum value is less than minimum value.",
		);
	}

	return ValidationResult.success();
};

export function maskRangeCheck(min, max) {
	if (!(min && max)) {
		return ValidationResult.success();
	}

	let minArray = min.split(":");
	let maxArray = max.split(":");

	let minHours = parseInt(minArray[0]);
	let minMinutes = 0;
	if (minArray.length > 1) {
		minMinutes = parseInt(minArray.slice(-1)[0]);
	}

	let maxHours = parseInt(maxArray[0]);
	let maxMinutes = 0;
	if (maxArray.length > 1) {
		maxMinutes = parseInt(maxArray.slice(-1)[0]);
	}

	if (
		maxHours < minHours ||
		(minArray.length > 1 &&
			maxArray.length > 1 &&
			maxHours === minHours &&
			maxMinutes < minMinutes)
	) {
		return ValidationResult.error(
			"Maximum value is less than minimum value.",
		);
	}

	return ValidationResult.success();
}

export function dateRangeCheck(min, max) {
	if (!(min && max)) {
		return ValidationResult.success();
	}

	const minMoment = moment(min);
	const maxMoment = moment(max);

	if (minMoment.isAfter(maxMoment)) {
		return ValidationResult.error(
			"Maximum value is less than minimum value.",
		);
	}

	return ValidationResult.success();
}

export const validateInput = (rangeCheck, min, max, input) => {
	if (min || max) {
		return rangeCheck(min, max, input);
	}
	return ValidationResult.success();
};
