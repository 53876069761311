export function getSearchResult(state) {
	return state.getIn(["search", "result", "result"]);
}

export function getSearchResultError(state) {
	return state.getIn(["search", "result", "error"]);
}

export function getSearchResultColumns(state) {
	return state.getIn(["search", "result", "result", "columns"]);
}

export function isSearchResultLoading(state) {
	return state.getIn(["search", "result", "loading"]);
}

export function getDownloadFormat(state) {
	return state.getIn(["search", "result", "downloadFormat"]);
}

export function getSearchResultTotal(state) {
	return state.getIn(["search", "result", "result", "total"]);
}

export function printResultsAreLoading(state) {
	return state.getIn(["search", "result", "printResultsLoading"]);
}

export function printResultsError(state) {
	return state.getIn(["search", "result", "printResultsError"]);
}

export function printResultsLoaded(state) {
	return state.getIn(["search", "result", "printResultsLoaded"]);
}

export function printResultsRequested(state) {
	return state.getIn(["search", "result", "printResultRequest"]);
}

export function getPrintResults(state) {
	return state.getIn(["search", "result", "printResults"]);
}

export function shouldLoadPrintResults(state) {
	return printResultsRequested(state) && !printResultsAreLoading(state);
}
