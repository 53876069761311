import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../common/hash/actions";
import { hasHashData } from "../../common/hash/reducers";
import { receiveViewSelected } from "./actions";
import { getSelectedView, shouldLoadResults } from "./selectors";
import { getViewParameters } from "./useSelectView";

export default function useViewTracker() {
	const dispatch = useDispatch();
	const view = useSelector(getSelectedView);
	const loadResults = useSelector(shouldLoadResults);
	const hashDataExists = useSelector(hasHashData);
	const viewData = getViewParameters(view);

	useEffect(() => {
		if (hashDataExists && loadResults) {
			dispatch(receiveViewSelected());
			dispatch(updateSearchParameters(viewData));
		}
	}, [hashDataExists, loadResults, viewData]);
}
