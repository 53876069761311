import React, { useRef } from "react";
import { useCloseOnClickOutside } from "../useCloseOnClickOutside";
import { Toast } from "./Toast";
import useToasts from "./useToasts";

export function Toasts() {
	const ref = useRef(null);
	const { toasts, onCloseToast, onClearToasts } = useToasts();
	useCloseOnClickOutside(ref, !toasts.isEmpty(), onClearToasts);

	if (toasts.isEmpty()) {
		return null;
	}

	const items = toasts.valueSeq().map((item) => {
		const { key, type, role, title, content } = item;
		return (
			<Toast
				key={key}
				type={type}
				role={role}
				title={title}
				content={content}
				onClose={() => onCloseToast(key)}
			/>
		);
	});

	return (
		<article ref={ref} className="toasts" id="toasts">
			{items}
		</article>
	);
}
