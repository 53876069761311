export function isCreatingSearch(state) {
	return state.getIn(["search", "searches", "isCreating"]);
}

export function getSearchCreateError(state) {
	return state.getIn(["search", "searches", "createError"]);
}

export function getSearches(state) {
	return state.getIn(["search", "searches", "searches"]);
}

export function isFetchingSearches(state) {
	return state.getIn(["search", "searches", "isFetching"]);
}

export function shouldFetchSearches(state) {
	return (
		!isFetchingSearches(state) &&
		!state.getIn(["search", "searches", "isFetched"])
	);
}

export function getSearchesFetchError(state) {
	return state.getIn(["search", "searches", "fetchError"]);
}

export function getSelectedSearchId(state) {
	return state.getIn(["search", "searches", "selectedSearchId"]);
}

export function getSelectedSearchIdFromHash(state) {
	return state.getIn(["hash", "parameters", "selectedSearchId"]);
}

export function getSelectedSearch(state) {
	const id = getSelectedSearchId(state);
	return id ? getSearches(state).get(id) : null;
}

export function isDeletingSearch(state) {
	return state.getIn(["search", "searches", "isDeleting"]);
}

export function getSearchToDelete(state) {
	return state.getIn(["search", "searches", "searchToDeleteId"]);
}

export function getSearchDeleteError(state) {
	return state.getIn(["search", "searches", "deleteError"]);
}
