import React from "react";
import { pagingType } from "../../../common/types";
import { numberWithSpaces } from "../../utils";
import usePaging from "./usePaging";

export function Showing({ paging }) {
	const { currentPage, pageSize, results, pages } = usePaging(paging);

	if (pages <= 1) {
		return null;
	}

	const resultsFormatted = numberWithSpaces(results);

	let resultsShowingFrom;
	let resultsShowingTo;

	if (currentPage === 0) {
		resultsShowingFrom = 1;
		resultsShowingTo = results < pageSize ? results : pageSize;
	} else if (currentPage + 1 === pages) {
		resultsShowingFrom = pageSize * currentPage + 1;
		resultsShowingTo = results;
	} else {
		resultsShowingFrom = pageSize * currentPage + 1;
		resultsShowingTo = pageSize * (currentPage + 1);
	}

	return (
		<p className="results-showing">
			Showing {numberWithSpaces(resultsShowingFrom)}-
			{numberWithSpaces(resultsShowingTo)} of {resultsFormatted}
		</p>
	);
}

Showing.propTypes = {
	paging: pagingType.isRequired,
};
