import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { TextInput } from "../criteria/TextInput";

export function FavoriteListNameInput({ autoFocus, error, onChange, onFocus }) {
	return (
		<div className={clsx("form-group", { error })}>
			<TextInput
				id="favorite-list-name"
				name="favorite"
				placeholder="List name"
				label="Create new list"
				error={error}
				autoFocus={autoFocus}
				onChange={onChange}
				onFocus={onFocus}
			/>
		</div>
	);
}

FavoriteListNameInput.propTypes = {
	autoFocus: PropTypes.bool,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
};
