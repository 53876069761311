import PropTypes from "prop-types";
import React from "react";
import { namespaceType } from "../../common/types";
import { SearchesActions } from "./SearchesActions";
import { SearchSelected } from "./SearchSelected";
import useSearches from "./useSearches";

export function Searches({ namespace, label }) {
	const { current, searchDirty, updateSearch } = useSearches(namespace);

	return (
		<>
			<SearchSelected current={current} label={label} />
			<SearchesActions
				current={current}
				searchDirty={searchDirty}
				updateSearch={updateSearch}
			/>
		</>
	);
}

Searches.propTypes = {
	namespace: namespaceType.isRequired,
	label: PropTypes.string.isRequired,
};
