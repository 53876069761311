import jquery from "jquery";
import { failureToError } from "../../../api";

const url = `/api/v1/me/quick-search-criteria/`;

export function api(namespace) {
	return {
		update: function (fields, input, onSuccess, onError) {
			const data = JSON.stringify({
				fields,
				input,
			});
			jquery
				.ajax({
					type: "POST",
					url: `${url}${namespace}`,
					data,
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(
					failureToError(
						onError,
						"Failed to update quick search criteria.",
					),
				);
		},
		delete: function (onSuccess, onError) {
			jquery
				.ajax({
					type: "DELETE",
					url: `${url}${namespace}`,
				})
				.done(onSuccess)
				.fail(
					failureToError(
						onError,
						"Failed to reset quick search criteria.",
					),
				);
		},
	};
}
