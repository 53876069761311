import PropTypes from "prop-types";
import React from "react";
import {
	MODAL_ADD_TO_FAVORITES,
	MODAL_ADVANCED_SEARCH,
	MODAL_CUSTOMIZE_QUICK_SEARCH,
	MODAL_DATA_DOWNLOAD_REQUEST,
	MODAL_DATA_SERVICES_REQUEST,
	MODAL_DELETE_SEARCH,
	MODAL_DELETE_VIEW,
	MODAL_DOWNLOAD,
	MODAL_EDIT_VIEW,
	MODAL_LOAD_SEARCH,
	MODAL_LOAD_VIEW,
	MODAL_REQUEST_SUCCESS,
	MODAL_SAVE_SEARCH,
	MODAL_SAVE_VIEW,
} from "../common/components/modals/constants";
import { Modals } from "../common/components/modals/Modals";
import { Toasts } from "../common/components/toasts/Toasts";
import { DataDownloadRequest } from "../common/forms/data-download-request/DataDownloadRequest";
import { DataServicesRequestModal } from "../common/forms/data-services-request/DataServicesRequest";
import { ModalSuccess } from "../common/forms/ModalSuccess";
import {
	advancedSearchAdditionalLinkType,
	criteriaType,
	customizeQuickSearchType,
	downloadType,
	faqType,
	footerPresetsType,
	namespaceType,
	notDeletableType,
	resultTableConfigType,
	searchesType,
	searchTitleType,
} from "../common/types";
import { AdvancedSearchModal } from "./advanced-search/AdvancedSearchModal";
import { CustomizeQuickSearch } from "./criteria/quick-search/customize-quick-search/CustomizeQuickSearch";
import { DownloadModal } from "./download/DownloadModal";
import { FavoritesModal } from "./favorites/FavoritesModal";
import { SearchHeader } from "./header/SearchHeader";
import { Initializers } from "./Initializers";
import { Presets } from "./presets/Presets";
import { SearchResultsContainer } from "./result/SearchResultsContainer";
import useSearch from "./result/useSearch";
import { DeleteSearch } from "./searches/load/DeleteSearch";
import { LoadSearch } from "./searches/load/LoadSearch";
import { SaveSearch } from "./searches/save/SaveSearch";
import useEnterToSearch from "./useEnterToSearch";
import { EditView } from "./views/edit/EditView";
import { DeleteView } from "./views/load/DeleteView";
import { LoadView } from "./views/load/LoadView";
import { SaveView } from "./views/save/SaveView";

export function SearchContainer({
	definition: {
		advancedSearchAdditionalLink,
		criteria,
		customizeQuickSearch,
		download,
		faq,
		favoriteListsParams,
		footerPresets,
		namespace,
		notDeletable,
		resultTableConfig,
		searches,
		searchTitle,
		showResultsOnInitialPageLoad,
		...props
	},
}) {
	const { searchesEnabled, searchAlertEnabled } = searches;
	const columns = resultTableConfig.columns;
	const { onSearch, onClearSearch, onClearInput } = useSearch();
	useEnterToSearch(onSearch);

	const modals = {
		[MODAL_ADVANCED_SEARCH]: (
			<AdvancedSearchModal
				namespace={namespace}
				searchTitle={searchTitle}
				criteria={criteria.criteria}
				notDeletable={notDeletable}
				searchesEnabled={searchesEnabled}
				advancedSearchAdditionalLink={advancedSearchAdditionalLink}
				onSearchClick={onSearch}
				onClearClick={onClearInput}
			/>
		),
		[MODAL_EDIT_VIEW]: <EditView namespace={namespace} />,
		[MODAL_CUSTOMIZE_QUICK_SEARCH]: (
			<CustomizeQuickSearch
				criteria={criteria.criteria}
				namespace={namespace}
				customizeQuickSearch={customizeQuickSearch}
				quickSearchCriteriaOrder={criteria.quickSearchCriteriaOrder}
			/>
		),
		[MODAL_LOAD_VIEW]: (
			<LoadView namespace={namespace} modalKey={MODAL_LOAD_VIEW} />
		),
		[MODAL_DELETE_VIEW]: (
			<DeleteView namespace={namespace} modalKey={MODAL_DELETE_VIEW} />
		),
		[MODAL_SAVE_VIEW]: (
			<SaveView namespace={namespace} modalKey={MODAL_SAVE_VIEW} />
		),
		[MODAL_LOAD_SEARCH]: (
			<LoadSearch
				namespace={namespace}
				searchAlertEnabled={searchAlertEnabled}
				searchesEnabled={searchesEnabled}
				modalKey={MODAL_LOAD_SEARCH}
			/>
		),
		[MODAL_DELETE_SEARCH]: (
			<DeleteSearch
				namespace={namespace}
				modalKey={MODAL_DELETE_SEARCH}
			/>
		),
		[MODAL_SAVE_SEARCH]: (
			<SaveSearch
				namespace={namespace}
				searches={searches}
				modalKey={MODAL_SAVE_SEARCH}
			/>
		),
		[MODAL_DOWNLOAD]: (
			<DownloadModal
				download={download}
				namespace={namespace}
				modalKey={MODAL_DOWNLOAD}
			/>
		),
		[MODAL_DATA_SERVICES_REQUEST]: (
			<DataServicesRequestModal
				namespace={namespace}
				modalKey={MODAL_DATA_SERVICES_REQUEST}
			/>
		),
		[MODAL_REQUEST_SUCCESS]: (
			<ModalSuccess
				title="Request Sent Successfully"
				modalKey={MODAL_REQUEST_SUCCESS}
			/>
		),
		[MODAL_DATA_DOWNLOAD_REQUEST]: (
			<DataDownloadRequest
				modalKey={MODAL_DATA_DOWNLOAD_REQUEST}
				namespace={namespace}
			/>
		),
		[MODAL_ADD_TO_FAVORITES]: (
			<FavoritesModal
				params={favoriteListsParams}
				modalKey={MODAL_ADD_TO_FAVORITES}
			/>
		),
	};

	return (
		<section className="section--search">
			<div className="search-container">
				<Initializers
					columns={columns}
					criteria={criteria.criteria}
					namespace={namespace}
					searchesEnabled={searchesEnabled}
					showResultsOnInitialPageLoad={showResultsOnInitialPageLoad}
				/>
				<SearchHeader
					criteria={criteria}
					customizeQuickSearch={customizeQuickSearch}
					namespace={namespace}
					faq={faq}
					searchesEnabled={searchesEnabled}
					notDeletable={notDeletable}
					onSearchClick={onSearch}
					onClearSearchClick={onClearSearch}
				/>
				<SearchResultsContainer
					namespace={namespace}
					searchTitle={searchTitle}
					download={download}
					searches={searches}
					resultTableConfig={resultTableConfig}
					{...props}
				/>
				<Presets footerPresets={footerPresets} />
				<Modals
					modalsList={modals}
					renderDefault={MODAL_ADVANCED_SEARCH}
				/>
				<Toasts />
			</div>
		</section>
	);
}

SearchContainer.propTypes = {
	definition: PropTypes.shape({
		advancedSearchAdditionalLink: advancedSearchAdditionalLinkType,
		customizeQuickSearch: customizeQuickSearchType,
		criteria: criteriaType.isRequired,
		download: downloadType.isRequired,
		faq: faqType,
		notDeletable: notDeletableType,
		footerPresets: footerPresetsType,
		namespace: namespaceType.isRequired,
		resultTableConfig: resultTableConfigType.isRequired,
		searches: searchesType.isRequired,
		searchTitle: searchTitleType.isRequired,
		favoriteListsParams: PropTypes.object,
		showResultsOnInitialPageLoad: PropTypes.bool,
	}).isRequired,
};
