import useFetchData from "../../../common/api/useFetchData";

export default function useFetchViews(namespace, shouldFetch) {
	const { data, loading, error } = useFetchData({
		endpoint: `/api/v1/me/views/${namespace}`,
		immutable: false,
		shouldFetch,
	});

	return {
		views: data,
		loading,
		error,
	};
}
