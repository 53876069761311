import Immutable from "immutable";
import { LAYOUT_SET_WINDOW_SIZE } from "./actions";

const initialState = Immutable.Map({
	isMobile: false,
	isTablet: false,
});

export function layoutReducer(state = initialState, action) {
	switch (action.type) {
		case LAYOUT_SET_WINDOW_SIZE:
			return state.merge({
				isMobile: action.data.isMobile,
				isTablet: action.data.isTablet,
			});
		default:
			return state;
	}
}
