import PropTypes from "prop-types";
import React from "react";
import { Loader } from "../../../common/components/Loader";
import useCriteriaVisibility from "../../../common/forms/useCriteriaVisibility";
import { criteriaElementsType, notDeletableType } from "../../../common/types";
import { SelectedChoices } from "../choices/SelectedChoices";
import { quickSearchCriteriaSelectionType } from "./useFetchQuickSearchCriteria";
import { useQuickSearchCriteriaSelectionType } from "./useQuickSearchCriteriaSelectionType";

export function QuickSearch({
	criteria,
	notDeletable,
	onSearchClick,
	onClearSearchClick,
	loading = false,
}) {
	const allCriteriaVisible = useCriteriaVisibility(criteria);
	const criteriaSelectionType = useQuickSearchCriteriaSelectionType();

	const handleSearchClick = (e) => {
		e.preventDefault();
		onSearchClick();
	};

	let content = (
		<div className="loading">
			<Loader />
		</div>
	);
	let errorMessage;
	const errorMessageText =
		criteriaSelectionType === quickSearchCriteriaSelectionType.CUSTOM
			? 'You changed the "Search By" option, so some criteria are hidden because they do not belong to the current "Search By".'
			: 'Some criteria are hidden because they do not belong to the current "Search By".';

	if (!allCriteriaVisible) {
		errorMessage = (
			<div className="error">
				<p className="error-text">{errorMessageText}</p>
			</div>
		);
	}

	if (!loading) {
		content = (
			<>
				<form aria-labelledby="quickSearchTitle">
					{criteria.map((component, key) => (
						<React.Fragment key={key}>{component}</React.Fragment>
					))}
					<div className="actions-wrapper">
						<button
							type="button"
							className="btn btn--secondary btn--clear-search"
							onClick={onClearSearchClick}
						>
							Clear search
						</button>
						<button
							className="btn btn--primary"
							type="submit"
							onClick={handleSearchClick}
						>
							Search
						</button>
					</div>
				</form>

				{errorMessage}

				<SelectedChoices notDeletable={notDeletable} />
			</>
		);
	}

	return (
		<div className="quick-search-filters">
			<p className="search-type-title h5" id="quickSearchTitle">
				Quick Search
			</p>
			{content}
		</div>
	);
}

QuickSearch.propTypes = {
	criteria: criteriaElementsType.isRequired,
	notDeletable: notDeletableType,
	onSearchClick: PropTypes.func.isRequired,
	onClearSearchClick: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};
