import { isImmutable } from "immutable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSearchState from "../../useSearchState";
import { clearObsoleteChoices, updateChoices } from "../actions";
import { BooleanCheckboxCriterion } from "../components/checkboxes/BooleanCheckboxCriterion";
import { CheckboxCriterion } from "../components/checkboxes/CheckboxCriterion";
import { DateCriterion } from "../components/date/DateCriterion";
import { DropdownCriterion } from "../components/dropdown/DropdownCriterion";
import { ForecastCriterion } from "../components/forecast/ForecastCriterion";
import { RadioButtonCriterion } from "../components/radio/RadioButtonCriterion";
import { createDatePicker, getInputRange } from "../components/range/inputs";
import { RangeCriterion } from "../components/range/RangeCriterion";
import { FreeTextCriterion } from "../components/text/FreeTextCriterion";
import { UtilisationSearchRangeCriterion } from "../components/utilisationSearchRange/UtilisationSearchRangeCriterion";
import { getPrefixedChoiceLabel } from "../utils";
import { getValidationResult } from "../validation/selectors";
import { ValidationResult } from "../validation/validationResult";
import {
	getBooleanChoices,
	getChoicesInInput,
	getDateChoice,
	getForecastChoices,
	getFreeTextChoice,
	getRangeChoices,
	getUtilisationSearchRangeChoices,
} from "./choices";

export default function useSetSelectedChoices(criteria) {
	const dispatch = useDispatch();
	const { currentInput } = useSearchState();
	const validationResult = useSelector(getValidationResult);

	useEffect(() => {
		if (!currentInput.isEmpty()) {
			dispatch(clearObsoleteChoices());

			currentInput.forEach((value, key) => {
				const criterion = criteria[key];

				if (!criterion) {
					return;
				}

				const props = criterion.factory(key).props;
				const label = getPrefixedChoiceLabel(props.label, props.prefix);
				const selectedChoices = mapInputToCriterionSelectedChoices(
					props,
					criterion.factory(key).type,
					currentInput,
					validationResult,
				);

				if (selectedChoices) {
					dispatch(
						updateChoices(props.criterionKey, {
							label,
							choices: selectedChoices,
						}),
					);
				}
			});
		}
	}, [currentInput]);
}

function mapInputToCriterionSelectedChoices(
	props,
	criterionType,
	currentInput,
	validationResult,
) {
	const criterionKey = props.criterionKey;
	const criterionInput = currentInput.get(criterionKey);
	const criterionValidation =
		validationResult.get(criterionKey) || ValidationResult.success();
	const valid = criterionValidation.isSuccess();
	const visible = isVisible(currentInput, props.visibilitySelector);

	switch (criterionType) {
		case BooleanCheckboxCriterion:
			return getBooleanChoices(
				criterionKey,
				props.choice,
				criterionInput,
				visible,
			);
		case CheckboxCriterion:
			return getChoicesInInput(props.choices, criterionInput, visible);
		case DateCriterion:
			return getDateChoice(criterionInput, valid, visible);
		case FreeTextCriterion:
			return getFreeTextChoice(criterionInput, visible);
		case DropdownCriterion:
		case RadioButtonCriterion:
			return getChoicesInInput(props.choices, criterionInput, visible);
		case RangeCriterion: {
			const datesRange = props.createControl === createDatePicker;
			if (!isImmutable(criterionInput)) {
				return;
			}
			const inputMin = criterionInput?.get("min", "");
			const inputMax = criterionInput?.get("max", "");
			const unit = criterionInput.get("unit", "");
			const inputRange = getInputRange(inputMin, inputMax, datesRange);
			return getRangeChoices(inputRange, unit, visible);
		}
		case ForecastCriterion: {
			const amount = criterionInput.get("amount", "");
			return getForecastChoices(criterionInput, amount && valid);
		}
		case UtilisationSearchRangeCriterion:
			return getUtilisationSearchRangeChoices(criterionInput);
		default:
			return;
	}
}

const isVisible = (currentInput, visibilitySelector) => {
	if (!visibilitySelector) {
		return true;
	}
	return (
		currentInput.get(visibilitySelector.criterionKey) ===
		visibilitySelector.visibleOn
	);
};
