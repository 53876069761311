import React from "react";
import {
	columnHeaderGeneratorType,
	columnsVisibleType,
} from "../../../../common/types";

export function SearchResultsHeaderRow({
	columnsVisible,
	columnHeaderGenerator,
}) {
	return (
		<thead>
			<tr>{columnHeaderGenerator(columnsVisible)}</tr>
		</thead>
	);
}

SearchResultsHeaderRow.propTypes = {
	columnsVisible: columnsVisibleType.isRequired,
	columnHeaderGenerator: columnHeaderGeneratorType.isRequired,
};
