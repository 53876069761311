import { useSelector } from "react-redux";
import { getInputVisibility } from "../selectors";

export const useCustomizeQuickSearchVisibilityState = (visibilitySelector) => {
	if (!visibilitySelector) {
		return true;
	}

	const { criterionKey, visibleOn } = visibilitySelector;
	return useSelector((state) =>
		getInputVisibility(state, criterionKey, visibleOn),
	);
};
