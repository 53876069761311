export const QUICK_SEARCH_FETCH_CRITERIA = "QUICK_SEARCH_GET_CRITERIA";
export const QUICK_SEARCH_CRITERIA_FETCHED = "QUICK_SEARCH_CRITERIA_FETCHED";
export const QUICK_SEARCH_SELECTED_CRITERIA = "QUICK_SEARCH_SELECTED_CRITERIA";
export const QUICK_SEARCH_CRITERIA_INPUT = "QUICK_SEARCH_CRITERIA_INPUT";
export const REQUEST_UPDATE_QUICK_SEARCH_CRITERIA =
	"REQUEST_UPDATE_QUICK_SEARCH_CRITERIA";
export const QUICK_SEARCH_CRITERIA_UPDATED = "QUICK_SEARCH_CRITERIA_UPDATED";
export const QUICK_SEARCH_CRITERIA_UPDATE_FAILED =
	"QUICK_SEARCH_CRITERIA_UPDATE_FAILED";
export const QUICK_SEARCH_CRITERIA_DELETE = "QUICK_SEARCH_CRITERIA_DELETE";
export const QUICK_SEARCH_CRITERIA_INPUT_ARE_DELETED =
	"QUICK_SEARCH_CRITERIA_INPUT_ARE_DELETED";
export const QUICK_SEARCH_CRITERIA_DELETE_FAILED =
	"QUICK_SEARCH_CRITERIA_DELETE_FAILED";
export const QUICK_SEARCH_REFRESH_INITIAL_CRITERIA =
	"QUICK_SEARCH_REFRESH_INITIAL_CRITERIA";
export const QUICK_SEARCH_CRITERIA_SET_SELECTION_TYPE =
	"QUICK_SEARCH_CRITERIA_SET_SELECTION_TYPE";

export const requestQuickSearchCriteria = () => ({
	type: QUICK_SEARCH_FETCH_CRITERIA,
	data: {},
});

export const receiveQuickSearchCriteria = (criteria) => ({
	type: QUICK_SEARCH_CRITERIA_FETCHED,
	data: { criteria },
});

export const setQuickSearchCriteriaSelectionType = (type) => ({
	type: QUICK_SEARCH_CRITERIA_SET_SELECTION_TYPE,
	data: { type },
});

export const setQuickSearchSelectedCriteria = (criteria) => ({
	type: QUICK_SEARCH_SELECTED_CRITERIA,
	data: { criteria },
});

export function requestUpdateQuickSearchCriteria() {
	return {
		type: REQUEST_UPDATE_QUICK_SEARCH_CRITERIA,
		data: {},
	};
}

export function quickSearchCriteriaUpdated() {
	return {
		type: QUICK_SEARCH_CRITERIA_UPDATED,
		data: {},
	};
}

export function updateQuickSearchCriteriaFailed(error) {
	return {
		type: QUICK_SEARCH_CRITERIA_UPDATE_FAILED,
		data: {
			error,
		},
	};
}

export const setQuickSearchCriteriaInput = (input) => ({
	type: QUICK_SEARCH_CRITERIA_INPUT,
	data: { input },
});

export function requestDeleteQuickSearchCriteria() {
	return {
		type: QUICK_SEARCH_CRITERIA_DELETE,
		data: {},
	};
}

export function quickSearchCriteriaAreDeleted() {
	return {
		type: QUICK_SEARCH_CRITERIA_INPUT_ARE_DELETED,
		data: {},
	};
}

export function deleteQuickSearchCriteriaFailed(error) {
	return {
		type: QUICK_SEARCH_CRITERIA_DELETE_FAILED,
		data: {
			error,
		},
	};
}

export const initialQuickSearchCriteriaRefreshed = () => ({
	type: QUICK_SEARCH_REFRESH_INITIAL_CRITERIA,
	data: {},
});
