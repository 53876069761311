import PropTypes from "prop-types";
import React, { useState } from "react";

export function PagingGoTo({ currentPage, onClick }) {
	const [value, setValue] = useState("");
	const page = value === "" ? currentPage : value - 1;

	const handleChange = (value) => {
		const re = /^[0-9\b]+$/;

		if (value === "" || re.test(value)) {
			setValue(value);
		}
	};

	const handleClick = (e) => {
		e.preventDefault();
		const goToPage = page > 0 ? page : currentPage;
		onClick(goToPage);
	};

	return (
		<form className="paging-go-to">
			<label htmlFor="goToPage">Go to page</label>
			<div className="go-to-wrapper">
				<input
					type="text"
					id="goToPage"
					pattern="[0-9]*"
					autoComplete="off"
					placeholder="10"
					value={value}
					onChange={(e) => handleChange(e.target.value)}
				/>
				<button
					type="submit"
					className="btn--goto"
					aria-label="Go"
					onClick={handleClick}
				/>
			</div>
		</form>
	);
}

PagingGoTo.propTypes = {
	currentPage: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
};
