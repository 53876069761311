import PropTypes from "prop-types";
import React from "react";
import {
	MODAL_LOAD_VIEW,
	MODAL_SAVE_VIEW,
} from "../../../common/components/modals/constants";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import useSearchState from "../../useSearchState";
import useSaveView from "../useSaveView";
import { mergeColumns } from "../View";
import useColumnsState from "./useColumnsState";

export function EditViewActions({ namespace, columnsInput }) {
	const { onChangeColumns } = useColumnsState();
	const { current, viewDirty, onSaveView } = useSaveView(namespace);
	const { columnsVisible } = useSearchState();
	const { onOpenModal, onCloseModal } = useModal();

	const handleSaveViewClick = () => {
		const columns = mergeColumns(columnsVisible, columnsInput);
		onChangeColumns(columnsInput);
		onSaveView(columns);
		onCloseModal();
	};

	const handleSaveViewAsClick = () => {
		onChangeColumns(columnsInput);
		onOpenModal(MODAL_SAVE_VIEW);
	};

	const handleLoadViewClick = () => {
		onOpenModal(MODAL_LOAD_VIEW);
	};

	return (
		<>
			{current && viewDirty && (
				<button
					type="button"
					className="btn btn--tertiary btn--divided btn--has-icon btn--save"
					onClick={handleSaveViewClick}
				>
					<span>Save...</span>
				</button>
			)}

			<button
				type="button"
				className="btn btn--tertiary btn--divided btn--has-icon btn--save"
				onClick={handleSaveViewAsClick}
			>
				<span>Save as...</span>
			</button>

			<button
				type="button"
				className="btn btn--tertiary btn--divided btn--has-icon btn--load"
				onClick={handleLoadViewClick}
			>
				<span>Load as...</span>
			</button>
		</>
	);
}

EditViewActions.propTypes = {
	namespace: namespaceType.isRequired,
	columnsInput: PropTypes.string.isRequired,
};
