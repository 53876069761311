import React from "react";
import { faqType } from "../../common/types";

export function SupportLink({ faq }) {
	if (!faq) {
		return null;
	}

	return (
		<a
			className="btn btn--link btn--has-icon btn--support"
			href={`/faq/${faq.link}`}
			title={`Need help? - FAQ on ${faq.title}`}
		>
			<span>Support</span>
		</a>
	);
}

SupportLink.propTypes = {
	faq: faqType,
};
