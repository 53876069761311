import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useFetchData from "../../../../common/api/useFetchData";
import { setYears } from "../../actions";

export default function useSetYears() {
	const dispatch = useDispatch();
	const { data } = useFetchData({
		endpoint: "/api/v1/choices/least_dates",
	});

	useEffect(() => {
		if (data) {
			dispatch(setYears(data));
		}
	}, [data]);
}
