import { namespaceType } from "../common/types";
import { useSearchScrollPosition } from "./useSearchScrollPosition";

export const SearchScrollPosition = ({ namespace }) => {
	useSearchScrollPosition(namespace);

	return null;
};

SearchScrollPosition.propTypes = {
	namespace: namespaceType.isRequired,
};
