import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { MODAL_ADD_TO_FAVORITES } from "../../../common/components/modals/constants";
import useModal from "../../../common/components/modals/useModal";
import useFavorites from "../useFavorites";

export function FavoritesIndicatorCell({ code, name }) {
	const { favorites, selectedCodes, handleModalContent, onInputChange } =
		useFavorites();
	const { onOpenModal } = useModal();

	const codesInFavorites = favorites.reduce((result, current) => {
		const codes = current.codes;
		result.push(...codes);

		return result;
	}, []);

	const handleFavoritesItem = (code) => {
		const input = selectedCodes.clear().push(code);
		onInputChange(input);

		handleModalContent("entity");
		onOpenModal(MODAL_ADD_TO_FAVORITES);
	};

	const inFavorites = codesInFavorites.some((item) => item === code);
	const title = inFavorites
		? "Remove " + name + " from favorites"
		: "Add " + name + " to favorites";

	return (
		<td className="action-buttons action-buttons--icon">
			<button
				type="button"
				className={clsx("btn btn--icon btn--favorite", {
					active: inFavorites,
				})}
				title={title}
				onClick={() => handleFavoritesItem(code)}
			>
				{title}
			</button>
		</td>
	);
}

FavoritesIndicatorCell.propTypes = {
	code: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};
