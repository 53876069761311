import Immutable from "immutable";
import jquery from "jquery";
import queryString from "query-string";
import { flattenHashData } from "../common/hash/util";

export function parametersToQuery(parameters) {
	return queryString.stringify(parameters, {
		arrayFormat: "bracket",
	});
}

export function failureToError(
	failureHandler,
	defaultMessage = "An unexpected error occurred.",
) {
	return (jqXHR) => {
		const error =
			jqXHR.responseJSON && jqXHR.responseJSON.error
				? jqXHR.responseJSON.error
				: {
						code: "unexpected_error",
						message: defaultMessage,
					};
		failureHandler(error);
	};
}

export function loadCounts(namespace, input, onSuccess, onError) {
	const data = Immutable.Map({
		input,
		parameters: Immutable.Map({
			counts_only: true,
		}),
	});

	requestSearch(namespace, data, onSuccess, onError);
}

export function requestSearch(namespace, requestData, onSuccess, onError) {
	const flattenedRequestData = flattenHashData(requestData);
	const query = parametersToQuery(flattenedRequestData);

	return jquery
		.get(`/api/v1/${namespace}?${query}`)
		.done(onSuccess)
		.fail(failureToError(onError, "Search request failed"));
}
