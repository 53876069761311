import PropTypes from "prop-types";
import React from "react";

export function FeedbackCell({ url }) {
	const handleFeedbackClick = () => {
		window.open(
			url,
			"feedback",
			"width=493, height=680, scrollbars=yes, toolbar=no, status=no, resizable=yes, menubar=no, location=no, directories=no, top=10, left=10",
		);
	};

	return (
		<td className="action-buttons">
			<button
				type="button"
				className="btn--icon btn--feedback"
				title="Provide Correction"
				onClick={() => handleFeedbackClick(url)}
			>
				Provide Correction
			</button>
		</td>
	);
}

FeedbackCell.propTypes = {
	url: PropTypes.string.isRequired,
};
