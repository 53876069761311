import PropTypes from "prop-types";
import { namespaceType } from "../../common/types";
import useResultsPreview from "./useResultsPreview";

export const ResultsPreviewInitializer = ({ namespace, isPrimary = true }) => {
	useResultsPreview(namespace, isPrimary);

	return null;
};

ResultsPreviewInitializer.propTypes = {
	namespace: namespaceType.isRequired,
	isPrimary: PropTypes.bool,
};
