import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { forecastingInputType } from "../../../../common/types";
import { amountCheck } from "./validation";

export function ForecastAmountField({
	type,
	currentInput,
	valid,
	onInputChange,
	quickSearch,
}) {
	const [showValidation, setShowValidation] = useState(false);

	if (showValidation && currentInput.isEmpty()) {
		setShowValidation(false);
	}

	const amount = currentInput.get("amount", "");
	const errorMessage =
		(showValidation || !valid) && amountCheck(currentInput).getMessage();
	const error = errorMessage && (
		<span className="error-text">{errorMessage}</span>
	);
	const errorClasses = clsx("criterion__section", { error: errorMessage });

	const onChange = (e) => {
		onInputChange(currentInput.set("amount", e.target.value));
		setShowValidation(true);
	};

	return (
		<div className={errorClasses}>
			<div className="form-group form-group-inline">
				<label htmlFor="forecastAmount">Number of {type}</label>
				<input
					type="text"
					name="amount"
					value={amount}
					id={`forecastAmount${quickSearch ? "_quick_search" : ""}`}
					pattern="\d*"
					placeholder="0"
					data-criterion={true}
					onChange={onChange}
					onBlur={onChange}
				/>
			</div>
			{error}
		</div>
	);
}

ForecastAmountField.propTypes = {
	type: PropTypes.string.isRequired,
	currentInput: forecastingInputType.isRequired,
	onInputChange: PropTypes.func.isRequired,
	valid: PropTypes.bool.isRequired,
	quickSearch: PropTypes.bool.isRequired,
};
