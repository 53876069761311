import Immutable from "immutable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetchData from "../../../common/api/useFetchData";
import { inputChanged } from "../../../common/input/actions";
import useSearchState from "../../useSearchState";
import {
	initialQuickSearchCriteriaRefreshed,
	receiveQuickSearchCriteria,
	requestQuickSearchCriteria,
	setQuickSearchCriteriaInput,
	setQuickSearchCriteriaSelectionType,
} from "./actions";
import {
	isQuickSearchCriteriaLoaded,
	shouldRefreshInitialCriteria,
} from "./selectors";

export const useFetchQuickSearchCriteria = (
	namespace,
	fallbackCriteria,
	shouldFetch = true,
	allCriteriaDefinition,
	quickSearchCriteriaOrder,
	customizeQuickSearch,
) => {
	const dispatch = useDispatch();
	const isLoaded = useSelector(isQuickSearchCriteriaLoaded);
	const refreshInitialCriteria = useSelector(shouldRefreshInitialCriteria);
	const { currentInput } = useSearchState();

	const { data, loading, error, mutate } = useFetchData({
		endpoint: `/api/v1/me/quick-search-criteria/${namespace}`,
		shouldFetch: !isLoaded && shouldFetch,
	});

	const processCriteria = (criteriaDefinitionList, refreshInput = false) => {
		const quickSearchCriteriaDefinition = getQuickSearchCriteriaDefinition(
			criteriaDefinitionList,
			getAllCriteriaKeys(allCriteriaDefinition),
			quickSearchCriteriaOrder,
		);
		const input = quickSearchCriteriaDefinition?.input;
		const fields = quickSearchCriteriaDefinition?.fields;
		const type = quickSearchCriteriaDefinition?.type;
		const shouldUseFallbackCriteria =
			error || !quickSearchCriteriaDefinition || fields.length === 0;

		dispatch(
			receiveQuickSearchCriteria(
				Immutable.List(
					shouldUseFallbackCriteria ? fallbackCriteria : fields,
				),
			),
		);

		if (input) {
			const userDefinedInput = Immutable.Map(input);
			dispatch(setQuickSearchCriteriaInput(userDefinedInput));

			if (userDefinedInput !== currentInput) {
				dispatch(inputChanged(currentInput.merge(userDefinedInput)));
			}
		} else if (refreshInput) {
			const defaultInput = customizeQuickSearch.defaultInput;

			if (defaultInput !== currentInput) {
				dispatch(inputChanged(currentInput.merge(defaultInput)));
			}
		}

		if (type) {
			dispatch(
				setQuickSearchCriteriaSelectionType(
					quickSearchCriteriaDefinition?.type,
				),
			);
		}
	};

	// Fill quick search criteria on page load
	useEffect(() => {
		if (refreshInitialCriteria && !loading) {
			(async () => {
				dispatch(requestQuickSearchCriteria());
				processCriteria(await mutate(), true);
				dispatch(initialQuickSearchCriteriaRefreshed());
			})();
			return;
		}

		if (!isLoaded && !loading && !refreshInitialCriteria) {
			processCriteria(data);
		}
	}, [data, loading, isLoaded, refreshInitialCriteria]);
};

export const quickSearchCriteriaSelectionType = {
	CUSTOM: "custom",
	RECOMMENDED: "recommended",
	RECOMMENDED_USER: "recommended-user",
	RECOMMENDED_USERS_INDUSTRY_TYPE: "recommended-users-industry-type",
	DEFAULT_USERS_INDUSTRY_TYPE: "default-users-industry-type",
	DEFAULT_DEFAULT_INDUSTRY_TYPE: "default-default-industry-type",
};

const getAllCriteriaKeys = (allCriteriaDefinition) => {
	return allCriteriaDefinition.flatMap((searchGroup) =>
		Object.keys(searchGroup.criteria),
	);
};

const getQuickSearchCriteriaDefinition = (
	allQuickSearchCriteriaDefinitions,
	allCriteria,
	quickSearchCriteriaOrder,
) => {
	if (!allQuickSearchCriteriaDefinitions) {
		return null;
	}

	let finalDefinition = null;

	for (const definition of allQuickSearchCriteriaDefinitions) {
		let fields = Array.from(
			new Set(
				definition.fields.map(
					(field) => subKeyToKeyMap[field] || field,
				),
			),
		)
			.filter((field) => allCriteria.includes(field))
			.slice(0, 5);

		if (fields.length !== 5) {
			continue;
		}

		if (quickSearchCriteriaOrder) {
			fields.sort(
				(a, b) =>
					quickSearchCriteriaOrder[a] - quickSearchCriteriaOrder[b],
			);
		}

		finalDefinition = { ...definition, fields };
		break;
	}

	return finalDefinition;
};

const subKeyToKeyMap = {
	airline_display_name_codes: "airline_display_name",
	airline_display_name_free_text: "airline_display_name",
	engines_total_max: "engines_total",
	engines_total_min: "engines_total",
	number_in_fleet_max: "number_in_fleet",
	number_in_fleet_min: "number_in_fleet",
	total_seats_in_fleet_max: "total_seats_in_fleet",
	total_seats_in_fleet_min: "total_seats_in_fleet",
	total_seats_max: "total_seats",
	total_seats_min: "total_seats",
	airline_codes: "airline",
	airline_free_text: "airline",
	destination_airport_codes: "destination_airport",
	destination_airport_free_text: "destination_airport",
	origin_airport_codes: "origin_airport",
	origin_airport_free_text: "origin_airport",
	period_max: "period",
	period_min: "period",
	operator_codes: "operator",
	operator_free_text: "operator",
	flight_date_max: "flight_date",
	flight_date_min: "flight_date",
	lessor_name_codes: "lessor_name",
	lessor_name_free_text: "lessor_name",
	number_of_aircraft_max: "number_of_aircraft",
	number_of_aircraft_min: "number_of_aircraft",
	aircraft_construction_site_free_text: "aircraft_construction_site",
	aircraft_construction_site_codes: "aircraft_construction_site",
	aircraft_location_codes: "aircraft_location",
	aircraft_location_free_text: "aircraft_location",
	avg_annual_cycles_max: "avg_annual_cycles",
	avg_annual_cycles_min: "avg_annual_cycles",
	avg_annual_hours_max: "avg_annual_hours",
	avg_annual_hours_min: "avg_annual_hours",
	avg_daily_utilization_max: "avg_daily_utilization",
	avg_daily_utilization_min: "avg_daily_utilization",
	avg_daily_utilization_ttm_max: "avg_daily_utilization_ttm",
	avg_daily_utilization_ttm_min: "avg_daily_utilization_ttm",
	avg_stage_length_max: "avg_stage_length",
	avg_stage_length_min: "avg_stage_length",
	built_max: "built",
	built_min: "built",
	date_in_max: "date_in",
	date_in_min: "date_in",
	delivery_customer_codes: "delivery_customer",
	delivery_customer_free_text: "delivery_customer",
	delivery_max: "delivery",
	delivery_min: "delivery",
	end_of_life_max: "end_of_life",
	end_of_life_min: "end_of_life",
	flight_cycles_max: "flight_cycles",
	flight_cycles_min: "flight_cycles",
	flight_hours_max: "flight_hours",
	flight_hours_min: "flight_hours",
	future_operator_codes: "future_operator",
	future_operator_free_text: "future_operator",
	lease_end_date_max: "lease_end_date",
	lease_end_date_min: "lease_end_date",
	market_lease_rate_max: "market_lease_rate",
	market_lease_rate_min: "market_lease_rate",
	market_value_max: "market_value",
	market_value_min: "market_value",
	mtow_max: "mtow",
	mtow_min: "mtow",
	operator_wet_lease: "operator",
	order_cancellation_date_max: "order_cancellation_date",
	order_cancellation_date_min: "order_cancellation_date",
	order_date_max: "order_date",
	order_date_min: "order_date",
	order_entity_codes: "order_entity",
	order_entity_free_text: "order_entity",
	projected_retirement_date_max: "projected_retirement_date",
	projected_retirement_date_min: "projected_retirement_date",
	seating_max_abreast_max: "seating_max_abreast",
	seating_max_abreast_min: "seating_max_abreast",
	seating_pitch_max: "seating_pitch",
	seating_pitch_min: "seating_pitch",
	seating_seats_max: "seating_seats",
	seating_seats_min: "seating_seats",
	stored_as_of_max: "stored_as_of",
	stored_as_of_min: "stored_as_of",
	customer_codes: "customer",
	customer_free_text: "customer",
	arrival_airport_codes: "arrival_airport",
	arrival_airport_free_text: "arrival_airport",
	departure_airport_codes: "departure_airport",
	departure_airport_free_text: "departure_airport",
	date_as_of_max: "date_as_of",
	date_as_of_min: "date_as_of",
	date_out_max: "date_out",
	date_out_min: "date_out",
	operator_no_longer_operated: "operator",
	phrase_codes: "phrase",
	phrase_free_text: "phrase",
	search_range_check_avg_daily_ut: "search_range",
	search_range_check_avg_st_length: "search_range",
	search_range_check_cycles: "search_range",
	search_range_check_hours: "search_range",
	search_range_from_month: "search_range",
	search_range_from_year: "search_range",
	search_range_to_month: "search_range",
	search_range_to_year: "search_range",
	search_range_type: "search_range",
	search_range_utilisation_data_historical: "search_range",
	search_range_utilisation_data_type_1: "search_range",
	search_range_utilisation_data_type_2: "search_range",
	search_range_utilisation_data_type_aircraft: "search_range",
	search_range_end_month: "search_range",
	search_range_end_year: "search_range",
	search_range_start_month: "search_range",
	search_range_start_year: "search_range",
	forecast_amount: "forecast",
	forecast_from_month: "forecast",
	forecast_from_year: "forecast",
	forecast_to_month: "forecast",
	forecast_to_year: "forecast",
	forecast_type: "forecast",
};
