import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

export function Link({ url, label, target, classes, showTitle = false }) {
	return (
		<a
			className={clsx(classes)}
			href={url}
			target={target}
			title={showTitle ? label : undefined}
		>
			{label}
		</a>
	);
}

Link.propTypes = {
	url: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	target: PropTypes.string,
	classes: PropTypes.string,
	showTitle: PropTypes.bool,
};
