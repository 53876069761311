import Immutable from "immutable";
import {
	DELETE_CRITERION_CHOICES,
	SET_CRITERIA_YEARS,
	UPDATE_CRITERIA_CHOICES,
} from "./actions";

const initialState = Immutable.Map({
	choices: Immutable.Map(),
	years: Immutable.List(),
});

export const criteriaReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CRITERIA_YEARS:
			return state.mergeIn(["years"], action.data);
		case UPDATE_CRITERIA_CHOICES:
			return state.setIn(
				["choices", action.data.criterionKey],
				Immutable.Map(action.data.choices),
			);
		case DELETE_CRITERION_CHOICES:
			return state.deleteIn(["choices", action.data.criterionKey]);
		default:
			return state;
	}
};
