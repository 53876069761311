import { useDispatch, useSelector } from "react-redux";
import { selectView } from "./actions";
import { getSelectedView } from "./selectors";

export default function useSelectView() {
	const dispatch = useDispatch();
	const viewData = getViewParameters(useSelector(getSelectedView));

	const onSelectView = (id, shouldLoadResults = true) => {
		dispatch(selectView(id, shouldLoadResults));
	};

	return { viewData, onSelectView };
}

export function getViewParameters(view) {
	if (view) {
		const orderColumn = view.columns.find((c) => c.order !== null) || {
			name: "operator",
			sort: "asc",
		};
		return {
			columns: view.columns.map((c) => c.name).join(","),
			sort: orderColumn.name,
			ascending: orderColumn.order === "asc",
			selectedViewId: view.id,
		};
	} else {
		return {
			columns: undefined,
			sort: undefined,
			ascending: undefined,
			selectedViewId: undefined,
		};
	}
}
