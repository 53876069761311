import { flattenHashData } from "../../common/hash/util";
import { parametersToQuery } from "../api";

const downloadUrl = (namespace) => {
	return "/" + namespace + "/download?";
};

function getQuery(fileType, parameters, extraParameters = {}) {
	const params = Object.assign(
		{
			file_type: fileType,
			page_size: 999999,
		},
		parameters,
		extraParameters,
	);
	return parametersToQuery(params);
}

function getDownloadQuery(fileType, hashData, extraParameters = {}) {
	const flattenedHashData = flattenHashData(hashData);
	return getQuery(fileType, flattenedHashData, extraParameters);
}

export function getDownloadFullUrl(
	namespace,
	fileType,
	hashData,
	extraParameters = {},
) {
	return (
		downloadUrl(namespace) +
		getDownloadQuery(fileType, hashData, extraParameters)
	);
}

export function getDownloadDemoUrl(
	namespace,
	fileType,
	downloadDemoData,
	extraParameters = {},
) {
	return (
		downloadUrl(namespace) +
		getQuery(fileType, downloadDemoData, extraParameters)
	);
}
