import PropTypes, { arrayOf } from "prop-types";
import React from "react";
import useVisibilityState from "../../common/forms/useVisibilityState";
import useRolesVisibility from "../../common/roles/useRolesVisibility";
import { criteriaGroupType, notDeletableType } from "../../common/types";
import { ADVANCED_SEARCH } from "../constants";
import { SelectedChoices } from "../criteria/choices/SelectedChoices";
import useValidation from "../criteria/validation/useValidation";

export function AdvancedSearchModalGroup({
	group,
	notDeletable,
	visibleCriteria,
}) {
	const { validationResult } = useValidation();
	const {
		name,
		criteria,
		visibilitySelector = () => true,
		requiredRoles = null,
	} = group;
	const requiredFields = Object.keys(criteria).some((key) =>
		validationResult.get(key)?.isError(),
	);
	const visible = useVisibilityState(visibilitySelector);
	const { hasRequiredRoles } = useRolesVisibility(requiredRoles);

	if (!visible || !hasRequiredRoles) {
		return null;
	}

	return (
		<div key={name} className="grid search-criteria__wrapper">
			<div className="search-criteria__info grid__col-12">
				<span className="search-criteria__title">{name}</span>
				{requiredFields && (
					<span className="search-criteria__required">
						Required field
					</span>
				)}
				<SelectedChoices
					groupCriteria={criteria}
					notDeletable={notDeletable}
					searchType={ADVANCED_SEARCH}
				/>
			</div>

			<div className="search-criteria__criteria grid__col-12">
				{Object.entries(criteria).map(
					([key, value]) =>
						visibleCriteria.some((item) => item.key === key) && (
							<React.Fragment key={key}>
								{value.factory(key)}
							</React.Fragment>
						),
				)}
			</div>
		</div>
	);
}

AdvancedSearchModalGroup.propTypes = {
	group: criteriaGroupType.isRequired,
	notDeletable: notDeletableType,
	visibleCriteria: arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	),
};
