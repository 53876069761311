import Immutable from "immutable";

export function getSelectedChoices(input, codes, nameIndex) {
	if (!input && (!nameIndex || nameIndex.isEmpty())) {
		return;
	}

	const selectedCodes =
		codes &&
		codes.toJS().map((code) => {
			const label = nameIndex.get(code, code + "...");
			return { key: code, value: label };
		});

	const freeText = input && [{ key: "free_text", value: input }];

	return [...selectedCodes, ...freeText];
}

export function getChoicesInInput(choices, currentInput, visible = true) {
	if (!choices || !visible) {
		return;
	}

	if (currentInput instanceof Immutable.List) {
		return choices.filter((choice) => currentInput.includes(choice.key));
	}

	return choices.filter((choice) => currentInput === choice.key);
}

export function getBooleanChoices(
	criterionKey,
	choice,
	currentInput,
	visible = true,
) {
	if (currentInput !== "true" || !visible) {
		return [];
	}

	return [{ key: criterionKey, value: choice }];
}

export function getForecastChoices(currentInput, valid) {
	if (currentInput.isEmpty() || !valid) {
		return [];
	}

	const amount = currentInput.get("amount", "");
	const type = currentInput.get("type", "");
	const fromYear = currentInput.get("from_year", "");
	const fromMonth = currentInput.get("from_month", "");
	const toYear = currentInput.get("to_year", "");
	const toMonth = currentInput.get("to_month", "");

	const value =
		amount +
		" " +
		type +
		", from " +
		fromYear +
		"-" +
		fromMonth +
		" to " +
		toYear +
		"-" +
		toMonth;

	return [{ key: "forecast", value }];
}

export function getUtilisationSearchRangeChoices(currentInput, valid) {
	if (currentInput.isEmpty() || !valid) {
		return [];
	}

	const fromYear = currentInput.get("from_year", "");
	const fromMonth = currentInput.get("from_month", "");
	const toYear = currentInput.get("to_year", "");
	const toMonth = currentInput.get("to_month", "");

	return [
		{
			key: "search_range",
			value: `from ${fromYear}-${fromMonth} to ${toYear}-${toMonth}`,
		},
	];
}

export function getRangeChoices(inputRange, unit, visible = true) {
	if (!inputRange || !visible) {
		return [];
	}

	return [{ key: "range", value: `${inputRange} ${unit}` }];
}

export function getDateChoice(currentInput, valid, visible = true) {
	if (!currentInput || !valid || !visible) {
		return [];
	}
	return [{ key: "date", value: currentInput }];
}

export function getFreeTextChoice(currentInput, visible = true) {
	if (!currentInput || !visible) {
		return [];
	}
	return [{ key: "free_text", value: currentInput }];
}

export function sortChoices(choices) {
	return choices.sort((a, b) => a.get("label").localeCompare(b.get("label")));
}

export function getCriteriaKeys(groupCriteria) {
	const compositeCriteria = Object.entries(groupCriteria).filter(
		([key, value]) => value.factory && value.factory(key).props.criteria,
	);
	const compositeCriteriaChildren = compositeCriteria.reduce(
		(p, [key, value]) =>
			value
				.factory(key)
				.props.criteria.map((item) => item.props.criterionKey),
		[],
	);
	return Object.keys(groupCriteria).concat(compositeCriteriaChildren);
}

export function onRemoveChoice(key, currentInput, onInputChange) {
	const removeFromList = (key, list) => {
		const index = list.indexOf(key);
		return list.delete(index);
	};

	let newInput;
	let valid = true;

	if (
		currentInput instanceof Immutable.Map &&
		currentInput.has("free_text")
	) {
		if (key === "free_text") {
			newInput = currentInput.set("free_text", "");
		} else {
			const codes = removeFromList(key, currentInput.get("codes"));
			newInput = currentInput.set("codes", codes);
		}
	} else if (currentInput instanceof Immutable.Map) {
		valid = !currentInput.has("amount");
		newInput = Immutable.Map();
	} else if (currentInput instanceof Immutable.List) {
		newInput = removeFromList(key, currentInput);
	} else {
		newInput = "";
	}

	onInputChange(newInput, valid);
}
