import { criteriaGroupsType } from "../../common/types";
import useSetSelectedChoices from "../criteria/choices/useSetSelectedChoices";
import { flattenCriteria } from "../utils";

export const SelectedChoicesInitializer = ({ criteria }) => {
	useSetSelectedChoices(flattenCriteria(criteria));

	return null;
};

SelectedChoicesInitializer.propTypes = {
	criteria: criteriaGroupsType.isRequired,
};
