import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Loader } from "../../common/components/Loader";
import { CloseButton } from "../../common/components/modals/CloseButton";
import { Modal } from "../../common/components/modals/Modal";
import useModal from "../../common/components/modals/useModal";
import useSearchState from "../useSearchState";
import { numberWithSpaces } from "../utils";
import { CreateNewList } from "./CreateNewList";
import { FavoriteLists } from "./FavoriteLists";
import { getFavoritesAlertUrl } from "./favorites";
import useFavorites from "./useFavorites";
import useLoadFavorites from "./useLoadFavorites";

export function FavoritesModal({ modalKey, params }) {
	const { results } = useSearchState();
	const {
		favorites,
		selectedCodes,
		selectedCode,
		modal,
		allResults,
		handleAddEntities,
		handleDeleteEntities,
	} = useFavorites();
	const { fetching, fetchError } = useLoadFavorites();
	const { onCloseModal, clearModals } = useModal();

	const [favoritesState, setFavoritesState] = useState({
		name: "",
		listsSelected: [],
		listsToDeleteCodeFrom: [],
		createNew: false,
		loading: false,
		error: undefined,
		inputError: undefined,
	});

	const [createAlert, setCreateAlert] = useState(false);
	const [entitiesSelected, setEntitiesSelected] = useState(() =>
		allResults ? numberWithSpaces(results) : selectedCodes.size,
	);

	// Prefilling checked lists if one code selected and it is in some lists
	useEffect(() => {
		if (fetching) {
			return;
		}

		const listsIncludingSelectedCode = favorites
			.toJS()
			.filter((list) => list.codes.includes(selectedCode))
			.map((list) => list.id);

		setFavoritesState((favoritesState) => ({
			...favoritesState,
			listsSelected: listsIncludingSelectedCode,
			error: fetchError ? fetchError.message : undefined,
		}));

		return () => {
			setFavoritesState((favoritesState) => ({
				...favoritesState,
				listsSelected: [],
				error: fetchError ? fetchError.message : undefined,
			}));
		};
	}, [fetching, fetchError, selectedCode, favorites]);

	const listsSelected = favoritesState.listsSelected;

	const handleInputChange = (e) => {
		const value = e.target.value;
		setFavoritesState((favoritesState) => ({
			...favoritesState,
			name: value,
			error: undefined,
			inputError: value ? undefined : "Name is required.",
		}));
	};

	const handleCreateNewChange = (value) => {
		setFavoritesState((favoritesState) => ({
			...favoritesState,
			createNew: value,
			inputError: undefined,
		}));
	};

	const handleAddToLists = (addToLists) => {
		setFavoritesState((favoritesState) => ({
			...favoritesState,
			listsSelected: addToLists,
		}));
	};

	const handleDeleteFromLists = (deleteFromLists) => {
		setFavoritesState((favoritesState) => ({
			...favoritesState,
			listsToDeleteCodeFrom: deleteFromLists,
		}));
	};

	const handleCreateAlert = () => {
		setCreateAlert(!createAlert);
	};

	const update = () => {
		const codes = selectedCodes.toArray();

		const successHandler = (data) => {
			if (
				createAlert &&
				(listsSelected.length === 1 || favoritesState.createNew)
			) {
				setFavoritesState((favoritesState) => ({
					...favoritesState,
					loading: true,
					error: undefined,
				}));
				setEntitiesSelected(data[0].codes.length);
				document.location = getFavoritesAlertUrl(
					data,
					favorites,
					listsSelected[0],
				);
			} else {
				setFavoritesState((favoritesState) => ({
					...favoritesState,
					loading: false,
					error: undefined,
				}));
				clearModals();
			}
		};

		const failureHandler = (error) => {
			setFavoritesState((favoritesState) => ({
				...favoritesState,
				error: error.message,
				loading: false,
			}));
		};

		if (favoritesState.name || listsSelected.length > 0) {
			handleAddEntities(
				favoritesState.name,
				listsSelected,
				codes,
				params,
				successHandler,
				failureHandler,
			);
		}

		if (favoritesState.listsToDeleteCodeFrom.length > 0) {
			handleDeleteEntities(
				favoritesState.listsToDeleteCodeFrom,
				codes,
				successHandler,
				failureHandler,
			);
		}
	};

	const handleSubmit = () => {
		const noListsSelected = listsSelected.length === 0;
		const noDeleteFromListsSelected =
			favoritesState.listsToDeleteCodeFrom.length === 0;
		const createNew = favoritesState.createNew;
		const noInput = !favoritesState.name;

		if (favorites.isEmpty()) {
			if (noInput) {
				setFavoritesState((favoritesState) => ({
					...favoritesState,
					inputError: "Name is required.",
				}));
				return;
			}
		} else {
			if (createNew && noInput) {
				setFavoritesState((favoritesState) => ({
					...favoritesState,
					inputError: "Name is required.",
					error: undefined,
				}));
				return;
			}

			if (noListsSelected && noDeleteFromListsSelected && !createNew) {
				setFavoritesState((favoritesState) => ({
					...favoritesState,
					error: "Please select a list or create a new one.",
				}));
				return;
			}
		}

		setFavoritesState((favoritesState) => ({
			...favoritesState,
			loading: true,
		}));

		update();
	};

	let body;
	let btnPrimaryText;
	let btnSecondaryText = "Cancel";
	const loading = favoritesState.loading;
	const error = favoritesState.error;

	const modalTitle = `${modal.get("title", "")} (${entitiesSelected}) to favorites`;

	if (fetching && !loading) {
		body = <Loader label="Loading favourite lists..." />;
	} else if (loading) {
		body = <Loader label="Loading..." />;
		btnPrimaryText = selectedCode
			? "Saving..."
			: favorites.isEmpty()
				? "Creating..."
				: "Adding...";
	} else if (fetchError) {
		body = (
			<p className="error-text" role="alert">
				An error occurred: {fetchError.message}
			</p>
		);
		btnPrimaryText = "Close";
		btnSecondaryText = "";
	} else {
		if (favorites.isEmpty()) {
			body = (
				<CreateNewList
					error={favoritesState.inputError}
					onChange={handleInputChange}
					onCreateAlert={handleCreateAlert}
				/>
			);
			btnPrimaryText = "Create list";
		} else {
			body = (
				<FavoriteLists
					favorites={favorites}
					listsSelected={listsSelected}
					listsToDeleteCodeFrom={favoritesState.listsToDeleteCodeFrom}
					createNew={favoritesState.createNew}
					selectedCode={selectedCode}
					lead={modal.get("lead", "")}
					error={favoritesState.inputError}
					onCreateNewChange={handleCreateNewChange}
					onInputChange={handleInputChange}
					onAddToLists={handleAddToLists}
					onDeleteFromLists={handleDeleteFromLists}
					onCreateAlert={handleCreateAlert}
				/>
			);
			btnPrimaryText = selectedCode ? "Save" : "Add to lists";
		}
	}

	return (
		<Modal
			modalKey={modalKey}
			title={modalTitle}
			alignContent={fetchError && "modal__container--center"}
		>
			<div
				className={clsx("modal__content", {
					loading: fetching || loading,
					error: fetchError,
				})}
			>
				{body}
			</div>

			<footer className={clsx("flex modal__actions", { error })}>
				{error && <span className="error-text">{error}</span>}
				{btnSecondaryText && (
					<CloseButton
						classes="btn btn--secondary"
						onClick={onCloseModal}
						modalKey={modalKey}
						label={btnSecondaryText}
					/>
				)}
				<button
					type="button"
					className="btn--primary"
					onClick={handleSubmit}
				>
					{btnPrimaryText}
				</button>
			</footer>
		</Modal>
	);
}

FavoritesModal.propTypes = {
	modalKey: PropTypes.string.isRequired,
	params: PropTypes.object,
};
