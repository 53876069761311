import PropTypes from "prop-types";
import React from "react";
import { radioChoicesType } from "../../../../common/types";

export default function RadioButtons({
	label,
	currentInput,
	criterionKey,
	onInputChange,
	choices,
}) {
	const isChecked = (key) => {
		return currentInput === key;
	};

	const radioButtons = choices.map((choice) => {
		const { key, value } = choice;

		return (
			<div key={key} className="radio-input">
				<input
					type="radio"
					id={key}
					name={criterionKey}
					value={key}
					checked={isChecked(key)}
					onChange={() => onInputChange(key)}
				/>
				<label htmlFor={key}>{value}</label>
			</div>
		);
	});

	return (
		<fieldset>
			<legend>{label}</legend>

			{radioButtons}
		</fieldset>
	);
}

RadioButtons.propTypes = {
	choices: radioChoicesType.isRequired,
	criterionKey: PropTypes.string.isRequired,
	currentInput: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onInputChange: PropTypes.func.isRequired,
};
