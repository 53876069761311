import jquery from "jquery";
import { onFailure } from "../../common/api/api";
import { prepareColumns } from "./View";

const apiPath = "/api/v1/me/views";

export function createView(
	name,
	namespace,
	columns,
	successHandler,
	failureHandler,
) {
	const data = JSON.stringify({
		name,
		columns: prepareColumns(columns),
	});
	jquery
		.ajax({
			type: "POST",
			url: apiPath + "/" + namespace,
			data,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
		})
		.done(successHandler)
		.fail(onFailure(failureHandler, "Failed to save view."));
}

export function loadViews(successHandler, failureHandler, namespace) {
	jquery
		.ajax({
			type: "GET",
			url: apiPath + "/" + namespace,
			dataType: "json",
		})
		.done(successHandler)
		.fail(onFailure(failureHandler));
}

export function updateView(
	id,
	name,
	namespace,
	columns,
	successHandler,
	failureHandler,
) {
	const data = JSON.stringify({
		name,
		columns: columns,
	});

	jquery
		.ajax({
			type: "PUT",
			url: apiPath + "/" + namespace + "/" + id,
			data,
			contentType: "application/json; charset=utf-8",
			dataType: "json",
		})
		.done(successHandler)
		.fail(onFailure(failureHandler, "Failed to update view."));
}

export function deleteView(id, namespace, successHandler, failureHandler) {
	jquery
		.ajax({
			type: "DELETE",
			url: apiPath + "/" + namespace + "/" + id,
		})
		.done(successHandler)
		.fail(onFailure(failureHandler, "Failed to delete view."));
}
