import PropTypes from "prop-types";
import React from "react";
import { columnsVisibleType, pagingType } from "../../../../common/types";

export function SearchResultsFooterRow({
	columnsVisible,
	paging,
	columnFooterGenerator,
}) {
	if (!columnFooterGenerator) {
		return null;
	}

	return (
		<tfoot>
			<tr>{columnFooterGenerator(columnsVisible, paging)}</tr>
		</tfoot>
	);
}

SearchResultsFooterRow.propTypes = {
	columnsVisible: columnsVisibleType.isRequired,
	paging: pagingType.isRequired,
	columnFooterGenerator: PropTypes.func,
};
