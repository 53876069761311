export const getFavoritesAlertUrl = (data, favorites, selectedListId) => {
	let alertId;
	let listId;

	if (selectedListId) {
		alertId = favorites.find((f) => f.id === selectedListId).alertId;
		listId = selectedListId;
	} else {
		alertId = data[0].alertId;
		listId = data[0].id;
	}

	const alertExists = alertId > 0;

	return alertExists
		? "/account-management/alerts/" + alertId
		: "/account-management/alerts/favourite-list/" + listId;
};

export const modalContent = (type) => {
	const content = {
		entity: {
			title: "Add or Update all selected results",
			lead: "Add or Update all operators selected",
		},
		entities: {
			title: "Add all selected results",
			lead: "Add all operators selected",
		},
		page: {
			title: "Add all results on page",
			lead: "Add all operators on page",
		},
		all: {
			title: "Add all results",
			lead: "Add all operators in the search result",
		},
	};

	return content[type];
};
