import {useDispatch} from "react-redux";
import {setPreviewBlocked} from "../preview/actions";

export const useBlockPreview = () => {
    const dispatch = useDispatch();

    const blockPreview = () => {
        dispatch(setPreviewBlocked(true));
    }

    const allowPreview = () => {
        dispatch(setPreviewBlocked(false));
    }

    return {blockPreview, allowPreview};
}