import queryString from "query-string";

export function buildFetchURL(endpoint, params) {
	if (!params) {
		return endpoint;
	}

	const query = queryString.stringify(params, { arrayFormat: "bracket" });

	return `${endpoint}?${query}`;
}
