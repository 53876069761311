import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import useDropdownPosition from "../useDropdownPosition";

export default function Suggestions({
	active,
	value,
	suggestions,
	dropdownIsOpened,
	codeFetchKey,
	onSuggestionSelected,
	dropdownRef,
	quickSearch,
	loading,
}) {
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
		[suggestions, loading, value],
	);

	if (value.length === 0 || suggestions.length === 0 || !dropdownIsOpened) {
		return null;
	}

	const options = suggestions.map((item) => {
		const { name, [codeFetchKey]: code, disabled = false } = item;

		return (
			<li
				key={code}
				aria-selected="false"
				className={clsx("autosuggest__suggestion", {
					active: code === active,
				})}
			>
				<button
					type="button"
					id={code}
					disabled={disabled}
					onClick={(e) => onSuggestionSelected(e.target.id)}
				>
					{name}
				</button>
			</li>
		);
	});

	return (
		<div
			ref={dropdownRef}
			className={clsx("autosuggest__suggestions-container", {
				"autosuggest__suggestions-container--scrollable":
					suggestions.length > 10,
				"autosuggest__suggestions-container--inverted-y":
					isDropdownBelowViewport,
				"autosuggest__suggestions-container--hidden": isDropdownHidden,
			})}
		>
			{loading && (
				<div className="autosuggest__suggestions-loading loading">
					<div className="loader" />
				</div>
			)}
			{!loading && (
				<ul className="autosuggest__suggestions-list">{options}</ul>
			)}
		</div>
	);
}

Suggestions.propTypes = {
	active: PropTypes.string,
	codeFetchKey: PropTypes.string.isRequired,
	dropdownIsOpened: PropTypes.bool.isRequired,
	onSuggestionSelected: PropTypes.func.isRequired,
	quickSearch: PropTypes.bool,
	suggestions: PropTypes.array.isRequired,
	value: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	dropdownRef: PropTypes.object,
};
