import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useDropdown } from "../../common/components/dropdown/useDropdown";

export function ViewActionsDropdown({
	onLoadViewClick,
	onSaveViewAsClick,
	onSaveViewClick,
	saveViewEnabled,
}) {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();

	const handleSaveViewAsClick = () => {
		onDropdownToggle();
		onSaveViewAsClick();
	};

	const handleSaveViewClick = () => {
		onDropdownToggle();
		onSaveViewClick();
	};

	const handleLoadViewClick = () => {
		onDropdownToggle();
		onLoadViewClick();
	};

	return (
		<div
			ref={dropdownRef}
			className="dropdown dropdown--secondary dropdown--right"
		>
			<button
				type="button"
				className="dropdown-toggle btn--icon btn--icon--lg btn--more"
				aria-label="More"
				aria-expanded={dropdownIsOpened}
				onClick={onDropdownToggle}
			>
				<span aria-hidden={true}></span>
			</button>

			<div
				className={clsx("dropdown-menu", {
					"dropdown-menu--opened": dropdownIsOpened,
				})}
			>
				<div className="dropdown-menu-items">
					<button
						type="button"
						className="dropdown-item btn--has-icon btn--save"
						onClick={handleSaveViewAsClick}
					>
						<span>Save view as</span>
					</button>

					{saveViewEnabled && (
						<button
							type="button"
							className="dropdown-item btn--has-icon btn--save"
							onClick={handleSaveViewClick}
						>
							<span>Save view</span>
						</button>
					)}

					<button
						type="button"
						className="dropdown-item btn--has-icon btn--load"
						onClick={handleLoadViewClick}
					>
						<span>Load view</span>
					</button>
				</div>
			</div>
		</div>
	);
}

ViewActionsDropdown.propTypes = {
	onLoadViewClick: PropTypes.func.isRequired,
	onSaveViewAsClick: PropTypes.func.isRequired,
	onSaveViewClick: PropTypes.func.isRequired,
	saveViewEnabled: PropTypes.bool.isRequired,
};
