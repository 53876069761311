export function getFavorites(state) {
	return state.getIn(["favorites", "favoriteLists"]);
}

export function getSelectedCodes(state) {
	return state.getIn(["favorites", "codes"]);
}

export function getModalContent(state) {
	return state.getIn(["favorites", "modal"]);
}

export function getAddAllResults(state) {
	return state.getIn(["favorites", "addAllResults"]);
}

export function isFetchingFavorites(state) {
	return state.getIn(["favorites", "isFetching"]);
}

export function getFavoritesFetchError(state) {
	return state.getIn(["favorites", "fetchError"]);
}

function hasFetchedFavorites(state) {
	return state.getIn(["favorites", "isFetched"]);
}

export function isUpdatingFavorites(state) {
	return state.getIn(["favorites", "isUpdating"]);
}

export function isDeletingFromFavorites(state) {
	return state.getIn(["favorites", "isDeleting"]);
}

export function shouldFetchFavorites(state) {
	return (
		!isFetchingFavorites(state) &&
		!hasFetchedFavorites(state) &&
		!getFavoritesFetchError(state) &&
		!isUpdatingFavorites(state) &&
		!isDeletingFromFavorites(state)
	);
}
