import PropTypes from "prop-types";
import React from "react";
import { Switch } from "../../common/components/Switch";

export function FavoritesAlert({ onCreateAlert }) {
	return (
		<section className="modal__section">
			<Switch
				id="create-alert-for-favorites"
				name="create-alert-for-favorites"
				label="Alert via Email"
				onChange={onCreateAlert}
				reverse={true}
			/>
		</section>
	);
}

FavoritesAlert.propTypes = {
	onCreateAlert: PropTypes.func.isRequired,
};
