import { useEffect, useState } from "react";
import { useAccessWindowSize } from "../../../common/layout/useAccessWindowSize";

export default function useDropdownPosition(
	dropdownRef,
	dropdownIsOpened,
	disabled = false,
	externalTriggers = [],
) {
	const [isDropdownBelowViewport, setIsDropdownBelowViewport] =
		useState(false);
	const [isDropdownRightOfViewport, setIsDropdownRightOfViewport] =
		useState(false);
	const [isDropdownHidden, setIsDropdownHidden] = useState(true);
	const { isMobile } = useAccessWindowSize();

	useEffect(() => {
		if (disabled || isMobile) {
			setIsDropdownHidden(false);
			return;
		}

		if (!dropdownIsOpened) {
			setIsDropdownHidden(true);
			setIsDropdownBelowViewport(false);
			setIsDropdownRightOfViewport(false);
			return;
		}

		const boundingRect =
			dropdownRef.current?.lastChild?.getBoundingClientRect();

		if (!boundingRect) {
			setIsDropdownBelowViewport(false);
			setIsDropdownRightOfViewport(false);
			setIsDropdownHidden(true);
			return;
		}

		const percentageUnder = Math.trunc(
			(boundingRect.top / window.innerHeight) * 100,
		);
		const windowHeight = window.innerHeight - 70;
		const bottomOfElement = boundingRect.bottom;

		if (windowHeight < bottomOfElement) {
			if (percentageUnder > 60) {
				setIsDropdownBelowViewport(true);
			} else {
				dropdownRef.current?.lastChild?.scrollIntoView({
					behavior: "smooth",
					block: "end",
					inline: "nearest",
				});
			}
		}

		const windowWidth = window.innerWidth;
		const rightEdge = boundingRect.right;

		if (windowWidth < rightEdge) {
			setIsDropdownRightOfViewport(true);
		}

		setIsDropdownHidden(false);
	}, [dropdownIsOpened, ...externalTriggers]);

	return {
		isDropdownBelowViewport,
		isDropdownRightOfViewport,
		isDropdownHidden,
	};
}
