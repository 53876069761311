import { useSelector } from "react-redux";
import { getVisibility } from "../input/selectors";

export default function useVisibilityState(visibilitySelector) {
	if (!visibilitySelector) {
		return true;
	}

	const { criterionKey, visibleOn } = visibilitySelector;
	return useSelector((state) =>
		getVisibility(state, criterionKey, visibleOn),
	);
}
