import useSWR from "swr";
import { fetch } from "whatwg-fetch";
import { buildFetchURL } from "../../search/criteria/buildFetchUrl";
import { reportErrorToSentry } from "../../ui/sentry";

export default function useFetchData({
	endpoint,
	params = undefined,
	shouldFetch = true,
	immutable = true,
} = {}) {
	const fetcher = (...args) =>
		fetch(...args).then((res) => {
			if (!res.ok) {
				const error = new Error(
					`"${res.status}" error occurred while fetching the data.`,
				);
				error.status = res.status;
				throw error;
			}

			return res.json();
		});

	const key = shouldFetch ? buildFetchURL(endpoint, params) : null;

	const { data, error, mutate } = useSWR(key, fetcher, {
		revalidateIfStale: !immutable,
		revalidateOnFocus: !immutable,
		revalidateOnReconnect: !immutable,
		onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
			// Never retry on 404 or 500.
			if (error.status === 404 || error.status === 500) {
				return;
			}

			// Only retry up to 2 times.
			if (retryCount >= 2) {
				return;
			}

			// Retry after 5 seconds.
			setTimeout(() => revalidate({ retryCount }), 5000);
		},
		onError: (error) => reportErrorToSentry(error),
	});

	return {
		data,
		loading: shouldFetch && !error && !data,
		error,
		mutate,
	};
}
