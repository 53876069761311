import PropTypes from "prop-types";
import React from "react";
import useVisibilityState from "../../common/forms/useVisibilityState";
import { viewsType } from "../../common/types";
import { SelectView } from "../views/SelectView";
import { ViewActions } from "../views/ViewActions";

export function ViewsActions({ namespace, views, resultsAreLoaded }) {
	const visible = useVisibilityState(views.visibilitySelector);

	if (!views.viewsEnabled || !visible) {
		return null;
	}

	return (
		<div className="actions__action view-actions">
			<SelectView namespace={namespace} />
			{resultsAreLoaded && <ViewActions namespace={namespace} />}
		</div>
	);
}

ViewsActions.propTypes = {
	namespace: PropTypes.string.isRequired,
	resultsAreLoaded: PropTypes.bool.isRequired,
	views: viewsType.isRequired,
};
