export function getViews(state) {
	return state.getIn(["search", "views", "views"]);
}

export function getView(state, id) {
	return state.getIn(["search", "views", "views", id]);
}

export function isLoadingViews(state) {
	return state.getIn(["search", "views", "loading"]);
}

export function isLoadedViews(state) {
	return state.getIn(["search", "views", "loaded"]);
}

export function getSelectedView(state) {
	const selectedId = state.getIn(["search", "views", "selectedViewId"]);
	return selectedId ? getView(state, selectedId) : undefined;
}

export function getSelectedViewIdFromHash(state) {
	return state.getIn(["hash", "parameters", "selectedViewId"]);
}

export function getViewToDelete(state) {
	return state.getIn(["search", "views", "viewToDelete"]);
}

export function isViewDirty(state) {
	return state.getIn(["search", "views", "dirty"]);
}

export function shouldLoadResults(state) {
	return state.getIn(["search", "views", "shouldLoadResults"]);
}

export function shouldLocalViewInterceptSearchParameters(state) {
	return state.getIn([
		"search",
		"views",
		"shouldLocalViewInterceptSearchParameters",
	]);
}

export function isLocalViewChecked(state) {
	return state.getIn(["search", "views", "localViewChecked"]);
}
