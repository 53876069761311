import { columnsType } from "../../../../common/types";
import useSetColumns from "./useSetColumns";

export const ColumnsInitializer = ({ columns }) => {
	useSetColumns(columns);

	return null;
};

ColumnsInitializer.propTypes = {
	columns: columnsType,
};
