import { parseISO } from "date-fns";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import React from "react";
import InputMask from "react-input-mask";
import { DatePickerWrapper } from "../date/DatePickerWrapper";

export function createDefaultInput(id, label, onChange, placeholder, value) {
	return (
		<input
			type="number"
			id={id}
			value={value}
			aria-label={label}
			placeholder={placeholder}
			onChange={(e) => onChange(e.target.value)}
			data-criterion={true}
		/>
	);
}

export function createMaskedInput(id, label, onChange, placeholder, value) {
	return (
		<InputMask
			type="text"
			id={id}
			value={value}
			placeholder={placeholder}
			aria-label={label}
			mask="99:99"
			alwaysShowMask
			maskChar="0"
			onChange={(e) => onChange(e.target.value)}
			data-criterion
		/>
	);
}

export function createDatePicker(
	id,
	label,
	onChange,
	placeholder,
	value,
	filterDate,
	years,
) {
	return (
		<DatePickerWrapper
			id={id}
			filterDate={filterDate}
			onChange={onChange}
			value={value}
			years={years}
		/>
	);
}

export function formatInput(date) {
	if (!date) {
		return "";
	}

	return format(date, "ddMMMyyyy").toUpperCase();
}

export function inputToDate(input) {
	return parse(input, "ddMMMyyyy", new Date());
}

export function isInputValidDate(input) {
	return isValid(inputToDate(input));
}

export const getInputRange = (inputMin, inputMax, dateRange) => {
	let min = inputMin;
	let max = inputMax;

	if (min || max) {
		if (dateRange) {
			min = min ? formatInput(parseISO(min)) : min;
			max = max ? formatInput(parseISO(max)) : max;
		}

		return (min || "...") + " - " + (max || "...");
	}

	return null;
};

export const filterDate = (date, min = 2000, max = 2100) => {
	if (!date) {
		return true;
	}

	const year = date.getFullYear();

	return year >= min && year <= max;
};
