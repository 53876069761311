import Immutable from "immutable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../common/components/toasts/actions";
import { TOAST_FAILED_RESULTS_FOR_PRINT } from "../../common/components/toasts/constants";
import { requestSearch } from "../api";
import useSearchState from "../useSearchState";
import {
	setPrintResults,
	setPrintResultsError,
	setPrintResultsLoading,
} from "./actions";
import { shouldLoadPrintResults } from "./selectors";

export default function useRequestResultsForPrint(namespace) {
	const dispatch = useDispatch();
	const shouldFetch = useSelector(shouldLoadPrintResults);
	const { hashData } = useSearchState([""]);

	useEffect(() => {
		if (shouldFetch) {
			const params = hashData.mergeIn(
				["parameters"],
				Immutable.Map({ page_size: 1000000 }),
			);
			dispatch(setPrintResultsLoading());

			requestSearch(
				namespace,
				params,
				(result) => {
					dispatch(setPrintResults(result));
				},
				() => {
					dispatch(setPrintResultsError());
					dispatch(addToast(TOAST_FAILED_RESULTS_FOR_PRINT));
				},
			);
		}
	}, [shouldFetch, hashData]);
}
