import {
	criteriaType,
	customizeQuickSearchType,
	namespaceType,
} from "../../common/types";
import { useFetchQuickSearchCriteria } from "../criteria/quick-search/useFetchQuickSearchCriteria";

export const QuickSearchCriteriaInitializer = ({
	namespace,
	criteria,
	customizeQuickSearch,
}) => {
	useFetchQuickSearchCriteria(
		namespace,
		criteria.quickSearchFallbackCriteria,
		criteria.shouldFetchQuickSearchCriteria,
		criteria.criteria,
		criteria.quickSearchCriteriaOrder,
		customizeQuickSearch,
	);

	return null;
};

QuickSearchCriteriaInitializer.propTypes = {
	namespace: namespaceType.isRequired,
	criteria: criteriaType.isRequired,
	customizeQuickSearch: customizeQuickSearchType,
};
