import Immutable from "immutable";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../../common/hash/actions";
import { getSearchResultColumns } from "../../result/selectors";
import { getColumns } from "../../result/table/columns/selectors";
import { setViewColumnsChanged } from "../actions";
import useSelectView from "../useSelectView";

export default function useColumnsState() {
	const dispatch = useDispatch();
	const searchResultColumns = useSelector(getSearchResultColumns);
	const columns = useSelector(getColumns);
	const { onSelectView } = useSelectView();
	const columnsInput = searchResultColumns
		? searchResultColumns.map((c) => c.get("key")).toSet()
		: Immutable.Set();

	const onColumnsHaveChanged = () => {
		dispatch(setViewColumnsChanged());
	};

	const onChangeColumns = (columns) => {
		if (!columns) {
			onSelectView(0);
			return;
		}
		dispatch(updateSearchParameters({ columns }));
	};

	return {
		columns,
		columnsInput,
		onColumnsHaveChanged,
		onChangeColumns,
	};
}
