import { useEffect, useRef } from "react";

export function useFocus(autoFocus) {
	const ref = useRef();

	useEffect(() => {
		if (autoFocus) {
			ref.current.focus();
		}
	}, [autoFocus]);

	return { ref };
}
