export const generateDropdownItems = (
	options,
	fromYear,
	toYear,
	trimYear = false,
) => {
	const { startYear, startMonth, endYear, endMonth } = getApiData(options);

	let items = {
		from: {
			years: [],
			months: [],
		},
		to: {
			years: [],
			months: [],
		},
	};

	const pushDropdownItem = (pushTo, key) =>
		pushTo.push({ key, value: key.toString() });

	const generateMonths = (key, from, to) => {
		for (let y = from; y <= to; y++) {
			pushDropdownItem(items[key].months, y);
		}
	};

	for (let i = startYear; i <= endYear; i++) {
		pushDropdownItem(items.from.years, i);
		if (!(trimYear && i < fromYear)) {
			pushDropdownItem(items.to.years, i);
		}
	}

	// Generate "From" months
	if (startYear === endYear) {
		generateMonths("from", startMonth, endMonth);
	} else if (fromYear === startYear) {
		generateMonths("from", startMonth, 12);
	} else if (fromYear === endYear) {
		generateMonths("from", 1, endMonth);
	} else {
		generateMonths("from", 1, 12);
	}

	// Generate "To" months
	if (startYear === endYear) {
		generateMonths("to", startMonth, endMonth);
	} else if (toYear === startYear) {
		generateMonths("to", startMonth, 12);
	} else if (toYear === endYear) {
		generateMonths("to", 1, endMonth);
	} else {
		generateMonths("to", 1, 12);
	}

	return items;
};

export const onYearClick = (
	key,
	year,
	apiData,
	currentInput,
	onInputChange,
) => {
	const month = currentInput.get(`${key}_month`, "");

	const newInput = currentInput.merge({
		[`${key}_year`]: year,
		[`${key}_month`]: getNewMonth(year, month, apiData),
	});

	onInputChange(newInput);
};

function getApiData(data) {
	const startYear = data.start.year;
	const startMonth = data.start.month;
	const endYear = data.end.year;
	const endMonth = data.end.month;

	return { startYear, startMonth, endYear, endMonth };
}

function getNewMonth(year, month, apiData) {
	const { startYear, startMonth, endYear, endMonth } = getApiData(apiData);
	const currentMonth = month;
	const currentYear = year;

	if (currentYear === startYear) {
		return Math.max(startMonth, currentMonth);
	} else if (currentYear === endYear) {
		return Math.min(endMonth, currentMonth);
	} else {
		return currentMonth;
	}
}
