export function getCurrentFieldInput(state, criterionKey) {
	return state.getIn(["input", criterionKey]);
}

export function getCurrentInput(state) {
	return state.get("input");
}

export function getCriteriaVisibility(state, criteria) {
	return criteria
		.map((item) => item.props)
		.every((item) => {
			const visibilitySelector = item.visibilitySelector;

			if (!visibilitySelector) {
				return true;
			}

			const { criterionKey, visibleOn } = visibilitySelector;
			return getCurrentFieldInput(state, criterionKey) === visibleOn;
		});
}

export function getVisibility(state, criterionKey, visibleOn) {
	return getCurrentFieldInput(state, criterionKey) === visibleOn;
}

export function getResultBy(state) {
	return state.getIn(["input", "result_by"]);
}
