import { useEffect } from "react";

export default function useEnterToSearch(onSearch) {
	const handleEnterPress = (e) => {
		if (e.keyCode !== 13) {
			return;
		}
		const modalTitle = document.getElementById("modalTitle")?.innerText;
		if (
			(Array.from(document.activeElement.classList || []).includes(
				"page--search",
			) ||
				document.activeElement?.dataset?.criterion === "true" ||
				document.activeElement?.parentElement?.parentElement
					?.parentElement?.dataset?.criterion === "true" ||
				document.activeElement?.parentElement?.parentElement
					.parentElement.parentElement.parentElement?.dataset
					?.criterion === "true") &&
			![
				"Save Search",
				"Save View",
				"Load Search",
				"Load View",
				"Customise filters",
				"Edit columns",
			].includes(modalTitle) &&
			!Array.from(
				document.activeElement?.parentElement?.classList || [],
			).includes("error")
		) {
			onSearch();
			const dropdown = document.getElementsByClassName(
				"dropdown-menu--opened",
			)[0];
			dropdown?.previousSibling.setAttribute("aria-expanded", false);
			dropdown?.classList.remove("dropdown-menu--opened");
			document.activeElement.blur();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEnterPress);
		return () => document.removeEventListener("keydown", handleEnterPress);
	}, []);
}
