import Immutable from "immutable";
import { HASH_CHANGED } from "../../common/hash/actions";
import {
	CLEAR_INPUT,
	INPUT_FIELD_CHANGED,
	SET_DEFAULT_INPUT,
} from "../../common/input/actions";
import { SEARCH_RESULT } from "../result/actions";
import { FORM_SEARCHES_SELECT_SEARCH } from "../searches/actions";
import {
	SEARCH_GET_PREVIEW,
	SEARCH_GET_PREVIEW_FAILED,
	SEARCH_PREVIEW,
	SEARCH_PREVIEW_SET_BLOCKED,
} from "./actions";

const initialState = Immutable.Map({
	counts: undefined,
	valid: false,
	loading: false,
	error: false,
	blocked: false,
});

export const previewReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_GET_PREVIEW:
			// Set preview to valid once we start to load. If the flag is set to false while we load, a new load is
			// triggered immediately.
			return state.merge({
				valid: true,
				loading: true,
				error: false,
			});
		case SEARCH_GET_PREVIEW_FAILED:
			return state.merge({
				counts: undefined,
				valid: true, // To break an infinite loop of server requests if we get an error from the server.
				loading: false,
				error: true,
			});
		case SEARCH_PREVIEW:
			return state.merge({
				counts: Immutable.Map(action.data.counts),
				loading: false,
				error: false,
			});
		case SEARCH_PREVIEW_SET_BLOCKED:
			return state.merge({
				blocked: action.data.blocked,
			});
		case HASH_CHANGED:
		case FORM_SEARCHES_SELECT_SEARCH:
		case INPUT_FIELD_CHANGED:
		case SET_DEFAULT_INPUT:
		case CLEAR_INPUT:
			return state.merge({
				valid: false,
				error: false,
			});
		case SEARCH_RESULT:
			return state.merge({
				counts: Immutable.Map(action.data.result.paging),
				loading: false,
				error: false,
			});
		default:
			return state;
	}
};
