import { useEffect } from "react";
import useFetchData from "../../../../common/api/useFetchData";

export function useLoadUtilisationSearchRangeData(
	endpoint,
	currentInput,
	onInputChange,
) {
	const { data, loading, error } = useFetchData({ endpoint });

	useEffect(() => {
		if (data && currentInput.isEmpty()) {
			onInputChange(
				currentInput.merge({
					from_year: data.end.year - 3,
					from_month: data.end.month,
					to_year: data.end.year,
					to_month: data.end.month,
					check_avg_daily_ut: true,
					check_avg_st_length: true,
					check_cycles: true,
					check_hours: true,
					type: "historical_data",
					utilisation_data_historical: true,
					utilisation_data_type_1: true,
					utilisation_data_type_2: true,
					utilisation_data_type_aircraft: true,
				}),
				true,
			);
		}
	}, [data, currentInput]);

	return { data, loading, error };
}
