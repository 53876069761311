import React, { useState } from "react";
import { InputSearch } from "../../../common/components/modals/InputSearch";
import { Overlay } from "../../../common/components/modals/Overlay";
import useModal from "../../../common/components/modals/useModal";
import useRoles from "../../../common/roles/useRoles";
import { namespaceType } from "../../../common/types";
import { useLocalSearchView } from "../../../common/useLocalSearchView";
import useSelectAll from "../../criteria/components/useSelectAll";
import useSearchState from "../../useSearchState";
import { visibleOnUserInput } from "../../utils";
import { ColumnsGroup } from "./ColumnsGroup";
import { EditViewFooter } from "./EditViewFooter";
import useColumnsState from "./useColumnsState";
import {
	columnsByGroups,
	filterColumnsToRender,
	getMandatoryColumns,
	getOrderedColumns,
	validateColumnInput,
} from "./utils";

export function EditView({ namespace }) {
	const { columns, columnsInput, onChangeColumns, onColumnsHaveChanged } =
		useColumnsState();
	const { currentInput } = useSearchState([""]);
	const { clearSearchView } = useLocalSearchView(namespace);
	const { clearModals } = useModal();
	const { roles } = useRoles();

	const columnsToRender = filterColumnsToRender(
		columns,
		currentInput,
		roles,
	).toJS();
	const orderedColumns = getOrderedColumns(columnsToRender);
	const mandatoryColumns = getMandatoryColumns(columnsToRender, currentInput);
	const validatedColumns = validateColumnInput(
		columnsInput,
		columns,
		currentInput,
		roles,
	);

	const [visibleColumns, setVisibleColumns] = useState(columnsToRender);
	const [selection, setSelection] = useState(validatedColumns);

	const onInputChange = (input) => {
		onColumnsHaveChanged();
		setSelection(input);
	};

	const { allChecked, onSelectAll } = useSelectAll(
		visibleColumns,
		selection,
		onInputChange,
		mandatoryColumns,
	);

	const newColumnsInput = orderedColumns
		.keySeq()
		.filter((key) => selection.contains(key))
		.join(",");

	const handleInvertClick = () => {
		const newSelection = orderedColumns
			.keySeq()
			.filter(
				(key) =>
					!selection.contains(key) || mandatoryColumns.includes(key),
			)
			.toSet();

		onColumnsHaveChanged();
		setSelection(newSelection);
	};

	const handleDefaultClick = () => {
		clearSearchView();
		onChangeColumns(undefined);
		onColumnsHaveChanged();
		clearModals();
	};

	const handleSaveClick = () => {
		onChangeColumns(newColumnsInput);
		clearModals();
	};

	const handleSearchInputChange = (input) => {
		const visible = columnsToRender.filter((col) =>
			visibleOnUserInput(col.label, input),
		);
		setVisibleColumns(visible);
	};

	const groups = columnsByGroups(visibleColumns).map((columns) => {
		return (
			<ColumnsGroup
				key={columns[0].group.id}
				columns={columns}
				mandatoryColumns={mandatoryColumns}
				onInputChange={onInputChange}
				selection={selection}
			/>
		);
	});

	return (
		<Overlay
			additionalClasses="edit-view-overlay"
			title="Edit columns"
			searchInput={InputSearch.add(
				"search-columns",
				"Column name",
				"Enter column name",
				"No columns found.",
				visibleColumns.length,
				handleSearchInputChange,
			)}
		>
			<div className="modal__content">{groups}</div>

			<EditViewFooter
				allChecked={allChecked}
				columnsInput={newColumnsInput}
				namespace={namespace}
				onInvertClick={handleInvertClick}
				onSaveClick={handleSaveClick}
				onSelectAll={onSelectAll}
				onUseDefaultClick={handleDefaultClick}
			/>
		</Overlay>
	);
}

EditView.propTypes = {
	namespace: namespaceType.isRequired,
};
