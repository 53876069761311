import { useEffect } from "react";

export function useCloseOnClickOutside(
	ref,
	isOpened,
	onClose,
	closeOnClickOutside = true,
) {
	useEffect(() => {
		if (closeOnClickOutside) {
			function handleClickOutside(e) {
				if (
					ref.current &&
					!ref.current.contains(e.target) &&
					isOpened
				) {
					onClose();
				}
			}

			if (isOpened) {
				document.addEventListener("mousedown", handleClickOutside);
				return () => {
					document.removeEventListener(
						"mousedown",
						handleClickOutside,
					);
				};
			}
		}
	}, [isOpened]);
}
