export const SET_COLUMNS = "SET_COLUMNS";

export function setColumns(columns) {
	return {
		type: SET_COLUMNS,
		data: {
			columns,
		},
	};
}
