import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { getValidationResult } from "./selectors";
import { ValidationResult } from "./validationResult";

export default function useValidation(criterionKey) {
	const validationResult = useSelector(
		getValidationResult,
		isValidationEqual,
	);
	const criterionValidationResult = validationResult.get(criterionKey);

	const criterionValidation =
		criterionValidationResult || ValidationResult.success();
	const valid = criterionValidation.isSuccess();
	const message = criterionValidation.getMessage();
	const disabled = criterionValidation.isFieldDisabled();

	return { validationResult, valid, message, disabled };
}

const isValidationEqual = (immutableLeft, immutableRight) => {
	const left = immutableLeft.toJS();
	const right = immutableRight.toJS();

	if (Object.keys(left).length !== Object.keys(right).length) {
		return false;
	}

	return Object.entries(left).every(([property, leftValues]) => {
		const rightValues = right[property];
		return isEqual(leftValues, rightValues);
	});
};
