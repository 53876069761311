export const SET_VALIDATION_RESULT = "SET_VALIDATION_RESULT";
export const SET_VALIDATION_RULES = "SET_VALIDATION_RULES";

export function setValidationResult(validationResult) {
	return {
		type: SET_VALIDATION_RESULT,
		data: {
			validationResult,
		},
	};
}

export function setValidationRules(rules) {
	return {
		type: SET_VALIDATION_RULES,
		data: { rules },
	};
}
