import { OrderedMap } from "immutable";

class Footnotes {
	detectors = null;
	descriptions = null;

	footnotes = OrderedMap();
	index = 0;

	constructor(detectors, descriptions) {
		this.detectors = detectors;
		this.descriptions = descriptions;
	}

	marker(columnKey, value) {
		if (!value) {
			return null;
		}

		if (!this.detectors[columnKey]) {
			return null;
		}

		const footnote = this.detectors[columnKey](value);

		if (footnote === null) {
			return null;
		}

		if (this.footnotes.has(footnote)) {
			return this.footnotes.get(footnote).marker;
		}

		this.index++;
		const marker = this.index;

		this.footnotes = this.footnotes.set(footnote, {
			key: footnote,
			marker,
			description: this.descriptions[footnote],
		});

		return marker;
	}

	clearFootnotes() {
		this.footnotes = OrderedMap();
		this.index = 0;
	}

	hasFootnotes() {
		return this.index > 0;
	}

	toArray() {
		return this.footnotes.valueSeq().toArray();
	}
}

export default Footnotes;
