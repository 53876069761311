import PropTypes from "prop-types";
import React from "react";

export function ViewMode({
	onChange,
	mode,
	value,
	id,
	label,
	disabled,
	children,
}) {
	const isSelected = mode === value;

	const body =
		isSelected && children ? (
			<div className="additional-fields">{children}</div>
		) : null;

	return (
		<fieldset>
			<div className="radio-input">
				<input
					type="radio"
					id={id}
					name="search-save-search"
					value={mode}
					checked={isSelected}
					onChange={onChange}
					disabled={disabled}
				/>
				<label htmlFor={id}>{label}</label>
			</div>
			{body}
		</fieldset>
	);
}

ViewMode.propTypes = {
	onChange: PropTypes.func.isRequired,
	mode: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	children: PropTypes.node,
};
