import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

export function FormComponent(props) {
	const { id, name, label, required, errors, classes, children } = props;

	return (
		<fieldset
			className={clsx("form-group", classes, {
				error: errors[name],
				required,
			})}
		>
			<label htmlFor={id}>{label}</label>

			{errors[name] && errors[name].type === "required" && (
				<span className="error-text" role="alert">
					This field is required.
				</span>
			)}

			{errors[name] && errors[name].type === "pattern" && (
				<span className="error-text" role="alert">
					Please enter a valid email address.
				</span>
			)}

			{children}
		</fieldset>
	);
}

FormComponent.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool.isRequired,
	errors: PropTypes.object,
	classes: PropTypes.string,
	children: PropTypes.node.isRequired,
};
