export const ToastType = {
	SUCCESS: "success",
	ERROR: "error",
	INFO: "info",
};

export const ToastStatus = {
	ALERT: "alert",
	STATUS: "status",
};

class Toast {
	key = null;
	content = null;
	type = null;

	constructor(key, content, type) {
		this.key = key;
		this.content = content;
		this.type = type;

		switch (type) {
			case "success":
				this.role = ToastStatus.ALERT;
				this.title = "Success!";
				break;
			case "error": {
				this.role = ToastStatus.ALERT;
				this.title = "Error";
				break;
			}
			case "info": {
				this.role = ToastStatus.ALERT;
				this.title = "Info";
				break;
			}
			default:
				break;
		}
	}
}

export const newErrorToast = (key, content) => {
	return new Toast(key, content, ToastType.ERROR);
};

export const TOAST_FILL_REQUIRED_FIELDS = new Toast(
	"fillRequiredFields",
	"Please fill all the required fields on the page.",
	ToastType.ERROR,
);
export const TOAST_FILL_ONE_OF_REQUIRED_FIELDS = new Toast(
	"fillOneOfRequiredFields",
	"Please select at least one criteria.",
	ToastType.ERROR,
);
export const TOAST_QUICK_SEARCH_CRITERIA_UPDATED = new Toast(
	"quickSearchCriteriaSuccessfullyUpdated",
	"Quick search criteria are successfully saved.",
	ToastType.SUCCESS,
);
export const TOAST_QUICK_SEARCH_CRITERIA_DELETED = new Toast(
	"quickSearchCriteriaSuccessfullyDeleted",
	"Quick search criteria are successfully reset.",
	ToastType.SUCCESS,
);
export const TOAST_QUICK_SEARCH_DEFINE_FIVE = new Toast(
	"defineFiveFilters",
	"Please define 5 filters.",
	ToastType.ERROR,
);
export const TOAST_FAILED_RESULTS_FOR_PRINT = new Toast(
	"failedToFetchResultsForPrint",
	"Failed to fetch results for print. Please reload the page and try again.",
	ToastType.ERROR,
);
export const TOAST_FETCHING_ERROR = new Toast(
	"failedToFetchData",
	"An error occurred while fetching the data.",
	ToastType.ERROR,
);
export const TOAST_WRONG_SEARCH_QUERY = new Toast(
	"wrongSearchQuery",
	"Search query is not correct, please try again.",
	ToastType.ERROR,
);
