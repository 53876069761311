import PropTypes from "prop-types";
import React from "react";
import { footnotesType, resultRenderConfigType } from "../../../common/types";
import useSearchState from "../../useSearchState";
import { SearchResultsHeaderRow } from "./header/SearchResultsHeaderRow";
import { ResultsScrollbar } from "./partials/ResultsScrollbar";
import { SearchResultsFooterRow } from "./partials/SearchResultsFooterRow";
import { SearchResultsRow } from "./partials/SearchResultsRow";
import { SearchResultsSubheaderRow } from "./partials/SearchResultsSubheaderRow";
import usePrintSearchResult from "./usePrintSearchResult";

export function StandardSearchResultsTable({
	resultRenderConfig,
	footnotes,
	forPrint = false,
}) {
	const { rows, paging, total, columnsVisible } = useSearchState([""]);
	const { rowsForPrint } = usePrintSearchResult();

	const items = forPrint ? rowsForPrint : rows;

	if (!items || items.isEmpty()) {
		return null;
	}

	const {
		columnHeaderGenerator,
		columnCellGenerator,
		columnFooterGenerator,
		columnSubheaderGenerator,
	} = resultRenderConfig;
	footnotes && footnotes.clearFootnotes();

	return (
		<ResultsScrollbar forPrint={forPrint}>
			<table>
				<SearchResultsHeaderRow
					columnsVisible={columnsVisible}
					columnHeaderGenerator={columnHeaderGenerator}
				/>
				<tbody>
					<SearchResultsSubheaderRow
						total={total}
						columnsVisible={columnsVisible}
						columnSubheaderGenerator={columnSubheaderGenerator}
					/>
					{items.map((item, index) => (
						<SearchResultsRow
							key={index}
							item={item}
							columnsVisible={columnsVisible}
							footnotes={footnotes}
							columnCellGenerator={columnCellGenerator}
						/>
					))}
				</tbody>
				<SearchResultsFooterRow
					columnsVisible={columnsVisible}
					paging={paging}
					columnFooterGenerator={columnFooterGenerator}
				/>
			</table>
		</ResultsScrollbar>
	);
}

StandardSearchResultsTable.propTypes = {
	footnotes: footnotesType,
	forPrint: PropTypes.bool,
	resultRenderConfig: resultRenderConfigType.isRequired,
};
