import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { AlertLink } from "./AlertLink";

export function SearchItem({
	search,
	index,
	current,
	selected,
	error,
	searchAlertEnabled,
	handleSelect,
	handleDelete,
}) {
	const { id, name } = search;
	const isSelected = selected ? selected.id === search.id : index === 0;
	const isCurrentSearch = current ? current.id === id : false;
	const currentSearch = isCurrentSearch && (
		<span className="badge">Current Search</span>
	);

	return (
		<div className={clsx("radio-item", { error })}>
			<div className="radio-input">
				<input
					type="radio"
					id={`load-search-${id}`}
					name="load-search"
					value={name}
					checked={isSelected}
					onChange={() => handleSelect(search)}
				/>
				<label htmlFor={`load-search-${id}`}>
					{name} {currentSearch}
				</label>
			</div>

			{error && (
				<span className="error-text" role="alert">
					An error occurred: {error}.
				</span>
			)}

			{searchAlertEnabled && <AlertLink search={search} />}

			{!isCurrentSearch && (
				<button
					type="button"
					className="btn btn--secondary btn--icon btn--delete"
					onClick={() => handleDelete(id)}
					title="Delete search"
				>
					Delete search
				</button>
			)}
		</div>
	);
}

SearchItem.propTypes = {
	search: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	current: PropTypes.object,
	selected: PropTypes.object,
	error: PropTypes.string,
	searchAlertEnabled: PropTypes.bool.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
};
