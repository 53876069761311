import React from "react";
import {
	additionalSearchLinkType,
	contentCreditsType,
	disclaimersType,
	footnoteSingleType,
	footnotesType,
	pagingType,
	resultTableConfigType,
	warningsType,
} from "../../common/types";
import { DisclaimersContainer } from "../components/disclaimers/DisclaimersContainer";
import { FootnotesContainer } from "../components/footnotes/FootnotesContainer";
import { Warnings } from "../components/warnings/Warnings";
import { SearchResultsFactory } from "./table/SearchResultsFactory";

export function SearchResults({
	resultTableConfig,
	paging,
	additionalSearchLink,
	disclaimers,
	footnotes,
	warnings,
	footnoteSingle,
	contentCredits,
}) {
	return (
		<>
			<Warnings warnings={warnings} />
			<SearchResultsFactory
				footnotes={footnotes}
				paging={paging}
				resultTableConfig={resultTableConfig}
			/>
			{additionalSearchLink}
			{footnoteSingle}
			<DisclaimersContainer disclaimers={disclaimers} />
			{contentCredits}
			<FootnotesContainer footnotes={footnotes} />
		</>
	);
}

SearchResults.propTypes = {
	resultTableConfig: resultTableConfigType.isRequired,
	paging: pagingType.isRequired,
	additionalSearchLink: additionalSearchLinkType,
	footnoteSingle: footnoteSingleType,
	contentCredits: contentCreditsType,
	disclaimers: disclaimersType,
	footnotes: footnotesType,
	warnings: warningsType,
};
