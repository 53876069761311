import Immutable from "immutable";
import { SET_COLUMNS } from "./actions";

const initialState = Immutable.List();

export const columnsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_COLUMNS:
			return Immutable.List(action.data.columns);
		default:
			return state;
	}
};
