import PropTypes from "prop-types";
import React from "react";
import { FormComponent } from "./FormComponent";

export function EmailField({
	id,
	name,
	label,
	placeholder,
	required,
	errors,
	classes,
	register,
}) {
	const component = { id, name, label, required, errors, classes };
	const pattern =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	return (
		<FormComponent {...component}>
			<input
				type="email"
				id={id}
				placeholder={placeholder}
				{...register(name, { required, pattern })}
			/>
		</FormComponent>
	);
}

EmailField.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool.isRequired,
	errors: PropTypes.object,
	classes: PropTypes.string,
	register: PropTypes.func.isRequired,
};
