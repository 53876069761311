import { useCallback, useEffect } from "react";
import {
	debounceKeyTracker,
	findKeyElement,
	highlightKeyElement,
} from "../../../ui/trackDropdownKey";

let keyTracker = "";
let keyTimeout = null;

export default function useTrackDropdownKey(dropdownIsOpened, listRef) {
	const handleKeyboardPress = useCallback(
		(e) => {
			const nextSibling =
				document.activeElement.parentElement?.nextSibling;
			const choices = listRef?.current?.children;
			const key = e.key.toLowerCase();
			const keyIsRepeating = nextSibling?.innerText
				?.toLowerCase()
				.startsWith(key);
			keyTracker += key;
			let targetElement = keyIsRepeating
				? nextSibling
				: findKeyElement(choices, keyTracker || key, true);
			if (keyTracker && !targetElement) {
				targetElement = findKeyElement(choices, key, true);
			}
			highlightKeyElement(targetElement, true);
			if (keyTracker) {
				debounceKeyTracker();
			}
		},
		[listRef],
	);

	useEffect(() => {
		if (dropdownIsOpened) {
			window.addEventListener("keydown", handleKeyboardPress);
		} else {
			if (keyTimeout) {
				clearTimeout(keyTimeout);
			}
			keyTimeout = null;
			keyTracker = "";
			window.removeEventListener("keydown", handleKeyboardPress);
		}
	}, [dropdownIsOpened]);
}
