export const TOAST_ADD = "TOAST_ADD";
export const TOAST_DELETE = "TOAST_DELETE";
export const TOAST_CLEAR_ALL = "TOAST_CLEAR_ALL";

export function addToast(data) {
	return {
		type: TOAST_ADD,
		data,
	};
}

export function deleteToast(key) {
	return {
		type: TOAST_DELETE,
		data: { key },
	};
}

export function clearToasts() {
	return {
		type: TOAST_CLEAR_ALL,
	};
}
