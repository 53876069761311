import { namespaceType } from "../../common/types";
import useRequestResultsForPrint from "../result/useRequestResultsForPrint";

export const RequestResultsForPrintInitializer = ({ namespace }) => {
	useRequestResultsForPrint(namespace);

	return null;
};

RequestResultsForPrintInitializer.propTypes = {
	namespace: namespaceType.isRequired,
};
