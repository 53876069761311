import { useDispatch, useSelector } from "react-redux";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { addModal, deleteModal, resetModals } from "./actions";
import {
	MENU_CRITERION,
	MENU_DOWNLOADS_OPTIONS,
	MENU_FAVORITES_OPTIONS,
	MODAL_ADVANCED_SEARCH,
	MODAL_CUSTOMIZE_QUICK_SEARCH,
	MODAL_EDIT_VIEW,
} from "./constants";
import { getOpenedModals } from "./reducers";
import { removeOverlay, setOverlay } from "./setOverlay";

export default function useModal() {
	const dispatch = useDispatch();
	const openedModals = useSelector(getOpenedModals);
	const openedModalsExist = !openedModals.isEmpty();
	const { isMobile } = useAccessWindowSize();

	const onOpenModal = (key, isRelative = false) => {
		if (
			!isMobile &&
			(key.includes(MENU_CRITERION) ||
				key.includes(MENU_DOWNLOADS_OPTIONS) ||
				key.includes(MENU_FAVORITES_OPTIONS))
		) {
			return;
		}

		const overlays = [
			MODAL_ADVANCED_SEARCH,
			MODAL_EDIT_VIEW,
			MODAL_CUSTOMIZE_QUICK_SEARCH,
		];
		setOverlay(isRelative);

		if (overlays.includes(key) || !isMobile) {
			dispatch(resetModals());
		}

		dispatch(addModal(key));
	};

	const onCloseModal = (key) => {
		// If this is the only opened modal, remove overlay
		openedModals.size === 1 &&
			openedModals.includes(key) &&
			removeOverlay();
		dispatch(deleteModal(key));
	};

	const clearModals = () => {
		removeOverlay();
		dispatch(resetModals());
	};

	return {
		openedModals,
		openedModalsExist,
		onOpenModal,
		onCloseModal,
		clearModals,
	};
}
