export function getRoles(state) {
	return state.get("roles");
}

export function hasOneOfRoles(state, roles) {
	return roles.length > 0
		? roles.some((role) => state.getIn(["roles", role], false))
		: true;
}

export function hasRoles(state, roles) {
	return roles
		? roles.every((role) => state.getIn(["roles", role], false))
		: true;
}

export function getProvidedRoles(state) {
	return getRoles(state).filter((value) => value === true);
}
