export const SEARCH_GET_PREVIEW = "SEARCH_GET_PREVIEW";
export const SEARCH_PREVIEW = "SEARCH_PREVIEW";
export const SEARCH_GET_PREVIEW_FAILED = "SEARCH_GET_PREVIEW_FAILED";
export const SEARCH_PREVIEW_SET_BLOCKED = "SEARCH_PREVIEW_SET_BLOCKED";

export function requestPreview() {
	return {
		type: SEARCH_GET_PREVIEW,
		data: {},
	};
}

export function receiveRequestPreviewFailed(error) {
	return {
		type: SEARCH_GET_PREVIEW_FAILED,
		data: {
			error,
		},
	};
}

export function receivePreview(counts) {
	return {
		type: SEARCH_PREVIEW,
		data: {
			counts,
		},
	};
}

export function setPreviewBlocked(blocked) {
	return {
		type: SEARCH_PREVIEW_SET_BLOCKED,
		data: {
			blocked,
		},
	};
}
