import Immutable from "immutable";

class Disclaimers {
	detectors = null;
	descriptions = null;
	disclaimers = Immutable.Map();

	constructor(detectors, descriptions) {
		this.detectors = detectors;
		this.descriptions = descriptions;
	}

	setDisclaimers(detectors, input) {
		detectors.forEach((element) => {
			const keys = element.keys;
			const detector = element.detector;
			const disclaimer = detector(keys, input);
			if (disclaimer && !this.disclaimers.has(disclaimer)) {
				this.disclaimers = this.disclaimers.set(disclaimer, {
					key: disclaimer,
					description: this.descriptions[disclaimer],
				});
			}

			return null;
		});
	}

	checkInput(input) {
		if (input.size === 0 || !this.detectors.input) {
			return null;
		}

		this.setDisclaimers(this.detectors.input, input);

		return null;
	}

	checkColumns(columns) {
		if (columns.size === 0 || !this.detectors.columns) {
			return null;
		}

		this.setDisclaimers(this.detectors.columns, columns);

		return null;
	}

	checkResult(results) {
		if (results.size === 0 || !this.detectors.results) {
			return null;
		}

		const resultsDetectors = this.detectors.results;

		for (const [key, detector] of Object.entries(resultsDetectors)) {
			const disclaimer = detector(key, results);
			if (disclaimer && !this.disclaimers.has(disclaimer)) {
				this.disclaimers = this.disclaimers.set(disclaimer, {
					key: disclaimer,
					description: this.descriptions[disclaimer],
				});
			}
		}

		return null;
	}

	clearDisclaimers() {
		this.disclaimers = Immutable.Map();
	}

	hasDisclaimers() {
		return this.disclaimers.size > 0;
	}

	toArray() {
		return this.disclaimers.valueSeq().toArray();
	}
}

export default Disclaimers;
