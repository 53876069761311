import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { currentInputAutoSuggestType } from "../../../../common/types";

export function SelectedSuggestions({
	codes,
	currentInput,
	nameIndex,
	onInputChange,
}) {
	if (codes.isEmpty()) {
		return null;
	}

	const handleRemove = (choice) => {
		const index = codes.indexOf(choice);
		const updatedCodes = codes.delete(index);
		const updatedInput = currentInput.set("codes", updatedCodes);

		onInputChange(updatedInput);
	};

	const selection =
		codes &&
		codes.map((code) => {
			const label = nameIndex.get(code, code + "...");
			return (
				<li
					key={code}
					className="tag tag-removable criterion-selection"
				>
					<span>{label}</span>
					<button
						className="tag__remove"
						onClick={() => handleRemove(code)}
					>
						{"Remove " + label}
					</button>
				</li>
			);
		});

	return <ul className="criterion-selection-container">{selection}</ul>;
}

SelectedSuggestions.propTypes = {
	codes: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
	currentInput: currentInputAutoSuggestType.isRequired,
	nameIndex: ImmutablePropTypes.mapOf(PropTypes.string).isRequired,
	onInputChange: PropTypes.func.isRequired,
};
