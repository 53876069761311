import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateChoices } from "../../search/criteria/actions";

export default function useSetFetchedChoices(
	criterionKey,
	label,
	choices = [],
) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateChoices(criterionKey, { label, choices }));
	}, [criterionKey, label, choices]);
}
