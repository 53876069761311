import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { childrenType, searchInputType } from "../../types";
import { Logo } from "../Logo";
import { SearchInput } from "./SearchInput";
import useEscapeKey from "./useEscapeKey";
import useModal from "./useModal";

export function Overlay({
	additionalClasses,
	description,
	title,
	searchInput,
	children,
}) {
	const { openedModalsExist, clearModals } = useModal();
	useEscapeKey(clearModals);

	const classes = clsx("overlay", additionalClasses, {
		open: openedModalsExist,
	});

	return (
		<div role="dialog" aria-labelledby="modalTitle" className={classes}>
			<div className="modal__container">
				<header
					className={clsx("modal__header flex", {
						"modal__header--with-search": searchInput,
					})}
				>
					<Logo />
					<div className="modal__title">
						<h2 id="modalTitle">{title}</h2>
						{description && (
							<p className="modal__description">{description}</p>
						)}
					</div>

					<SearchInput searchInput={searchInput} />

					<button
						type="button"
						className="btn--close"
						onClick={clearModals}
						aria-label={`Close ${title}`}
					></button>
				</header>

				{children}
			</div>
		</div>
	);
}

Overlay.propTypes = {
	additionalClasses: PropTypes.string,
	children: childrenType.isRequired,
	description: PropTypes.string,
	searchInput: searchInputType,
	title: PropTypes.string.isRequired,
};
