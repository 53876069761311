import PropTypes from "prop-types";
import React from "react";
import { trim } from "../utils";

export function SearchSelected({ current, label }) {
	const searchName = current ? trim(current.name, 15) : "Unsaved";

	return (
		<div className="flex info--text">
			<p>{label}</p>
			<span>{searchName}</span>
		</div>
	);
}

SearchSelected.propTypes = {
	label: PropTypes.string.isRequired,
	current: PropTypes.object,
};
