import { defaultRenderConfig } from "./result/table/generateResultCells";
import { StandardSearchResultsTable } from "./result/table/StandardSearchResultsTable";
import { mapSearchCriteria, SearchType } from "./searchCriteriaMapper";

export function createDefaultSearchDefinition({
	columns = [],
	criteriaValidation = {},
	groupRequiredRoles = () => {},
	groupVisibilitySelector = () => {},
	mapSearchCriterion = () => {},
	resultRenderConfig = defaultRenderConfig,
	resultsDisplay = StandardSearchResultsTable,
	searchCriteria = {},
	shouldFetchQuickSearchCriteria = true,
	quickSearchFallbackCriteria = [],
	quickSearchCriteriaOrder = null,
}) {
	return {
		additionalAction: undefined,
		additionalLink: undefined,
		additionalSearch: undefined,
		advancedSearchAdditionalLink: undefined,
		actionsButtonDependsOn: undefined,
		contentCredits: undefined,
		criteria: {
			criteria: mapSearchCriteria(
				searchCriteria,
				SearchType.FULL_SEARCH,
				mapSearchCriterion,
				criteriaValidation,
				groupVisibilitySelector,
				groupRequiredRoles,
			),
			quickSearchFallbackCriteria,
			mapSearchCriterion,
			shouldFetchQuickSearchCriteria,
			quickSearchCriteriaOrder,
		},
		customizeQuickSearch: {
			enabled: true,
			radios: undefined,
		},
		disclaimers: undefined,
		download: {
			downloadDemoData: undefined,
			downloadEnabled: false,
			downloadRoles: undefined,
		},
		faq: undefined,
		footerPresets: undefined,
		footnotes: undefined,
		footnoteSingle: undefined,
		layoutSwitcher: undefined,
		namespace: undefined,
		notDeletable: undefined,
		pagingEnabled: true,
		resultTableConfig: {
			columns,
			resultRenderConfig,
			resultsDisplay,
		},
		resultRoles: null,
		searches: {
			searchAlertEnabled: false,
			searchesEnabled: true,
			viewBindingEnabled: true,
		},
		searchTitle: undefined,
		showResultsOnInitialPageLoad: false,
		tabSearch: null,
		views: {
			viewsEnabled: true,
			visibilitySelector: undefined,
		},
	};
}
