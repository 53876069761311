export class SearchGroup {
	name;
	criteria = {};
	visibilitySelector;
	requiredRoles;

	constructor(
		name,
		key,
		visibilitySelector = undefined,
		requiredRoles = undefined,
	) {
		this.name = name;
		this.key = key;
		this.visibilitySelector = visibilitySelector;
		this.requiredRoles = requiredRoles;
	}

	add(criterionKey, criterionFactory, criterionValidation) {
		this.criteria[criterionKey] = {
			factory: criterionFactory,
			validation: criterionValidation,
		};

		return this;
	}
}
