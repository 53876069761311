import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { footnotesType } from "../../../common/types";

export function FootnotesContainer({ footnotes, restricted }) {
	const classes = clsx("footnotes", { restricted });

	if (!footnotes || !footnotes.hasFootnotes()) {
		return null;
	}

	return (
		<div className={classes}>
			{footnotes.toArray().map((footnote) => {
				return (
					<p key={footnote.key}>
						<span className="footnotes__marker">
							<sup>{footnote.marker}</sup>
						</span>{" "}
						{footnote.description}
					</p>
				);
			})}
		</div>
	);
}

FootnotesContainer.propTypes = {
	footnotes: footnotesType,
	restricted: PropTypes.bool,
};
