import PropTypes from "prop-types";
import React from "react";
import {
	columnCellGeneratorType,
	columnsVisibleType,
	footnotesType,
} from "../../../../common/types";

export function SearchResultsRow({
	item,
	columnsVisible,
	footnotes,
	columnCellGenerator,
}) {
	return <tr>{columnCellGenerator(columnsVisible, item, footnotes)}</tr>;
}

SearchResultsRow.propTypes = {
	item: PropTypes.object.isRequired,
	columnsVisible: columnsVisibleType.isRequired,
	footnotes: footnotesType,
	columnCellGenerator: columnCellGeneratorType.isRequired,
};
