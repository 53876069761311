export const CELL_WRAP = "wrap";
export const CELL_RIGHT = "text-right";
export const CELL_CENTER = "text-center";
export const CELL_ICON = "action-buttons";
export const CELL_MONOSPACED = "monospaced";
export const CELL_ROWSPAN = "cell__rowspan";
export const CELL_MIN_WIDTH_25 = "w-min-25";
export const CELL_XSMALL = "cell__xsmall";
export const CELL_SMALL = "cell__small";
export const CELL_STANDARD = "cell__standard";
export const CELL_MEDIUM = "cell__medium";
export const CELL_LARGE = "cell__large";
