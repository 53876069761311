import useDebounceValue from "../useDebounceValue";
import useFetchChoices from "./useFetchChoices";

export default function useFetchSuggestions(
	endpoint,
	params,
	shouldFetch = true,
	debounceTime = undefined,
) {
	const { debouncedValue: debouncedParams, loading: debouncedLoading } =
		useDebounceValue(JSON.stringify(params), debounceTime);

	const debouncedParamsParsed = debouncedParams
		? JSON.parse(debouncedParams)
		: {};

	const inputIsNotEmpty =
		debouncedParamsParsed.codes?.length > 0 ||
		debouncedParamsParsed.query?.length > 0;

	const { choices, loading, error } = useFetchChoices(
		endpoint,
		debouncedParamsParsed,
		shouldFetch && inputIsNotEmpty,
	);

	return {
		choices,
		loading: loading || debouncedLoading,
		error,
	};
}
