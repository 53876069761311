import Immutable from "immutable";
import { CLEAR_SEARCH_DATA } from "../../common/hash/actions";
import {
	FORM_SEARCHES_SEARCH_CREATED,
	FORM_SEARCHES_SEARCH_UPDATED,
	FORM_SEARCHES_SELECT_SEARCH,
} from "../searches/actions";
import {
	SEARCH_DELETE_VIEW,
	SEARCH_DELETE_VIEW_FAILED,
	SEARCH_GET_VIEWS,
	SEARCH_GET_VIEWS_FAILED,
	SEARCH_SELECT_VIEW,
	SEARCH_SET_LOCAL_VIEW_CHECKED,
	SEARCH_SET_SHOULD_LOCAL_VIEW_INTERCEPT,
	SEARCH_VIEW_COLUMNS_CHANGED,
	SEARCH_VIEW_DELETED,
	SEARCH_VIEW_ORDER_CHANGED,
	SEARCH_VIEW_SAVED,
	SEARCH_VIEW_SELECTED,
	SEARCH_VIEW_TO_DELETE,
	SEARCH_VIEW_UPDATED,
	SEARCH_VIEWS,
} from "./actions";
import View from "./View";

const initialState = Immutable.Map({
	views: Immutable.OrderedMap(),
	loading: false,
	loaded: false,
	selectedViewId: undefined,
	shouldLoadResults: false,
	dirty: false,
	viewToDelete: undefined,
	shouldLocalViewInterceptSearchParameters: false,
	localViewChecked: false,
});

export function viewsReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_GET_VIEWS:
			return state.set("loading", true);
		case SEARCH_GET_VIEWS_FAILED:
			return state.set("loading", false);
		case SEARCH_VIEWS:
			return state.merge({
				views: Immutable.OrderedMap(
					action.data.views.map((s) => [s.id, new View(s)]),
				),
				loading: false,
				loaded: true,
			});
		case SEARCH_VIEW_SAVED:
		case SEARCH_VIEW_UPDATED:
			return state
				.setIn(
					["views", action.data.view.id],
					new View(action.data.view),
				)
				.merge({
					selectedViewId: action.data.view.id,
					dirty: false,
				});
		case SEARCH_SELECT_VIEW:
			return state.merge({
				selectedViewId: action.data.id,
				shouldLoadResults: action.data.shouldLoadResults,
				dirty: false,
			});
		case SEARCH_VIEW_SELECTED:
			return state.merge({
				shouldLoadResults: false,
			});
		case FORM_SEARCHES_SEARCH_CREATED:
			if (action.data.search.view) {
				const view = action.data.search.view;
				return state.setIn(["views", view.id], new View(view)).merge({
					selectedViewId: view.id,
					dirty: false,
				});
			} else if (action.data.search.viewId) {
				return state.merge({
					selectedViewId: action.data.search.viewId,
					dirty: false,
				});
			} else {
				return state;
			}
		case SEARCH_VIEW_TO_DELETE:
			return state.set("viewToDelete", action.data.id);
		case SEARCH_DELETE_VIEW:
			return state.merge({
				loading: true,
				viewToDelete: undefined,
			});
		case SEARCH_DELETE_VIEW_FAILED:
			return state.merge({
				loading: false,
				viewToDelete: undefined,
			});
		case SEARCH_VIEW_DELETED:
			return state.removeIn(["views", action.data.id]).merge({
				loading: false,
				viewToDelete: undefined,
			});
		case FORM_SEARCHES_SEARCH_UPDATED:
		case FORM_SEARCHES_SELECT_SEARCH:
			return state.merge({
				selectedViewId: action.data.search.viewId,
				dirty: false,
			});
		case SEARCH_VIEW_COLUMNS_CHANGED:
		case SEARCH_VIEW_ORDER_CHANGED:
			return state.set("dirty", true);
		case CLEAR_SEARCH_DATA:
			return state.set("selectedViewId", undefined);
		case SEARCH_SET_SHOULD_LOCAL_VIEW_INTERCEPT:
			return state.merge({
				shouldLocalViewInterceptSearchParameters:
					action.data.shouldIntercept,
			});
		case SEARCH_SET_LOCAL_VIEW_CHECKED:
			return state.set("localViewChecked", action.data.checked);
		default:
			return state;
	}
}
