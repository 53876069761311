import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useDropdown } from "../../components/dropdown/useDropdown";
import { dropdownItemsType } from "../../types";

export function DropdownField({
	name,
	dropdownToggle,
	dropdownItems,
	onDropdownItemClick,
	required,
	selected = false,
	error,
}) {
	const {
		dropdownRef,
		dropdownIsOpened,
		onDropdownToggle,
		onDropdownItemWasSelected,
	} = useDropdown();

	const handleDropdownItemClick = (key) => {
		onDropdownItemClick(key);
		onDropdownItemWasSelected();
		onDropdownToggle();
	};

	const items = dropdownItems.map((item) => {
		const { key, value } = item;
		return (
			<li key={key}>
				<button
					type="button"
					className="dropdown-item"
					onClick={() => handleDropdownItemClick(value)}
				>
					{value}
				</button>
			</li>
		);
	});

	const dropdownClasses = clsx("dropdown dropdown--labelled", {
		"dropdown--scrollable": dropdownItems.length > 7,
		error,
		required,
	});

	return (
		<div
			ref={dropdownRef}
			className={dropdownClasses}
			data-selected={selected}
		>
			<span className="dropdown-label" onClick={onDropdownToggle}>
				{name}
			</span>

			{error && error.type === "required" && (
				<span className="error-text" role="alert">
					This field is required.
				</span>
			)}

			<button
				type="button"
				className="dropdown-toggle"
				aria-expanded={dropdownIsOpened}
				onClick={onDropdownToggle}
			>
				<span>{dropdownToggle}</span>
			</button>

			<div
				className={`dropdown-menu ${dropdownIsOpened ? "dropdown-menu--opened" : ""}`}
			>
				<ul className="dropdown-menu-items">{items}</ul>
			</div>
		</div>
	);
}

DropdownField.propTypes = {
	name: PropTypes.string.isRequired,
	dropdownToggle: PropTypes.string.isRequired,
	dropdownItems: dropdownItemsType.isRequired,
	selected: PropTypes.bool,
	required: PropTypes.bool.isRequired,
	error: PropTypes.object,
	onDropdownItemClick: PropTypes.func.isRequired,
};
