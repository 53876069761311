import Immutable from "immutable";
import { SEARCH_GET_RESULT } from "../result/actions";
import {
	FAVORITES_ADD_ALL_RESULTS,
	FAVORITES_ADD_SELECTED_RESULTS,
	FAVORITES_CODES_CHANGED,
	FAVORITES_DELETE_ENTITIES,
	FAVORITES_DELETE_ENTITIES_FAILED,
	FAVORITES_ENTITIES_DELETED,
	FAVORITES_GET_LISTS,
	FAVORITES_GET_LISTS_FAILED,
	FAVORITES_LIST_ADDED,
	FAVORITES_LISTS,
	FAVORITES_LISTS_UPDATED,
	FAVORITES_MODAL_CONTENT,
	FAVORITES_UPDATE_LISTS,
	FAVORITES_UPDATE_LISTS_FAILED,
} from "./actions";

const initialState = Immutable.Map({
	favoriteLists: Immutable.List(),
	codes: Immutable.List(),
	modal: Immutable.Map(),
	addAllResults: false,
	isFetching: false,
	isFetched: false,
	fetchError: undefined,
	isUpdating: false,
	isDeleting: false,
});

export function favoritesReducer(state = initialState, action) {
	switch (action.type) {
		case FAVORITES_GET_LISTS:
			return state.set("isFetching", true);
		case FAVORITES_GET_LISTS_FAILED:
			return state.merge({
				isFetching: false,
				fetchError: action.data.error,
			});
		case FAVORITES_LISTS:
			return state.merge({
				favoriteLists: Immutable.List(action.data.favoriteLists),
				isFetching: false,
				isFetched: true,
			});
		case FAVORITES_CODES_CHANGED:
			return state.set("codes", action.data.codes);
		case FAVORITES_UPDATE_LISTS:
			return state.merge({
				isUpdating: true,
				updateError: undefined,
			});
		case FAVORITES_LISTS_UPDATED:
			return state
				.mergeIn(
					["favoriteLists"],
					Immutable.List(action.data.favoriteLists),
				)
				.merge({
					codes: Immutable.List(),
					addAllResults: false,
					isUpdating: false,
					updateError: undefined,
					isFetched: false,
				});
		case FAVORITES_LIST_ADDED:
			return state.set("isFetched", false);
		case FAVORITES_UPDATE_LISTS_FAILED:
			return state.merge({
				isUpdating: false,
				updateError: action.data.error,
			});
		case FAVORITES_MODAL_CONTENT:
			return state.set("modal", Immutable.Map(action.data.modal));
		case FAVORITES_ADD_ALL_RESULTS:
			return state.set("addAllResults", true);
		case FAVORITES_ADD_SELECTED_RESULTS:
			return state.set("addAllResults", false);
		case FAVORITES_DELETE_ENTITIES:
			return state.merge({
				isDeleting: true,
			});
		case FAVORITES_DELETE_ENTITIES_FAILED:
			return state.merge({
				isDeleting: false,
			});
		case FAVORITES_ENTITIES_DELETED:
			return state.merge({
				isFetched: false,
				isDeleting: false,
			});
		case SEARCH_GET_RESULT:
			return state.set("codes", Immutable.List());
		default:
			return state;
	}
}
