import { getFormValid } from "../criteria/validation/selectors";

export function getPreviewCounts(state) {
	return state.getIn(["search", "preview", "counts"]);
}

export function isPreviewValid(state) {
	return state.getIn(["search", "preview", "valid"]);
}

export function isLoadingPreview(state) {
	return state.getIn(["search", "preview", "loading"]);
}

export function hasErrorLoadingPreview(state) {
	return state.getIn(["search", "preview", "error"]);
}

export function isPreviewBlocked(state) {
	return state.getIn(["search", "preview", "blocked"]);
}

export function shouldLoadPreview(state) {
	return (
		!isPreviewValid(state) &&
		!isLoadingPreview(state) &&
		getFormValid(state) &&
		!isPreviewBlocked(state)
	);
}
