import { useDispatch, useSelector } from "react-redux";
import {
	displayToasts,
	errorToast,
} from "../../ui/common/toasts/displayToasts";
import useSearchState from "../useSearchState";
import { receiveViewUpdated } from "./actions";
import { updateView } from "./api";
import { getSelectedView, isViewDirty } from "./selectors";

export default function useSaveView(namespace) {
	const dispatch = useDispatch();
	const current = useSelector(getSelectedView);
	const viewDirty = useSelector(isViewDirty);
	const { resultExists } = useSearchState([""]);

	const onSaveView = (columns) => {
		if (!current || !resultExists) {
			return;
		}

		const successHandler = (view) => {
			dispatch(receiveViewUpdated(view));
		};

		const failureHandler = (error) => {
			displayToasts([errorToast(error.message)]);
		};

		updateView(
			current.id,
			current.name,
			namespace,
			columns,
			successHandler,
			failureHandler,
		);
	};

	return {
		current,
		viewDirty,
		onSaveView,
	};
}
