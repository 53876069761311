export const FORM_SEARCHES_CREATE_SEARCH = "FORM_SEARCHES_CREATE_SEARCH";
export const FORM_SEARCHES_CREATE_SEARCH_FAILED =
	"FORM_SEARCHES_CREATE_SEARCH_FAILED";
export const FORM_SEARCHES_SEARCH_CREATED = "FORM_SEARCHES_SEARCH_CREATED";
export const FORM_SEARCHES_GET_SEARCHES = "FORM_SEARCHES_GET_SEARCHES";
export const FORM_SEARCHES_GET_SEARCHES_FAILED =
	"FORM_SEARCHES_GET_SEARCHES_FAILED";
export const FORM_SEARCHES_SEARCHES = "FORM_SEARCHES_SEARCHES";
export const FORM_SEARCHES_SELECT_SEARCH = "FORM_SEARCHES_SELECT_SEARCH";
export const FORM_SEARCHES_SEARCH_UPDATED = "FORM_SEARCHES_SEARCH_UPDATED";
export const FORM_SEARCHES_SELECT_SEARCH_TO_DELETE =
	"FORM_SEARCHES_SELECT_SEARCH_TO_DELETE";
export const FORM_SEARCHES_DELETE_SEARCH = "FORM_SEARCHES_DELETE_SEARCH";
export const FORM_SEARCHES_DELETE_SEARCH_FAILED =
	"FORM_SEARCHES_DELETE_SEARCH_FAILED";
export const FORM_SEARCHES_SEARCH_DELETED = "FORM_SEARCHES_SEARCH_DELETED";

export function requestCreateSearch() {
	return {
		type: FORM_SEARCHES_CREATE_SEARCH,
		data: {},
	};
}

export function searchCreated(search) {
	return {
		type: FORM_SEARCHES_SEARCH_CREATED,
		data: {
			search,
		},
	};
}

export function createSearchFailed(error) {
	return {
		type: FORM_SEARCHES_CREATE_SEARCH_FAILED,
		data: {
			error,
		},
	};
}

export function requestSearches() {
	return {
		type: FORM_SEARCHES_GET_SEARCHES,
	};
}

export function requestSearchesFailed(error) {
	return {
		type: FORM_SEARCHES_GET_SEARCHES_FAILED,
		data: {
			error,
		},
	};
}

export function receiveSearches(searches) {
	return {
		type: FORM_SEARCHES_SEARCHES,
		data: {
			searches,
		},
	};
}

export function selectSearch(search) {
	return {
		type: FORM_SEARCHES_SELECT_SEARCH,
		data: {
			search,
		},
	};
}

export function searchUpdated(search) {
	return {
		type: FORM_SEARCHES_SEARCH_UPDATED,
		data: {
			search,
		},
	};
}

export function selectSearchToDelete(id) {
	return {
		type: FORM_SEARCHES_SELECT_SEARCH_TO_DELETE,
		data: {
			id,
		},
	};
}

export function requestDeleteSearch(id) {
	return {
		type: FORM_SEARCHES_DELETE_SEARCH,
		data: {
			id,
		},
	};
}

export function searchDeleted(id) {
	return {
		type: FORM_SEARCHES_SEARCH_DELETED,
		data: {
			id,
		},
	};
}

export function deleteSearchFailed(id, error) {
	return {
		type: FORM_SEARCHES_DELETE_SEARCH_FAILED,
		data: {
			id,
			error,
		},
	};
}
