import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../common/components/ConditionalWrapper";
import {
	MENU_TABS,
	MODAL_CUSTOMIZE_QUICK_SEARCH,
} from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import { faqType } from "../../common/types";
import { useAccessWindowSize } from "../../common/layout/useAccessWindowSize";
import { ActionsMenuButton } from "../actions/ActionsMenuButton";
import { AdvancedSearch } from "../advanced-search/AdvancedSearch";
import { PrintResultsButton } from "./PrintResultsButton";
import { SupportLink } from "./SupportLink";

export function SearchButtons({
	customizeQuickSearchEnabled,
	faq,
	onClearSearchClick,
}) {
	const { onOpenModal } = useModal();
	const { isMobile, isTablet } = useAccessWindowSize();
	const showMenu = isMobile || isTablet;

	const handleCustomizeQuickSearchClick = () => {
		onOpenModal(MODAL_CUSTOMIZE_QUICK_SEARCH);
	};

	return (
		<div className="btn-group">
			<ConditionalWrapper
				condition={showMenu}
				wrapper={(children) => (
					<ActionsMenu id={MENU_TABS}>{children}</ActionsMenu>
				)}
			>
				<SupportLink faq={faq} />

				<AdvancedSearch />

				{customizeQuickSearchEnabled && (
					<button
						type="button"
						className="btn btn--secondary btn--has-icon btn--customize"
						onClick={handleCustomizeQuickSearchClick}
					>
						<span>Customise quick search</span>
					</button>
				)}

				<PrintResultsButton />

				<button
					type="button"
					className="btn btn--secondary btn--clear-search"
					onClick={onClearSearchClick}
				>
					Clear search
				</button>
			</ConditionalWrapper>

			{showMenu && (
				<ActionsMenuButton onClick={() => onOpenModal(MENU_TABS)} />
			)}
		</div>
	);
}

SearchButtons.propTypes = {
	customizeQuickSearchEnabled: PropTypes.bool.isRequired,
	faq: faqType,
	onClearSearchClick: PropTypes.func.isRequired,
};
