import React, { useEffect, useState } from "react";
import usePrintSearchResult from "../result/table/usePrintSearchResult";
import useSearchState from "../useSearchState";

export function PrintResultsButton() {
	const {
		resultsForPrintAreLoaded,
		resultsForPrintRequested,
		onRequestResultsForPrint,
	} = usePrintSearchResult();
	const { resultsAreLoaded } = useSearchState([""]);
	const [print, setPrint] = useState(false);

	const buttonText = resultsForPrintRequested
		? "Preparing for print..."
		: "Print Results";

	useEffect(() => {
		if (print && resultsForPrintAreLoaded) {
			window.print();
			setPrint(false);
		}
	}, [print, resultsForPrintAreLoaded]);

	const handlePrintAllResults = (e) => {
		e.preventDefault();
		e.target.blur();

		const currentResultsOnPage =
			document.querySelector(".results-container");
		currentResultsOnPage?.classList?.add(
			"results-container-all-results-for-print",
		);

		onRequestResultsForPrint();
		setPrint(true);
	};

	if (!resultsAreLoaded) {
		return null;
	}

	return (
		<button
			type="button"
			className="btn btn--secondary btn--has-icon btn--print"
			onClick={handlePrintAllResults}
		>
			<span>{buttonText}</span>
		</button>
	);
}
