import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { childrenType } from "../../types";

export function ModalFooter({ classes, children }) {
	if (!children) {
		return null;
	}

	return (
		<footer className={clsx("flex modal__actions", classes)}>
			{children}
		</footer>
	);
}

ModalFooter.propTypes = {
	children: childrenType,
	classes: PropTypes.string,
};
