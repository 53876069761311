import React from "react";
import { warningsType } from "../../../common/types";

export function Warnings({ warnings }) {
	if (!warnings || warnings.isEmpty()) {
		return null;
	}

	return (
		<ul className="warnings disclaimers">
			{warnings.map((warning, index) => (
				<li key={index} className="disclaimer">
					{warning}
				</li>
			))}
		</ul>
	);
}

Warnings.propTypes = {
	warnings: warningsType,
};
