import PropTypes from "prop-types";
import React from "react";

export function Logo({ inverted = false }) {
	const fillColor = inverted ? "#fff" : "#2f2f2f";

	return (
		<a className="logo" href="/" aria-label="ch-aviation home page">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="217"
				height="50"
				viewBox="0 0 199 46"
			>
				<path fill="#C0272D" d="M0,46h45.6V0.3H0V46z" />
				<path
					fill="#ffffff"
					d="M37.3,39.1l1.5-0.8v1.6c-18.5,0-33.5-7.5-33.5-16.7c0-9.3,15-16.8,33.4-16.8V8l-1.5-0.9 c-14.8,0.5-26.6,7.5-26.6,16C10.6,31.7,22.4,38.7,37.3,39.1z M38.2,11.8c-1.6,0-3.2,0.1-4.7,0.3v10.3h-7.6V15l0.9-1.5 c-1.7,0.5-3.3,1.2-4.7,1.9v15.5c1.4,0.7,3,1.4,4.7,1.9l-0.9-1.5v-7.4h7.6v10.3c1.5,0.2,3.1,0.3,4.7,0.3L37.3,33V13.3L38.2,11.8z"
				/>
				<path
					id="logo-text"
					fill={fillColor}
					d="M163,11.8h-1.5v-1.5h1.5V11.8z M115.5,29.1l0.9,1.5h-2.1v-1.8c-1.2,1.2-3.6,2-5.6,2c-3.9,0-6.1-2-6.1-5.5 c0-3.2,2.2-5.4,6.4-5.3c1.7,0,3.5,0.3,5.1,1.3c-0.1-3.3-1.9-5.1-5.2-5.1c-2.3,0-3.9,0.4-5.1,1.4l-0.6-1c1.5-1.1,3.4-1.6,5.8-1.6 c4,0,6.3,2.4,6.3,6.6v7.5H115.5z M114.2,22.6c-1.4-1-3.5-1.3-5.1-1.3c-3.5,0-5.2,1.7-5.2,4.2c0,2.7,1.6,4.2,4.9,4.2 c1.7,0,4.2-0.8,5.4-1.9V22.6z M89.5,23.2L89.5,23.2c-0.1-5.2-1.8-8-6.8-8c-2,0-3.8,0.8-5.1,1.7v-5l0.9-1.5h-2.1v20.3h2.2l-0.9-1.5 V18c1.2-0.9,3.1-1.6,4.8-1.6c4.4,0,5.7,2.3,5.7,6.8v7.5h2.2l-0.9-1.5V23.2z M68.8,16.4c1.5,0,2.8,0.4,4,1.3l0.9-0.9 c-1.3-1.1-3.1-1.7-4.9-1.7c-4.3,0-7.9,3.5-7.9,7.9s3.5,7.9,7.9,7.9c1.8,0,3.5-0.6,4.9-1.7l-0.9-0.9c-1.1,0.9-2.4,1.3-4,1.3 c-3.7,0-6.6-2.9-6.6-6.6S65.1,16.4,68.8,16.4z M92.4,22.9h8.2v-1.2h-8.2V22.9z M181.6,23c0,4.4-3.5,7.9-7.9,7.9 c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9C178.1,15.2,181.6,18.7,181.6,23z M180.3,23c0-3.7-2.9-6.6-6.6-6.6s-6.6,2.9-6.6,6.6 s2.9,6.6,6.6,6.6C177.4,29.7,180.3,26.7,180.3,23z M163.6,15.4h-2.1v15.3h2.1l-0.9-1.5V16.9L163.6,15.4z M198.8,30.7l-0.9-1.5v-6 c0-5.2-1.7-8-6.7-8c-2,0-3.9,0.8-5.1,1.7v-1.5h-1.2v15.3h2.1l-0.9-1.5V18c1.2-0.9,3.1-1.6,4.8-1.6c4.4,0,5.7,2.3,5.7,6.8v7.5H198.8z M155.3,11.8h0.1l0.9-1.5h-2.2v5.1h-2.2v1.2h2.2v14.1h2.1l-0.9-1.5V16.6h4.1v-1.2h-4.1V11.8z M129.4,15.4l-5.7,13.8l-5-12.3l0.9-1.5 h-2.7l6.2,15.3h2.8l-0.9-1.5l5.6-13.8H129.4z M150.1,29.1l0.9,1.5h-2.1v-1.8c-1.2,1.2-3.6,2-5.6,2c-3.9,0-6.1-2-6.1-5.5 c0-3.2,2.2-5.4,6.4-5.3c1.7,0,3.5,0.3,5.1,1.3c-0.1-3.3-1.9-5.1-5.2-5.1c-2.3,0-3.9,0.4-5.1,1.4l-0.6-1c1.5-1.1,3.3-1.6,5.8-1.6 c4.1,0,6.3,2.4,6.3,6.6v7.5H150.1z M148.8,22.6c-1.4-1-3.5-1.3-5.1-1.3c-3.5,0-5.2,1.7-5.2,4.2c0,2.7,1.6,4.2,4.9,4.2 c1.7,0,4.2-0.8,5.4-1.9V22.6z M134.5,10.3H133v1.5h1.5V10.3z M135.2,15.4h-2.1v15.3h2.1l-0.9-1.5V16.9L135.2,15.4z"
				/>
			</svg>
		</a>
	);
}

Logo.propTypes = {
	inverted: PropTypes.bool,
};
