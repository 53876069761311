import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFetchChoices from "../../../../common/choices/useFetchChoices";
import useCurrentParentInputs from "../../../../common/forms/useCurrentParentInputs";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import useMatchInputToChoices from "../../../../common/forms/useMatchInputToChoices";
import useVisibilityState from "../../../../common/forms/useVisibilityState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { getChoicesInInput } from "../../choices/choices";
import { getPrefixedChoiceLabel } from "../../utils";
import { SearchCriterion } from "../common/SearchCriterion";
import { SetFetchedChoicesInitializer } from "../SetFetchedChoicesInitializer";
import CheckboxesDropdown from "./CheckboxesDropdown";

export function StandardChoiceCheckboxCriterion({
	choicesMapper = (choices) => choices,
	criterionKey,
	endpoint,
	label,
	parentKeys = [],
	parentKeysMap = null,
	prefix,
	quickSearch = false,
	requiredRoles,
	visibilitySelector,
	...props
}) {
	const visible = useVisibilityState(visibilitySelector);
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		Immutable.List(),
	);
	const parentInputs = useCurrentParentInputs(parentKeys, parentKeysMap);
	const { choices, loading } = useFetchChoices(endpoint, parentInputs);
	useMatchInputToChoices(currentInput, choices, onInputChange);

	return (
		<SearchCriterion
			label={label}
			prefix={prefix}
			quickSearch={quickSearch}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<SetFetchedChoicesInitializer
				criterionKey={criterionKey}
				criterionLabel={getPrefixedChoiceLabel(label, prefix)}
				criterionChoices={getChoicesInInput(
					choicesMapper(choices),
					currentInput,
					visible,
				)}
			/>
			<CheckboxesDropdown
				label={label}
				loading={loading}
				choices={choicesMapper(choices) || []}
				criterionKey={criterionKey}
				currentInput={currentInput}
				onInputChange={onInputChange}
				onClearInput={onClearInput}
				prefix={prefix}
				quickSearch={quickSearch}
				{...props}
			/>
		</SearchCriterion>
	);
}

StandardChoiceCheckboxCriterion.propTypes = {
	choicesMapper: PropTypes.func,
	criterionKey: PropTypes.string.isRequired,
	endpoint: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	parentKeys: PropTypes.array,
	parentKeysMap: PropTypes.objectOf(PropTypes.string.isRequired),
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool,
	requiredRoles: requiredRolesType,
	visibilitySelector: visibilitySelectorType,
};
