import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { POPOVER_RESTRICTED } from "./constants";
import useModal from "./useModal";

export function Popover({
	buttonContent,
	children,
	classes,
	id,
	toggleClasses,
	offset = [0, 0],
}) {
	const { openedModals, onCloseModal, onOpenModal } = useModal();
	const { isMobile } = useAccessWindowSize();

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const {
		styles,
		attributes,
		forceUpdate: updatePopper,
	} = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: "offset", options: { offset } }],
		placement: "bottom-start",
	});

	const show = () => {
		popperElement.setAttribute("data-show", "");

		// We need to tell Popper to update the tooltip position
		// after we show the tooltip, otherwise it will be incorrect
		updatePopper ? updatePopper() : null;
	};

	const hide = () => {
		popperElement.removeAttribute("data-show");
	};

	useEffect(() => {
		if (!isMobile) {
			document.documentElement.classList.remove(
				"backdrop--fixed--opened",
			);

			const showEvents = ["mouseenter", "focus"];
			const hideEvents = ["mouseleave", "blur"];

			showEvents.forEach((event) => {
				referenceElement &&
					referenceElement.parentElement.addEventListener(
						event,
						show,
					);
			});

			hideEvents.forEach((event) => {
				referenceElement &&
					referenceElement.parentElement.addEventListener(
						event,
						hide,
					);
			});

			return () => {
				showEvents.forEach((event) => {
					referenceElement &&
						referenceElement.parentElement.removeEventListener(
							event,
							show,
						);
				});

				hideEvents.forEach((event) => {
					referenceElement &&
						referenceElement.parentElement.removeEventListener(
							event,
							hide,
						);
				});
			};
		}
	}, [updatePopper]);

	const handleOpenPopover = () => {
		if (isMobile) {
			show();
			onOpenModal(POPOVER_RESTRICTED);
			document.documentElement.classList.add("backdrop--fixed--opened");
		}
	};

	const handleClosePopover = () => {
		if (isMobile) {
			hide();
			onCloseModal();
			document.documentElement.classList.remove(
				"backdrop--fixed--opened",
			);
		}
	};

	return (
		<div
			className={clsx("popover", classes)}
			aria-expanded={isMobile && openedModals.includes(id)}
		>
			<button
				type="button"
				className={clsx("btn", toggleClasses)}
				ref={setReferenceElement}
				onClick={handleOpenPopover}
			>
				{buttonContent}
			</button>

			<div
				className="popover-content"
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
			>
				<div className="content">
					<button
						type="button"
						className="btn btn--close"
						onClick={handleClosePopover}
					>
						Close
					</button>
					{children}
					<button
						type="button"
						className="btn btn--secondary btn--cancel"
						onClick={handleClosePopover}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

Popover.propTypes = {
	buttonContent: PropTypes.node,
	children: PropTypes.node.isRequired,
	classes: PropTypes.string,
	id: PropTypes.string.isRequired,
	toggleClasses: PropTypes.string,
	offset: PropTypes.arrayOf(PropTypes.number.isRequired),
};
