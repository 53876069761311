export const SEARCH_GET_VIEWS = "SEARCH_GET_VIEWS";
export const SEARCH_GET_VIEWS_FAILED = "SEARCH_GET_VIEWS_FAILED";
export const SEARCH_VIEWS = "SEARCH_VIEWS";
export const SEARCH_SAVE_VIEW = "SEARCH_SAVE_VIEW";
export const SEARCH_VIEW_SAVED = "SEARCH_VIEW_SAVED";
export const SEARCH_VIEW_UPDATED = "SEARCH_VIEW_UPDATED";
export const SEARCH_SELECT_VIEW = "SEARCH_SELECT_VIEW";
export const SEARCH_VIEW_SELECTED = "SEARCH_VIEW_SELECTED";
export const SEARCH_DELETE_VIEW = "SEARCH_DELETE_VIEW";
export const SEARCH_VIEW_TO_DELETE = "SEARCH_VIEW_TO_DELETE";
export const SEARCH_DELETE_VIEW_FAILED = "SEARCH_DELETE_VIEW_FAILED";
export const SEARCH_VIEW_DELETED = "SEARCH_VIEW_DELETED";
export const SEARCH_VIEW_COLUMNS_CHANGED = "SEARCH_VIEW_COLUMNS_CHANGED";
export const SEARCH_VIEW_ORDER_CHANGED = "SEARCH_VIEW_ORDER_CHANGED";
export const SEARCH_SET_SHOULD_LOCAL_VIEW_INTERCEPT =
	"SEARCH_SET_SHOULD_LOCAL_VIEW_INTERCEPT";
export const SEARCH_SET_LOCAL_VIEW_CHECKED = "SEARCH_SET_LOCAL_VIEW_CHECKED";

export function requestViews() {
	return {
		type: SEARCH_GET_VIEWS,
		data: {},
	};
}

export function receiveViewsFailed() {
	return {
		type: SEARCH_GET_VIEWS_FAILED,
		data: {},
	};
}

export function receiveViews(data) {
	return {
		type: SEARCH_VIEWS,
		data: {
			views: data,
		},
	};
}

export function receiveViewSaved(data) {
	return {
		type: SEARCH_VIEW_SAVED,
		data: {
			view: data,
		},
	};
}

export function receiveViewUpdated(data) {
	return {
		type: SEARCH_VIEW_UPDATED,
		data: {
			view: data,
		},
	};
}

export function selectView(id, shouldLoadResults = true) {
	return {
		type: SEARCH_SELECT_VIEW,
		data: {
			id,
			shouldLoadResults,
		},
	};
}

export function receiveViewSelected() {
	return {
		type: SEARCH_VIEW_SELECTED,
		data: {},
	};
}

export function requestDeleteView(id) {
	return {
		type: SEARCH_DELETE_VIEW,
		data: {
			id,
		},
	};
}

export function selectViewToDelete(id) {
	return {
		type: SEARCH_VIEW_TO_DELETE,
		data: {
			id,
		},
	};
}

export function receiveDeleteViewFailed(id) {
	return {
		type: SEARCH_DELETE_VIEW_FAILED,
		data: {
			id,
		},
	};
}

export function receiveViewDeleted(id) {
	return {
		type: SEARCH_VIEW_DELETED,
		data: {
			id,
		},
	};
}

export function setViewColumnsChanged() {
	return {
		type: SEARCH_VIEW_COLUMNS_CHANGED,
	};
}

export function setViewSortChanged() {
	return {
		type: SEARCH_VIEW_ORDER_CHANGED,
	};
}

export function setShouldLocalViewInterceptSearchParameters(shouldIntercept) {
	return {
		type: SEARCH_SET_SHOULD_LOCAL_VIEW_INTERCEPT,
		data: {
			shouldIntercept,
		},
	};
}

export function setLocalViewChecked(checked) {
	return {
		type: SEARCH_SET_LOCAL_VIEW_CHECKED,
		data: {
			checked,
		},
	};
}
