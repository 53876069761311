import { useDispatch, useSelector } from "react-redux";
import { requestPrintResults } from "../actions";
import {
	getPrintResults,
	printResultsLoaded,
	printResultsRequested,
} from "../selectors";

export default function usePrintSearchResult() {
	const dispatch = useDispatch();
	const resultsForPrintAreLoaded = useSelector(printResultsLoaded);
	const resultsForPrintRequested = useSelector(printResultsRequested);
	const results = useSelector(getPrintResults);

	const onRequestResultsForPrint = () => {
		dispatch(requestPrintResults());
	};

	return {
		resultsForPrintAreLoaded,
		resultsForPrintRequested,
		rowsForPrint: results?.get("items"),
		onRequestResultsForPrint,
	};
}
