import PropTypes from "prop-types";
import React from "react";
import { POPOVER_RESTRICTED } from "../../../../common/components/modals/constants";
import { Popover } from "../../../../common/components/modals/Popover";

export function SearchResultsRestrictedCell({ packageName }) {
	return (
		<div className="restricted-cell">
			<Popover
				classes="popover-grey"
				id={POPOVER_RESTRICTED}
				toggleClasses="btn--icon btn--restricted"
				offset={[-4, 0]}
			>
				<p>
					This information is only visible with a{" "}
					<span className="text-color-primary">
						{packageName} subscription.
					</span>
				</p>
				<a
					href="/account-management/subscription"
					className="btn btn--link"
				>
					Add to Subscription
				</a>
			</Popover>
		</div>
	);
}

SearchResultsRestrictedCell.propTypes = {
	packageName: PropTypes.string.isRequired,
};
