import useFetchData from "../api/useFetchData";

export default function useFetchUserData() {
	const { data: userData, loading } = useFetchData({
		endpoint: "/api/v1/me",
		immutable: false,
	});

	return {
		userData,
		loading,
	};
}
