import PropTypes from "prop-types";
import React from "react";

export function Toast({ type, role, title, content, onClose }) {
	return (
		<div className={`toast toast--${type}`} role={role}>
			<span className="toast__icon"></span>
			<h6 className="toast__title">{title}</h6>
			<p>{content}</p>
			<button
				type="button"
				className="toast__close-btn"
				onClick={onClose}
			></button>
		</div>
	);
}

Toast.propTypes = {
	type: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};
