import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { checkboxesType, notDeletableType } from "../../../common/types";
import { onRemoveChoice } from "./choices";

export function CriterionChoice({
	choiceKey,
	criterionKey,
	currentInput,
	hidden,
	notDeletable,
	onDelete,
	value,
}) {
	const deleteButton = notDeletable?.includes(criterionKey) ? null : (
		<button
			type="button"
			className="tag__remove"
			onClick={() => onRemoveChoice(choiceKey, currentInput, onDelete)}
		>
			{`Remove ${value}`}
		</button>
	);

	const classes = clsx("criterion-choice tag tag-grey", {
		"tag-removable": deleteButton,
		hidden,
	});

	return (
		<li className={classes}>
			<span>{value}</span>
			{deleteButton}
		</li>
	);
}

CriterionChoice.propTypes = {
	choiceKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	criterionKey: PropTypes.string.isRequired,
	currentInput: PropTypes.oneOfType([
		checkboxesType,
		ImmutablePropTypes.map,
		PropTypes.string,
	]),
	hidden: PropTypes.bool.isRequired,
	notDeletable: notDeletableType,
	onDelete: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};
