import queryString from "query-string";
import { useEffect } from "react";
import { TOAST_WRONG_SEARCH_QUERY } from "../../common/components/toasts/constants";
import useToasts from "../../common/components/toasts/useToasts";
import {
	clearHash,
	determineCurrentHash,
	isValidEncoding,
} from "../../common/hash/util";

export const useValidateUrl = () => {
	const hash = determineCurrentHash();
	const { onAddToast } = useToasts();

	useEffect(() => {
		if (hash) {
			const params = queryString.parse(hash).search;
			if (params && !isValidEncoding(params)) {
				clearHash();
				onAddToast(TOAST_WRONG_SEARCH_QUERY);
			}
		}
	}, []);
};
