import Immutable from "immutable";
import { TOAST_ADD, TOAST_CLEAR_ALL, TOAST_DELETE } from "./actions";

const initialState = Immutable.Map();

export default function (state = initialState, action) {
	switch (action.type) {
		case TOAST_ADD:
			return state.set(action.data.key, action.data);
		case TOAST_DELETE:
			return state.delete(action.data.key);
		case TOAST_CLEAR_ALL:
			return initialState;
		default:
			return state;
	}
}
