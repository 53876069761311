import PropTypes from "prop-types";
import React from "react";

export default function SimpleCheckbox({
	checked,
	disabled,
	onToggle,
	itemKey,
	inputId,
	value,
}) {
	return (
		<div className="checkbox-input">
			<input
				type="checkbox"
				id={inputId}
				value={itemKey}
				checked={checked}
				disabled={disabled}
				onChange={() => onToggle(itemKey, value)}
			/>
			<label htmlFor={inputId}>{value}</label>
		</div>
	);
}

SimpleCheckbox.propTypes = {
	itemKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.array,
	]).isRequired,
	value: PropTypes.string,
	checked: PropTypes.bool,
	onToggle: PropTypes.func.isRequired,
	inputId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};
