import { useCallback, useEffect } from "react";

export function useScrollToSelected(dropdownIsOpened, scrollToView, listRef) {
	const scrollToSelected = useCallback(() => {
		const dropdown = listRef?.current;
		const dropdownItems = [...dropdown.children];
		const activeItem = dropdown.querySelector(".active")?.parentNode;

		const index = dropdownItems.indexOf(activeItem);
		let lastPos = 0;
		dropdownItems.forEach(() => lastPos++);

		const dropdownHeight = dropdown.clientHeight;
		const dropdownScrollHeight = dropdown.scrollHeight;
		const currScrollPos =
			(index / lastPos) * parseInt(dropdownScrollHeight) -
			(dropdownHeight / 2 - activeItem?.offsetHeight / 2);
		dropdown.scrollTo(0, currScrollPos);
	}, [listRef]);

	useEffect(() => {
		if (scrollToView && dropdownIsOpened) {
			scrollToSelected();
		}
	}, [dropdownIsOpened]);
}
