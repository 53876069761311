import PropTypes from "prop-types";
import React from "react";
import { checkboxesType } from "../../../../common/types";
import CriterionSelectAll from "../common/CriterionSelectAll";
import useSelectAll from "../useSelectAll";
import SimpleCheckbox from "./SimpleCheckbox";

export default function Checkboxes({
	label,
	choices,
	criterionKey,
	currentInput,
	onChangeClientState = () => {},
	onInputChange,
	quickSearch,
	scrollable,
}) {
	const { allChecked, onSelectAll } = useSelectAll(
		choices,
		currentInput,
		onInputChange,
	);
	const quickSearchId = quickSearch ? "_quick_search" : "";

	const handleToggle = (key) => {
		let newInput;
		let index = currentInput.indexOf(key);

		if (currentInput.includes(key)) {
			newInput = currentInput.delete(index);
		} else {
			newInput = currentInput.push(key);
		}

		const clientStateInput = !newInput.isEmpty() ? newInput.toJS() : null;
		onChangeClientState(clientStateInput);

		onInputChange(newInput);
	};

	const checkboxes = choices.map((choice) => {
		const { key, value } = choice;
		return (
			<SimpleCheckbox
				key={key}
				itemKey={key}
				value={value}
				checked={currentInput.includes(key)}
				onToggle={handleToggle}
				inputId={`${criterionKey}_${key}${quickSearchId}`}
			/>
		);
	});

	return (
		<fieldset className={`dropdown-menu-items`}>
			<legend>{label}</legend>

			<CriterionSelectAll
				quickSearchId={quickSearchId}
				criterionKey={criterionKey}
				isVisible={scrollable}
				allChecked={allChecked}
				onSelectAll={onSelectAll}
			/>

			{checkboxes}
		</fieldset>
	);
}

Checkboxes.propTypes = {
	label: PropTypes.string.isRequired,
	choices: PropTypes.array.isRequired,
	criterionKey: PropTypes.string.isRequired,
	currentInput: checkboxesType.isRequired,
	onInputChange: PropTypes.func.isRequired,
	quickSearch: PropTypes.bool,
	scrollable: PropTypes.bool.isRequired,
	onChangeClientState: PropTypes.func,
};
