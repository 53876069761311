import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import { requiredRolesType } from "../../../../common/types";
import { SearchCriterion } from "../common/SearchCriterion";
import { ForecastComponent } from "./ForecastComponent";

export function ForecastCriterion({
	criterionKey,
	label,
	prefix,
	quickSearch = false,
	requiredRoles,
	...props
}) {
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		Immutable.Map(),
	);

	return (
		<SearchCriterion
			label={label}
			requiredRoles={requiredRoles}
			quickSearch={quickSearch}
		>
			<ForecastComponent
				label={label}
				criterionKey={criterionKey}
				currentInput={currentInput}
				onInputChange={onInputChange}
				onClearInput={onClearInput}
				prefix={prefix}
				quickSearch={quickSearch}
				{...props}
			/>
		</SearchCriterion>
	);
}

ForecastCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool,
	requiredRoles: requiredRolesType,
};
