import jquery from "jquery";
import { onFailure } from "./api";

export function sendForm(data, endpoint, successHandler, failureHandler) {
	jquery
		.ajax({
			type: "POST",
			url: endpoint,
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
		})
		.done(successHandler)
		.fail(onFailure(failureHandler, "Failed to send form."));
}
