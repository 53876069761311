import { useEffect } from "react";
import useFetchData from "../../../../common/api/useFetchData";

export function useLoadForecastingData(
	endpoint,
	dropdownIsOpened,
	currentInput,
	onInputChange,
) {
	const { data, loading, error } = useFetchData({
		endpoint,
		shouldFetch: dropdownIsOpened,
	});

	useEffect(() => {
		if (data && dropdownIsOpened && currentInput.isEmpty()) {
			const newInput = currentInput.merge({
				from_year: data.start.year,
				from_month: data.start.month,
				to_year: data.end.year,
				to_month: data.end.month,
				type: "hours",
				amount: "",
			});

			onInputChange(newInput);
		}
	}, [data, dropdownIsOpened, currentInput]);

	return { data, loading, error };
}
