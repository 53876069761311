import PropTypes from "prop-types";
import React from "react";
import useFavorites from "../useFavorites";

export function FavoritesSelectCell({ code, name }) {
	const { selectedCodes, onInputChange } = useFavorites();

	const handleToggle = (key) => {
		const index = selectedCodes.indexOf(key);
		const newInput = selectedCodes.includes(key)
			? selectedCodes.delete(index)
			: selectedCodes.push(key);

		onInputChange(newInput);
	};

	return (
		<td className="select-all">
			<div className="checkbox-input">
				<input
					type="checkbox"
					id={code}
					aria-label={"Select " + name}
					checked={selectedCodes.includes(code)}
					onChange={() => handleToggle(code)}
				/>
			</div>
		</td>
	);
}

FavoritesSelectCell.propTypes = {
	code: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};
