import PropTypes from "prop-types";
import React from "react";

export function AlertLink({ search }) {
	const createAlertLink = (
		<a
			href={"account-management/alerts/saved-search/" + search.id}
			className="btn btn--secondary btn--icon btn--create-alert"
			title="Create alert"
		>
			Create alert
		</a>
	);

	const editAlertLink = (
		<a
			href={"account-management/alerts/" + search.alertId}
			className="btn btn--secondary btn--icon btn--edit"
			title="Edit alert"
		>
			Edit alert
		</a>
	);

	return search.alertId ? editAlertLink : createAlertLink;
}

AlertLink.propTypes = {
	search: PropTypes.object.isRequired,
};
