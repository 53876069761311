import Immutable from "immutable";
import useSearchState from "../../search/useSearchState";

export default function useCurrentParentInputs(parentKeys, parentKeysMap) {
	const parentKeysPaths =
		parentKeys.length === 0 ? [""] : parentKeys.map((key) => [key]);
	const { currentInput } = useSearchState(parentKeysPaths);

	const inputs = parentKeys.reduce((acc, curr) => {
		const currentFieldInput = currentInput.get(curr, Immutable.List());
		return { ...acc, [curr]: currentFieldInput?.toJS() || [] };
	}, {});

	// FIXME: This is a temp fix for issue CHA-6606
	//  once backend is adjusted, remove mapParentInputs and parentKeysMap here and in search component definitions
	if (!parentKeysMap) {
		return inputs;
	}

	const newInputs = JSON.parse(JSON.stringify(inputs));

	for (const [oldKey, newKey] of Object.entries(parentKeysMap)) {
		if (oldKey in newInputs) {
			newInputs[newKey] = newInputs[oldKey];
			delete newInputs[oldKey];
		}
	}

	return newInputs;
}
