import PropTypes from "prop-types";
import React from "react";

export function Switch({ id, name, label, onChange, disabled, reverse }) {
	return (
		<div className={`switch-input ${reverse ? "reverse-order" : ""}`}>
			<input
				id={id}
				type="checkbox"
				name={name}
				onChange={onChange}
				disabled={!!disabled}
			/>
			<label htmlFor={id}>{label}</label>
		</div>
	);
}

Switch.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	reverse: PropTypes.bool,
};
