import Immutable, { fromJS } from "immutable";
import {
	FORM_SEARCHES_SEARCH_CREATED,
	FORM_SEARCHES_SELECT_SEARCH,
} from "../../search/searches/actions";
import {
	CLEAR_SEARCH_DATA,
	RESET_SEARCH_DATA,
	UPDATE_SEARCH_INPUT,
	UPDATE_SEARCH_PARAMETERS,
} from "./actions";
import { encodeHash } from "./util";

export function createHashReducers(initialState) {
	return function (state = initialState, action) {
		switch (action.type) {
			case UPDATE_SEARCH_INPUT: {
				return state
					.set("input", action.data.input)
					.mergeIn(["parameters"], {
						salt: Math.random(),
					});
			}
			case FORM_SEARCHES_SEARCH_CREATED:
			case FORM_SEARCHES_SELECT_SEARCH:
				return state
					.set("input", fromJS(action.data.search.input))
					.mergeIn(["parameters"], {
						selectedSearchId: action.data.search.id,
						salt: Math.random(),
					});
			case UPDATE_SEARCH_PARAMETERS: {
				const parameters = Object.assign({}, action.data.parameters, {
					salt: Math.random(),
				});
				return state.mergeIn(["parameters"], parameters);
			}
			case RESET_SEARCH_DATA:
				return action.data ?? Immutable.Map();
			case CLEAR_SEARCH_DATA:
				return Immutable.Map();
			default:
				return state;
		}
	};
}

export function hasHashData(state) {
	const data = getCurrentHashData(state);
	return data.has("parameters") || data.has("input");
}

export function getCurrentHashData(state) {
	return state.get("hash");
}

export function getCurrentHash(state) {
	const hash = getCurrentHashData(state);
	return encodeHash(hash);
}
