import * as Sentry from "@sentry/browser";
import jquery from "jquery";

export function initSentryConfig() {
	let environment = "development";

	switch (process.env.NODE_ENV) {
		case "production": {
			environment = "production";
			break;
		}
	}

	const getUserId = () => {
		let id;

		jquery
			.ajax({
				type: "GET",
				url: `/api/v1/me`,
				dataType: "json",
				async: false,
			})
			.done((data) => (id = data.uuid))
			.fail(() => (id = "None"));

		return id;
	};

	const errorMessagesToIgnore = [
		/Event `ErrorEvent` captured as exception with message `Script error.`/,
		/Network request failed/,
		/AbortError/,
		/Failed to execute 'send' on 'XMLHttpRequest': The object's state must be OPENED./,
		/script is not defined/,
	];

	Sentry.init({
		dsn: "https://bbe007c838c1a0bd8f7e0f4abd430d2a@o4506705950146560.ingest.us.sentry.io/4506705969545216",
		environment,
		initialScope: {
			user: { id: getUserId() },
		},
		// for local debugging uncomment localhost and disable browser ad blockers
		allowUrls: [
			/https?:\/\/((www|staging)\.)?ch-aviation\.(dev|com)/,
			// /http:\/\/localhost:8000/,
		],
		denyUrls: [
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
		],
		ignoreErrors: [
			"Event `ErrorEvent` captured as exception with message `Script error.`",
			/Event `ErrorEvent` captured as exception with message `Script error.`/,
			"Network request failed",
			"AbortError",
			"Failed to execute 'send' on 'XMLHttpRequest': The object's state must be OPENED.",
			/^Non-Error promise rejection captured with value:/,
			"script is not defined",
		],
		beforeSend(event, hint) {
			const error = hint.originalException;
			if (
				error === "Timeout" ||
				(error &&
					errorMessagesToIgnore.some((message) =>
						new RegExp(message).test(error),
					))
			) {
				return null;
			}
			return event;
		},
	});
}

export function reportErrorToSentry(error) {
	Sentry.captureException(error);
}
