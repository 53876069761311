export class RequiredRoles {
	openForRoles;
	showForRoles;

	constructor(openForRoles, showForRoles) {
		this.openForRoles = openForRoles;
		this.showForRoles = showForRoles;
	}

	static openFor = (roles) => {
		return new RequiredRoles(roles);
	};

	static showFor = (roles) => {
		return new RequiredRoles(undefined, roles);
	};

	static add = (openForRoles, showForRoles) => {
		return new RequiredRoles(openForRoles, showForRoles);
	};

	getOpenForRoles() {
		return this.openForRoles;
	}

	getShowForRoles() {
		return this.showForRoles;
	}
}
