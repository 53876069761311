export const DELETE_CRITERION_CHOICES = "DELETE_CRITERION_CHOICES";
export const SET_CRITERIA_YEARS = "SET_CRITERIA_YEARS";
export const UPDATE_CRITERIA_CHOICES = "UPDATE_CRITERIA_CHOICES";

export function deleteCriterionChoices(criterionKey) {
	return {
		type: DELETE_CRITERION_CHOICES,
		data: {
			criterionKey,
		},
	};
}

export function updateChoices(criterionKey, choices) {
	return {
		type: UPDATE_CRITERIA_CHOICES,
		data: {
			criterionKey,
			choices,
		},
	};
}

export function setYears(data) {
	return {
		type: SET_CRITERIA_YEARS,
		data,
	};
}

export const clearObsoleteChoices = () => {
	return (dispatch, getState) => {
		const state = getState();
		const currentInput = state.get("input");
		const selectedChoices = state.getIn(["search", "criteria", "choices"]);

		selectedChoices.mapKeys((criterionKey) => {
			if (!currentInput.has(criterionKey)) {
				dispatch(deleteCriterionChoices(criterionKey));
			}
		});
	};
};
