import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../common/components/ConditionalWrapper";
import { MENU_ACTIONS } from "../../common/components/modals/constants";
import { useAccessWindowSize } from "../../common/layout/useAccessWindowSize";
import {
	additionalActionsType,
	additionalSearchType,
	criteriaElementsType,
	downloadType,
	layoutSwitcherType,
	namespaceType,
	viewsType,
} from "../../common/types";
import { RequiredRoles } from "../criteria/components/common/requiredRoles";
import { Download } from "../download/Download";
import { Searches } from "../searches/Searches";
import { ViewsActions } from "./ViewsActions";

export function SearchActions({
	namespace,
	resultsPreview,
	resultsAreLoaded,
	searchesEnabled,
	download,
	views,
	additionalAction,
	additionalSearch,
	layoutSwitcher,
}) {
	const { downloadEnabled, downloadRoles } = download;
	const { isMobile } = useAccessWindowSize();
	const showMenu = !additionalSearch && isMobile;

	const getAdditionalActionAndDownloads = () => {
		if (!resultsAreLoaded || (!additionalAction && !downloadEnabled)) {
			return null;
		}

		return (
			<div className="actions__action">
				<div className="btn-group-divided">
					{additionalAction}
					{resultsAreLoaded && downloadEnabled && (
						<Download
							namespace={namespace}
							downloadRoles={RequiredRoles.showFor(downloadRoles)}
						/>
					)}
				</div>
			</div>
		);
	};

	return (
		<ConditionalWrapper
			condition={showMenu}
			wrapper={(children) => (
				<ActionsMenu id={MENU_ACTIONS}>{children}</ActionsMenu>
			)}
		>
			<div className="search-actions">
				{searchesEnabled && (
					<div className="actions__action">
						<Searches
							namespace={namespace}
							label={"Current search"}
						/>

						<div className="flex info--text info--results">
							<p>Data search</p>
							{resultsPreview}
						</div>
					</div>
				)}

				{getAdditionalActionAndDownloads()}

				<ViewsActions
					namespace={namespace}
					resultsAreLoaded={resultsAreLoaded}
					views={views}
				/>

				{layoutSwitcher}
				{additionalSearch}
			</div>
		</ConditionalWrapper>
	);
}

SearchActions.propTypes = {
	additionalAction: additionalActionsType,
	additionalSearch: additionalSearchType,
	criteria: criteriaElementsType,
	download: downloadType.isRequired,
	layoutSwitcher: layoutSwitcherType,
	namespace: namespaceType.isRequired,
	resultsAreLoaded: PropTypes.bool.isRequired,
	resultsPreview: PropTypes.node.isRequired,
	searchesEnabled: PropTypes.bool.isRequired,
	views: viewsType.isRequired,
};
