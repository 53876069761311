import moment from "moment";
import { ValidationResult } from "../../validation/validationResult";

export const utilisationSearchRangeValidation = (currentInput, key) => {
	const input = currentInput.get(key);
	const inputExists = input && !input.isEmpty();

	if (!inputExists) {
		return ValidationResult.success();
	}

	return dateRangeCheck(input);
};

export const dateRangeCheck = (input) => {
	const toYear = parseInt(input.get("to_year"));
	const toMonth = parseInt(input.get("to_month"));
	const fromYear = parseInt(input.get("from_year"));
	const fromMonth = parseInt(input.get("from_month"));

	const from = moment(`${fromYear}-${fromMonth}`, "YYYY-MM");
	const to = moment(`${toYear}-${toMonth}`, "YYYY-MM");
	const yearDiff = Math.abs(to.diff(from, "years"));

	if (from.isAfter(to) || yearDiff > 3) {
		return ValidationResult.error("Please select a valid date range.");
	}

	return ValidationResult.success();
};
