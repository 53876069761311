export function onFailure(
	failureHandler,
	defaultMessage = "An unexpected error occurred.",
) {
	return (jqXHR) => {
		const error =
			jqXHR.responseJSON && jqXHR.responseJSON.error
				? jqXHR.responseJSON.error
				: {
						code: "unexpected_error",
						message: defaultMessage,
					};
		failureHandler(error);
	};
}
