import Immutable, { fromJS } from "immutable";
import { SEARCH_RESULT } from "../../search/result/actions";
import { FORM_SEARCHES_SELECT_SEARCH } from "../../search/searches/actions";
import {
	CLEAR_INPUT,
	INPUT_CHANGED,
	INPUT_FIELD_CHANGED,
	SET_DEFAULT_INPUT,
} from "./actions";

export function createInputReducer(initialState, defaultInput) {
	const defaultState = defaultInput
		? Immutable.Map(defaultInput)
		: Immutable.Map();

	return function (state = initialState, action) {
		switch (action.type) {
			case INPUT_CHANGED:
				return Immutable.Map(action.data.input);
			case INPUT_FIELD_CHANGED:
				return state.set(action.data.criterionKey, action.data.input);
			case FORM_SEARCHES_SELECT_SEARCH:
				return fromJS(action.data.search.input);
			case SET_DEFAULT_INPUT:
				return defaultState.merge(action.data.input);
			case CLEAR_INPUT:
				return defaultState;
			case SEARCH_RESULT:
				return fromJS(
					restructureInputFromSearchResult(
						action.data.result.input,
						state.toJS(),
					),
				);
			default:
				return state;
		}
	};
}

const restructureInputFromSearchResult = (inputFromRequest, currentState) => {
	let finalState = { ...currentState };
	const criteriaWithCodes = Object.keys(inputFromRequest || {}).filter(
		(key) => new RegExp("\\w*_codes\\b").test(key),
	);

	for (const criterion of criteriaWithCodes) {
		const criterionKey = criterion.substring(0, criterion.length - 6);

		// For Operators and Routes/Schedules airports
		const criteriaToClearFreeInputFor = [
			"acmi_customer",
			"airline",
			"airline_display_name",
			"carrier",
			"customer",
			"delivery_customer",
			"event_location_airport",
			"flight_destination_airport",
			"flight_origin_airport",
			"future_operator",
			"lessor",
			"operator",
			"order_entity",
			"origin_airport",
			"destination_airport",
			"origin_airport",
			"destination_airport",
		];

		if (!currentState[criterionKey]?.free_text) {
			continue;
		}

		if (criteriaToClearFreeInputFor.includes(criterionKey)) {
			finalState[criterionKey]["codes"] = Array.from(
				new Set([
					...(finalState[criterionKey]["codes"] || []),
					...inputFromRequest[criterion],
				]),
			);

			if (finalState[criterionKey]?.free_text) {
				finalState[criterionKey].free_text = "";
			}
		}
	}

	return finalState;
};
