import { criteriaGroupsType } from "../../common/types";
import useValidate from "../criteria/validation/useValidate";

export const ValidationInitializer = ({ criteria }) => {
	useValidate(criteria);

	return null;
};

ValidationInitializer.propTypes = {
	criteria: criteriaGroupsType.isRequired,
};
