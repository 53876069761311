import PropTypes from "prop-types";
import React from "react";
import { useFocus } from "../../common/forms/useFocus";
import { CriterionLabel } from "./components/common/CriterionLabel";

export function TextInput({
	autoFocus,
	disabled,
	error,
	label,
	id,
	name,
	onFocus,
	onChange,
	onKeyDown,
	placeholder,
	prefix,
	value,
	onBlur,
}) {
	const { ref } = useFocus(autoFocus);

	return (
		<>
			<label htmlFor={id}>
				{<CriterionLabel label={label} prefix={prefix} />}
			</label>
			{error && <span className="error-text">{error}</span>}
			<input
				type="text"
				ref={ref}
				id={id}
				name={name}
				value={value}
				placeholder={placeholder}
				autoComplete="off"
				disabled={disabled}
				onFocus={onFocus}
				onChange={onChange}
				onKeyDown={onKeyDown}
				data-criterion={true}
				onBlur={onBlur}
			/>
		</>
	);
}

TextInput.propTypes = {
	autoFocus: PropTypes.bool,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.string,
	prefix: PropTypes.string,
	value: PropTypes.string,
	onBlur: PropTypes.func,
};
