import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../../common/components/toasts/actions";
import {
	TOAST_QUICK_SEARCH_CRITERIA_DELETED,
	TOAST_QUICK_SEARCH_CRITERIA_UPDATED,
} from "../../../../common/components/toasts/constants";
import { inputChanged } from "../../../../common/input/actions";
import {
	deleteQuickSearchCriteriaFailed,
	quickSearchCriteriaAreDeleted,
	quickSearchCriteriaUpdated,
	requestDeleteQuickSearchCriteria,
	requestUpdateQuickSearchCriteria,
	setQuickSearchCriteriaInput,
	setQuickSearchSelectedCriteria,
	updateQuickSearchCriteriaFailed,
} from "../actions";
import {
	getCustomizeQuickSearchInput,
	getSelectedCriteria,
	isDeletingCriteria,
	isUpdatingCriteria,
} from "../selectors";
import { api } from "./api";

export const useCustomizeQuickSearch = (namespace, currentInput) => {
	const dispatch = useDispatch();
	const input = useSelector(getCustomizeQuickSearchInput);
	const updating = useSelector(isUpdatingCriteria);
	const deleting = useSelector(isDeletingCriteria);
	const selectedCriteria = useSelector(getSelectedCriteria);

	const onInputChange = (input) => {
		dispatch(setQuickSearchCriteriaInput(input));
	};

	const onSelectedCriteriaChange = (criteria) => {
		dispatch(setQuickSearchSelectedCriteria(criteria));
	};

	const updateCriteria = (
		fields,
		input,
		onSuccess = undefined,
		onFailure = undefined,
	) => {
		if (!updating) {
			dispatch(requestUpdateQuickSearchCriteria());

			api(namespace).update(
				fields,
				input,
				(data) => {
					dispatch(quickSearchCriteriaUpdated());
					dispatch(addToast(TOAST_QUICK_SEARCH_CRITERIA_UPDATED));
					dispatch(inputChanged(currentInput.merge(input)));
					if (onSuccess) {
						onSuccess(data);
					}
				},
				(error) => {
					dispatch(updateQuickSearchCriteriaFailed(error));
					if (onFailure) {
						onFailure(error);
					}
				},
			);
		}
	};

	const resetCriteria = (onSuccess, onFailure) => {
		if (!deleting) {
			dispatch(requestDeleteQuickSearchCriteria());
			dispatch(addToast(TOAST_QUICK_SEARCH_CRITERIA_DELETED));
			api(namespace).delete(
				() => {
					dispatch(quickSearchCriteriaAreDeleted());
					if (onSuccess) {
						onSuccess();
					}
				},
				(error) => {
					dispatch(deleteQuickSearchCriteriaFailed(error));
					if (onFailure) {
						onFailure(error);
					}
				},
			);
		}
	};

	return {
		input,
		selectedCriteria,
		onInputChange,
		onSelectedCriteriaChange,
		updateCriteria,
		resetCriteria,
	};
};
