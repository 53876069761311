import {
	AIRCRAFT_URL,
	ENTITIES_URL,
	OPERATORS_URL,
	renderLink,
} from "../search/result/renderers";
import { convertDecimalHoursToHrsMins } from "../search/utils";

export const renderAvgAnnualCyclesValue = (value) =>
	Math.round(value).toLocaleString();
export const renderAvgHoursValue = (value) =>
	convertDecimalHoursToHrsMins(Number.parseFloat(value));
export const renderCyclesValue = (value) =>
	Number.parseInt(value).toLocaleString();
export const renderDateValue = (value) => value.get("date");
export const renderHoursValue = (value) =>
	Number.parseFloat(value).toLocaleString(undefined, {
		minimumFractionDigits: 2,
	});

export const renderMarketRate = (value) =>
	Number.parseFloat(value).toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});

export const renderMarketValue = (value) => "$" + value + "M";

export function renderAircraftLink(value, item) {
	const id = item.get("id") || item.get("aircraft_id") || "";
	return renderLink(AIRCRAFT_URL + id, value.toString());
}

export function renderAircraftStatusBasedLink(value, item) {
	const id = item.get("id") || item.get("aircraft_id") || "";
	const label =
		value.get("status") === "unknown" ? "(unknown)" : value.get("value");
	return renderLink(AIRCRAFT_URL + id, label);
}

export function renderAvailabilityLink(value, item) {
	const id = item.get("id");
	return value ? renderLink(AIRCRAFT_URL + id, "Yes") : "No";
}

export function renderDivisionLink(value) {
	return renderLink(
		OPERATORS_URL + value.get("code"),
		value.get("name") + " Division",
	);
}

export function renderOrderCommentLink(value, item) {
	const label = value.get("short_text");

	if (!label) {
		return;
	}

	const id = item.get("id");
	return renderLink(AIRCRAFT_URL + id, label);
}

export function renderOwnershipLink(value) {
	const code = value.get("code");
	const name = value.get("name");
	return code ? renderLink(ENTITIES_URL + code, name) : name;
}

export const renderMyAirTradeLink = (value) => {
	return renderLink(value, value, "", "_blank");
};

export const renderPitchValue = (value) => {
	const from = value.get("from");
	const to = value.get("to");
	return from !== to ? `${from} to ${to}''` : `${from}''`;
};

export function renderRegistrationLink(value) {
	const code = value.get("code");
	const name = value.get("name");
	return renderLink(AIRCRAFT_URL + code, name);
}

export function renderSubsidiaryLink(value) {
	return renderLink(
		OPERATORS_URL +
			value.get("code") +
			"#ownershipPartners?tabsGroup1=tab-3",
		value.get("name"),
	);
}

export const renderWeightValue = (value) => value.get("value");

export function renderAircraftStatus(value, item) {
	if (value !== "Crashed" && value !== "Beyond repair") {
		return value;
	}

	return renderLink(AIRCRAFT_URL + item.get("id") + "#accidents", value);
}

export function renderNumberOfAircraftLink(value) {
	return value.get("value") === 0
		? ""
		: renderLink(value.get("link"), value.get("value").toString());
}
