import Immutable from "immutable";
import { useEffect, useState } from "react";
import useFetchChoices from "./useFetchChoices";

export default function useNamesForCodes(
	endpoint,
	codeFetchKey,
	codeFetchParameter,
	codes,
) {
	const [index, setIndex] = useState(() => Immutable.Map());
	const { choices, loading, error } = useFetchChoices(
		endpoint,
		{
			[codeFetchParameter]: codes.toJS(),
		},
		!codes.isEmpty(),
	);

	useEffect(() => {
		if (!loading && !error && choices) {
			const nameIndex = choices.reduce((obj, item) => {
				obj[item[codeFetchKey]] = item.name;
				return obj;
			}, {});

			setIndex(Immutable.Map(nameIndex));
		}
	}, [loading, error, choices]);

	return {
		nameIndex: index,
	};
}
