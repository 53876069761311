import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../../common/hash/actions";
import { getSelectedSearchIdFromHash } from "../../searches/selectors";
import { receiveViews, receiveViewsFailed, requestViews } from "../actions";
import { getSelectedViewIdFromHash, isLoadedViews } from "../selectors";
import useSelectView from "../useSelectView";
import useFetchViews from "./useFetchViews";

export default function useLoadViews(namespace) {
	const dispatch = useDispatch();
	const loaded = useSelector(isLoadedViews);
	const shouldFetch = !loaded;
	const viewFromHash = useSelector(getSelectedViewIdFromHash);
	const searchFromHash = useSelector(getSelectedSearchIdFromHash);
	const { onSelectView } = useSelectView();

	const { views, loading, error } = useFetchViews(namespace, shouldFetch);

	useEffect(() => {
		if (loading) {
			dispatch(requestViews(views));
		} else if (error) {
			dispatch(receiveViewsFailed(views));
		} else if (views) {
			dispatch(receiveViews(views));
			if (!viewFromHash || searchFromHash) {
				return;
			}
			const viewExists = views.some((view) => view.id === viewFromHash);
			if (viewExists) {
				onSelectView(viewFromHash, false);
			} else {
				dispatch(updateSearchParameters({ selectedViewId: undefined }));
			}
		}
	}, [loading, error]);

	return { error };
}
