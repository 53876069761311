import React from "react";
import { pagingType } from "../../../common/types";
import { PagingBar } from "./PagingBar";
import usePaging from "./usePaging";

export function Paging({ paging }) {
	const { currentPage, pages, onPageSelected } = usePaging(paging);

	if (pages <= 1) {
		return null;
	}

	return (
		<PagingBar
			pages={pages}
			currentPage={currentPage}
			onPageSelected={onPageSelected}
		/>
	);
}

Paging.propTypes = {
	paging: pagingType.isRequired,
};
