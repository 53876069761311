import React from "react";
import { criterionLinkType } from "../../../../common/types";

export default function CriterionLink({ link }) {
	if (!link) {
		return null;
	}

	return (
		<a
			className="dropdown-link"
			href={link.url}
			onClick={(e) => e.stopPropagation()}
			target="_blank"
			rel="noreferrer"
		>
			{link.content}
		</a>
	);
}

CriterionLink.propTypes = {
	link: criterionLinkType,
};
