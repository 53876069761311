import { MENU_CRITERION } from "../../common/components/modals/constants";

export const getPrefixedChoiceLabel = (label, prefix) =>
	`${prefix ?? ""}${prefix ? " " : ""}${label}`;

export const getCriterionModalId = (criterionKey) =>
	criterionKey + MENU_CRITERION;

export const setInputToLocalStorage = (key, input) => {
	input ? localStorage.setItem(key, input) : localStorage.removeItem(key);
};

export const getInputFromClientState = (key, cookiePrefix, choices) => {
	setCookiesToLocalStorage(key, cookiePrefix, choices);

	return localStorage?.getItem(key);
};

export const getCookie = (name) => {
	let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length === 2) {
		return parts.pop().split(";").shift();
	}
};

export const getInputFromCookies = (cookiePrefix, choices) => {
	const inputs = choices.map((choice) => choice.key);
	const cookiesExist =
		inputs.filter((v) => getCookie(cookiePrefix + v)).length > 0;

	if (!cookiesExist) {
		return null;
	}

	return inputs.filter((v) => getCookie(cookiePrefix + v) === "1");
};

function setCookiesToLocalStorage(key, cookiePrefix, choices) {
	const inputFromCookies = getInputFromCookies(cookiePrefix, choices);

	if (inputFromCookies?.length > 0) {
		choices.map((choice) => {
			const choiceCookie = cookiePrefix + choice.key;
			const cookieExists = typeof getCookie(choiceCookie) !== "undefined";

			if (cookieExists) {
				document.cookie =
					choiceCookie +
					"=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			}
		});

		setInputToLocalStorage(key, inputFromCookies);
	}
}
