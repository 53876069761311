import React from "react";
import { WindowSizeInitializer } from "../common/layout/WindowSizeInitializer";
import {
	columnsType,
	criteriaGroupsType,
	namespaceType,
} from "../common/types";
import { RequestResultsForPrintInitializer } from "./components/RequestResultsForPrintInitializer";
import { RequestSearchInitializer } from "./components/RequestSearchInitializer";
import { SelectedChoicesInitializer } from "./components/SelectedChoicesInitializer";
import { ValidationInitializer } from "./components/ValidationInitializer";
import { DatesInitializer } from "./criteria/components/DatesInitializer";
import { ColumnsInitializer } from "./result/table/columns/ColumnsInitializer";
import { SearchScrollPosition } from "./SearchScrollPosition";
import { UrlValidator } from "./validator/UrlValidator";
import { ViewTrackerInitializer } from "./views/ViewTrackerInitializer";

export const Initializers = ({ columns, criteria, namespace, ...props }) => {
	return (
		<>
			<ColumnsInitializer columns={columns} />
			<DatesInitializer />
			<RequestResultsForPrintInitializer namespace={namespace} />
			<RequestSearchInitializer namespace={namespace} {...props} />
			<SearchScrollPosition namespace={namespace} />
			<SelectedChoicesInitializer criteria={criteria} />
			<ValidationInitializer criteria={criteria} />
			<ViewTrackerInitializer />
			<WindowSizeInitializer />
			<UrlValidator />
		</>
	);
};

Initializers.propTypes = {
	columns: columnsType,
	criteria: criteriaGroupsType.isRequired,
	namespace: namespaceType.isRequired,
};
