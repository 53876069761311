import Immutable from "immutable";
import { isEmpty, isEqual } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getCurrentHashData, hasHashData } from "../common/hash/reducers";
import { getCurrentInput } from "../common/input/selectors";
import { isFormDirty } from "./reducers";
import {
	getSearchResult,
	getSearchResultError,
	getSearchResultTotal,
	isSearchResultLoading,
} from "./result/selectors";

/**
 * @param {string[][]|string[]|null} [keyPaths] - Allows current input to only subscribe to changes defined in the keyPath array.
 * Pass array of strings to define which property to track.
 * In case you want to track multiple properties, pass an array of string arrays.
 * To disable selector or just get initial state, pass an array with an empty string - <code>([''])</code>.
 */
export default function useSearchState(keyPaths = null) {
	const searchStateCurrentInputEquality = useCallback(
		(left, right) => {
			if (keyPaths && !isEmpty(keyPaths)) {
				if (keyPaths.every((path) => Array.isArray(path))) {
					return keyPaths.every((path) =>
						isEqual(left.getIn(path), right.getIn(path)),
					);
				}
				if (keyPaths.every((path) => typeof path === "string")) {
					return isEqual(left.getIn(keyPaths), right.getIn(keyPaths));
				}
			}
			return left.equals(right);
		},
		[keyPaths],
	);

	const hashDataExists = useSelector(hasHashData);
	const hashData = useSelector(getCurrentHashData);
	const currentInput = useSelector(
		(state) => getCurrentInput(state),
		searchStateCurrentInputEquality,
	);
	const result = useSelector(getSearchResult);
	const loading = useSelector(isSearchResultLoading);
	const error = useSelector(getSearchResultError);
	const total = useSelector(getSearchResultTotal);
	const isDirty = useSelector(isFormDirty);

	const resultExists =
		result !== undefined &&
		result instanceof Immutable.Map &&
		result.get("items").size > 0;
	const resultsAreLoaded = !loading && resultExists;

	let columnsVisible;
	let rows;
	let paging;
	let results;
	let warnings;

	if (!loading && resultExists) {
		columnsVisible = result.get("columns");
		rows = result.get("items");
		paging = result.get("paging");
		results = result.getIn(["paging", "results"], 0);
		warnings = result.get("warnings");
	}

	return {
		columnsVisible,
		currentInput,
		error,
		hashData,
		hashDataExists,
		isDirty,
		loading,
		paging,
		result,
		resultExists,
		results,
		resultsAreLoaded,
		rows,
		total,
		warnings,
	};
}
