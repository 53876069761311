import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { POPOVER_RESTRICTED } from "../../../../common/components/modals/constants";
import { Popover } from "../../../../common/components/modals/Popover";
import { rolePackageNames } from "../../../../common/roles/messages";
import useRolesRestrictions from "../../../../common/roles/useRolesRestrictions";
import useRolesVisibility from "../../../../common/roles/useRolesVisibility";
import {
	customizeQuickSearchRadiosType,
	customizeQuickSearchSelectedChoicesType,
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import SimpleCheckbox from "../../components/checkboxes/SimpleCheckbox";
import { useCustomizeQuickSearchVisibilityState } from "./useCustomizeQuickSearchVisibilityState";

export function CustomizeQuickSearchCheckbox({
	checked,
	criterionKey,
	label,
	disabled,
	onChange,
	radios,
	requiredRoles,
	selectedCriteria,
	visibilitySelector,
}) {
	const visible = useCustomizeQuickSearchVisibilityState(visibilitySelector);
	const { hasRequiredToOpenRoles } = useRolesRestrictions(requiredRoles);
	const { hasRequiredRoles } = useRolesVisibility(requiredRoles);
	const isRestricted = !hasRequiredToOpenRoles;

	if (!visible || !hasRequiredRoles) {
		return null;
	}

	const handleSelectedCriteriaInputChange = (key) => {
		let newInput;
		let index = selectedCriteria.indexOf(key);

		if (selectedCriteria.includes(key)) {
			newInput = selectedCriteria.delete(index);
		} else {
			newInput = selectedCriteria.push(key);
		}

		onChange(newInput);
	};

	const classes = clsx("grid__col-6", {
		"grid__col-md-12": radios.length === 1,
		"grid__col-xl-4": radios.length === 1,
		"grid__col-xl-3": radios.length === 0,
		"restricted-checkbox": isRestricted,
	});

	const restrictedIcon = isRestricted && (
		<Popover
			classes="popover-grey"
			id={POPOVER_RESTRICTED}
			toggleClasses="btn--icon btn--restricted"
			offset={[-16, 0]}
		>
			<p>
				This information is only visible with a{" "}
				{rolePackageNames[requiredRoles]} subscription.
			</p>
			<a
				href="/account-management/subscription"
				className="btn btn--link"
			>
				Add to Subscription
			</a>
		</Popover>
	);

	return (
		<div className={classes}>
			<SimpleCheckbox
				checked={checked}
				disabled={isRestricted || disabled}
				itemKey={criterionKey}
				inputId={`customize_quick_search_${criterionKey}`}
				onToggle={handleSelectedCriteriaInputChange}
				value={label}
			/>
			{restrictedIcon}
		</div>
	);
}

CustomizeQuickSearchCheckbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	criterionKey: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	radios: customizeQuickSearchRadiosType.isRequired,
	requiredRoles: requiredRolesType,
	selectedCriteria: customizeQuickSearchSelectedChoicesType.isRequired,
	visibilitySelector: visibilitySelectorType,
};
