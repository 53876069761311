import PropTypes from "prop-types";
import React from "react";
import { MODAL_LOAD_SEARCH } from "../../../common/components/modals/constants";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import useSearches from "../useSearches";

export function DeleteSearch({ modalKey, namespace }) {
	const { searchToDelete, deleteSearch } = useSearches(namespace);
	const { onOpenModal } = useModal();

	const handleDeleteClick = () => {
		deleteSearch(searchToDelete);
		onOpenModal(MODAL_LOAD_SEARCH);
	};

	const handleCancelClick = () => {
		onOpenModal(MODAL_LOAD_SEARCH);
	};

	const title = "Are you sure you want to delete this search?";
	const alignContent = "modal__container--center";

	return (
		<Modal modalKey={modalKey} title={title} alignContent={alignContent}>
			<div className="flex justify-center modal__content">
				<p>You can’t undo this step.</p>
			</div>

			<ModalFooter classes="justify-space-between">
				<button
					type="button"
					className="btn btn--secondary"
					onClick={handleDeleteClick}
				>
					Yes, delete
				</button>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleCancelClick}
				>
					No, leave
				</button>
			</ModalFooter>
		</Modal>
	);
}

DeleteSearch.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
};
