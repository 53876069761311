export class InputSearch {
	constructor(
		id,
		label,
		placeholder,
		errorMessage,
		itemsLength,
		onInputChange,
	) {
		this.id = id;
		this.label = label;
		this.placeholder = placeholder;
		this.errorMessage = errorMessage;
		this.itemsLength = itemsLength;
		this.onInputChange = onInputChange;
	}

	static add = (
		id,
		label,
		placeholder,
		errorMessage,
		itemsLength,
		onInputChange,
	) => {
		return new InputSearch(
			id,
			label,
			placeholder,
			errorMessage,
			itemsLength,
			onInputChange,
		);
	};
}
