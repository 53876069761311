export function getValidationResult(state) {
	return state.getIn(["search", "validation", "criteria"]);
}

export function getValidationRules(state) {
	return state.getIn(["search", "validation", "rules"]);
}

export function getCriterionValidationResult(state, criterionKey) {
	return getValidationResult(state).get(criterionKey);
}

export function getFormValid(state) {
	const validationResult = getValidationResult(state);
	return (
		validationResult.isEmpty() ||
		validationResult.every((item) => item.isSuccess())
	);
}
