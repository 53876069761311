export const SEARCH_GET_RESULT = "SEARCH_GET_RESULT";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const SEARCH_RESULT_FAILED = "SEARCH_RESULT_FAILED";
export const SEARCH_CLEAR_RESULT = "SEARCH_CLEAR_RESULT";
export const SEARCH_RESULT_DOWNLOAD_FORMAT = "SEARCH_RESULT_DOWNLOAD_FORMAT";
export const SEARCH_PRINT_RESULTS = "SEARCH_PRINT_RESULTS";
export const SEARCH_REQUEST_PRINT_RESULTS = "SEARCH_REQUEST_PRINT_RESULTS";
export const SEARCH_PRINT_RESULTS_ERROR = "SEARCH_PRINT_RESULTS_ERROR";
export const SEARCH_PRINT_RESULTS_LOADING = "SEARCH_PRINT_RESULTS_LOADING";

export function requestResult() {
	return {
		type: SEARCH_GET_RESULT,
	};
}

export function receiveResult(result) {
	return {
		type: SEARCH_RESULT,
		data: {
			result,
		},
	};
}

export function receiveResultFailed(error) {
	return {
		type: SEARCH_RESULT_FAILED,
		data: {
			error,
		},
	};
}

export function clearResult() {
	return {
		type: SEARCH_CLEAR_RESULT,
	};
}

export function setDownloadFormat(format) {
	return {
		type: SEARCH_RESULT_DOWNLOAD_FORMAT,
		data: {
			format,
		},
	};
}

export function requestPrintResults() {
	return {
		type: SEARCH_REQUEST_PRINT_RESULTS,
	};
}

export function setPrintResultsLoading() {
	return {
		type: SEARCH_PRINT_RESULTS_LOADING,
	};
}

export function setPrintResults(result) {
	return {
		type: SEARCH_PRINT_RESULTS,
		data: {
			result,
		},
	};
}

export function setPrintResultsError() {
	return {
		type: SEARCH_PRINT_RESULTS_ERROR,
	};
}
