import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { useBlockPreview } from "../../../searches/useBlockPreview";
import { TextInput } from "../../TextInput";
import useValidation from "../../validation/useValidation";
import { SearchCriterion } from "../common/SearchCriterion";

export function FreeTextCriterion({
	customValidation,
	criterionKey,
	label,
	requiredRoles,
	placeholder,
	prefix,
	quickSearch = false,
	visibilitySelector,
}) {
	const { currentInput, onInputChange } = useFormComponentState(
		criterionKey,
		"",
	);
	const { valid, message } = useValidation(criterionKey);
	const { blockPreview, allowPreview } = useBlockPreview();

	return (
		<SearchCriterion
			label={label}
			prefix={prefix}
			requiredRoles={requiredRoles}
			quickSearch={quickSearch}
			visibilitySelector={visibilitySelector}
		>
			<div className={clsx("criterion input-field", { error: !valid })}>
				<TextInput
					name={label}
					id={`${criterionKey}${quickSearch ? "_quick_search" : ""}`}
					label={label}
					value={currentInput}
					placeholder={placeholder}
					customValidation={customValidation}
					prefix={prefix}
					error={message}
					onChange={(e) => onInputChange(e.target.value)}
					onFocus={blockPreview}
					onBlur={allowPreview}
				/>
			</div>
		</SearchCriterion>
	);
}

FreeTextCriterion.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	customValidation: PropTypes.func,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool,
	requiredRoles: requiredRolesType,
	visibilitySelector: visibilitySelectorType,
};
