import { useSelector } from "react-redux";
import { getCriterionYears } from "../../selectors";

export default function useYears(criterionKey) {
	const yearMinData = useSelector((state) =>
		getCriterionYears(state, criterionKey),
	);

	return { yearMinData };
}
