import { useSelector } from "react-redux";
import {
	getPreviewCounts,
	hasErrorLoadingPreview,
	isLoadingPreview,
} from "./selectors";

export default function useAccessResultsPreview() {
	const counts = useSelector(getPreviewCounts);
	const loading = useSelector(isLoadingPreview);
	const error = useSelector(hasErrorLoadingPreview);

	const results = {
		counts: counts?.get("results"),
		carriers: counts?.get("carriers"),
		aircraft: counts?.get("aircraft"),
	};

	return { results, loading, error };
}
