import PropTypes from "prop-types";
import React from "react";

export default function CriterionFooter({
	clearLabel = "Clear all",
	guideline,
	onClearInput,
	onSaveInput,
}) {
	return (
		<footer className="flex justify-space-between">
			{guideline && <span className="range__guideline">{guideline}</span>}
			{clearLabel && (
				<button
					type="button"
					className="btn btn--link"
					onClick={onClearInput}
				>
					{clearLabel}
				</button>
			)}
			<button
				type="button"
				className="btn btn--primary btn--small"
				onClick={onSaveInput}
			>
				Save
			</button>
		</footer>
	);
}

CriterionFooter.propTypes = {
	clearLabel: PropTypes.string,
	guideline: PropTypes.string,
	onClearInput: PropTypes.func.isRequired,
	onSaveInput: PropTypes.func.isRequired,
};
