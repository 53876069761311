import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	receiveSearches,
	requestSearches,
	requestSearchesFailed,
} from "../actions";
import { api } from "../api";
import {
	getSearchesFetchError,
	isFetchingSearches,
	shouldFetchSearches,
} from "../selectors";

export default function useLoadSearches(namespace, searchesEnabled = true) {
	const dispatch = useDispatch();
	const shouldFetch = useSelector(shouldFetchSearches);
	const fetching = useSelector(isFetchingSearches);
	const fetchError = useSelector(getSearchesFetchError);

	useEffect(() => {
		fetchSearches();
	}, []);

	function fetchSearches() {
		if (shouldFetch && searchesEnabled) {
			dispatch(requestSearches());

			api(namespace).load(
				(data) => {
					dispatch(receiveSearches(data));
				},
				(error) => {
					dispatch(requestSearchesFailed(error));
				},
			);
		}
	}

	return { fetching, fetchError };
}
