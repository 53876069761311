import PropTypes from "prop-types";
import React from "react";
import useSearchState from "../../../useSearchState";
import { buildYearOptions } from "../../../utils";
import useYears from "../date/useYears";
import { createDefaultInput } from "./inputs";
import { minMaxRangeCheck, validateInput } from "./validation";

export function RangeFields({
	createControl = createDefaultInput,
	criterionKey,
	datesRange,
	filterDate,
	guideline,
	inputMin,
	inputMax,
	label,
	onInputChange,
	placeholder = "0",
	rangeCheck = minMaxRangeCheck,
	yearMax = new Date().getFullYear(),
	yearMin,
	yearMinKey,
}) {
	const { currentInput } = useSearchState();
	const { yearMinData } = useYears(yearMinKey || criterionKey);
	const inputMinId = criterionKey + "_from";
	const inputMaxId = criterionKey + "_to";
	const inputMinLabel = datesRange ? "Date from" : "From";
	const inputMaxLabel = datesRange ? "Date to" : "To";

	let years = [];

	if (!yearMin) {
		if (yearMinData) {
			years = buildYearOptions(yearMinData, yearMax).reverse();
		}
	} else {
		years = buildYearOptions(yearMin, yearMax).reverse();
	}

	const validationResult = validateInput(
		rangeCheck,
		inputMin,
		inputMax,
		currentInput,
	);

	const error = validationResult.isError() && (
		<span className="range__error">{validationResult.message}</span>
	);

	return (
		<div className="range__fields-wrapper flex">
			<div className="range__fields flex">
				<div className="range-field">
					<label htmlFor={inputMinId}>{inputMinLabel}</label>
					{createControl(
						inputMinId,
						label + " from",
						(value) => onInputChange("min", value),
						placeholder,
						inputMin,
						filterDate,
						years,
					)}
				</div>

				<span className="range-to hide-mobile">to</span>

				<div className="range-field">
					<label htmlFor={inputMaxId}>{inputMaxLabel}</label>
					{createControl(
						inputMaxId,
						label + " to",
						(value) => onInputChange("max", value),
						placeholder,
						inputMax,
						filterDate,
						years,
					)}
				</div>
			</div>

			{!datesRange && guideline && (
				<span className="range__guideline">{guideline}</span>
			)}
			{error}
		</div>
	);
}

RangeFields.propTypes = {
	createControl: PropTypes.func,
	criterionKey: PropTypes.string.isRequired,
	datesRange: PropTypes.bool.isRequired,
	filterDate: PropTypes.func,
	guideline: PropTypes.string,
	inputMax: PropTypes.string,
	inputMin: PropTypes.string,
	label: PropTypes.string.isRequired,
	onInputChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	rangeCheck: PropTypes.func,
	yearMax: PropTypes.number,
	yearMin: PropTypes.number,
	yearMinKey: PropTypes.string,
};
