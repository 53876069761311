import React from "react";
import { footerPresetsType } from "../../common/types";

export function Presets({ footerPresets }) {
	if (!footerPresets) {
		return null;
	}

	return <section className="search-presets">{footerPresets}</section>;
}

Presets.propTypes = {
	footerPresets: footerPresetsType,
};
