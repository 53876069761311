import Immutable from "immutable";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../common/hash/actions";
import { inputChanged } from "../../common/input/actions";
import { useLoadLocalSearchView } from "../../common/useLoadLocalSearchView";
import { useLocalSearchView } from "../../common/useLocalSearchView";
import { requestSearch } from "../api";
import useLoadSearches from "../searches/load/useLoadSearches";
import {
	getSearches,
	getSelectedSearchId,
	getSelectedSearchIdFromHash,
} from "../searches/selectors";
import useSearches from "../searches/useSearches";
import useSearchState from "../useSearchState";
import { setShouldLocalViewInterceptSearchParameters } from "../views/actions";
import {
	isLoadedViews,
	isLocalViewChecked,
	shouldLocalViewInterceptSearchParameters,
} from "../views/selectors";
import { receiveResult, receiveResultFailed, requestResult } from "./actions";

export default function useRequestSearch(
	namespace,
	showResultsOnInitialPageLoad,
	searchesEnabled = true,
) {
	const dispatch = useDispatch();
	const { currentInput, hashData, hashDataExists } = useSearchState();
	const hashInput = hashData.get("input", Immutable.Map());
	const loadFromInput = showResultsOnInitialPageLoad && hashInput.isEmpty();
	const { fetching: fetchingSearches, fetchError: searchFetchError } =
		useLoadSearches(namespace, searchesEnabled);
	const searchFromHash = useSelector(getSelectedSearchIdFromHash);
	const selectedSearch = useSelector(getSelectedSearchId);
	const allSearches = useSelector(getSearches).toJS();
	const { onSelectSearch } = useSearches(namespace);
	const viewsLoaded = useSelector(isLoadedViews);
	const shouldInterceptParameters = useSelector(
		shouldLocalViewInterceptSearchParameters,
	);
	const { getStoredSearchView } = useLocalSearchView(namespace);
	const { handleStoreSearchView } = useLoadLocalSearchView(namespace);
	const storedSearchView = getStoredSearchView();
	const localViewChecked = useSelector(isLocalViewChecked);

	useEffect(() => {
		const shouldSkipRequest =
			(!hashDataExists && !showResultsOnInitialPageLoad) ||
			(searchFromHash && fetchingSearches) ||
			!localViewChecked;
		if (shouldSkipRequest) {
			return;
		}

		const shouldLoadSearchFromHash =
			searchFromHash &&
			!selectedSearch &&
			!fetchingSearches &&
			!searchFetchError &&
			viewsLoaded;
		if (shouldLoadSearchFromHash) {
			const searchToLoad = allSearches[searchFromHash];
			searchToLoad &&
				onSelectSearch(searchToLoad, true, storedSearchView);
			return;
		}

		const shouldLoadLocalSearchView =
			storedSearchView &&
			!isEmpty(storedSearchView) &&
			shouldInterceptParameters;
		if (shouldLoadLocalSearchView) {
			dispatch(updateSearchParameters(storedSearchView));
			dispatch(setShouldLocalViewInterceptSearchParameters(false));
			return;
		}

		let requestData = loadFromInput
			? hashData.set("input", currentInput)
			: hashData;

		handleStoreSearchView(hashData.toJS().parameters);

		requestSearchResult();

		requestSearch(
			namespace,
			requestData,
			(result) => {
				dispatch(receiveResult(result));
			},
			(error) => {
				dispatch(receiveResultFailed(error));
			},
		);
	}, [
		hashDataExists,
		hashData,
		searchFromHash && fetchingSearches,
		searchFromHash && viewsLoaded,
		shouldInterceptParameters,
		localViewChecked,
	]);

	const requestSearchResult = () => {
		const input = loadFromInput ? currentInput : hashInput;
		dispatch(requestResult());
		dispatch(inputChanged(input));
	};
}
