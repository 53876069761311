import PropTypes from "prop-types";
import React from "react";

export function CurrentViewBadge({ isCurrentView }) {
	if (!isCurrentView) {
		return null;
	}

	return <span className="badge">Current View</span>;
}

CurrentViewBadge.propTypes = {
	isCurrentView: PropTypes.bool.isRequired,
};
