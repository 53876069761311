import Immutable from "immutable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSearchState from "../../useSearchState";
import { flattenCriteria } from "../../utils";
import { setValidationResult, setValidationRules } from "./actions";
import { getValidationRules } from "./selectors";
import { validateForm } from "./validation";

export default function useValidate(searchComponents) {
	const dispatch = useDispatch();
	const validationRules = useSelector(getValidationRules);
	const { resultExists } = useSearchState();
	const criteriaFilter = (criteria) =>
		criteria.filter((item) => item.isValidatedImmediately());
	const { currentInput } = useSearchState();

	useEffect(() => {
		if (validationRules.isEmpty()) {
			const rules = Immutable.Map(flattenCriteria(searchComponents))
				.filter((item) => item.validation)
				.map((item) => item.validation);
			dispatch(setValidationRules(rules));
		}

		const { validationResult } = validateForm(
			validationRules,
			currentInput,
			!resultExists ? criteriaFilter : undefined,
		);

		dispatch(setValidationResult(validationResult));
	}, [currentInput, validationRules]);
}
