import PropTypes from "prop-types";
import useSetFetchedChoices from "../../../common/choices/useSetFetchedChoices";

export const SetFetchedChoicesInitializer = ({
	criterionKey,
	criterionLabel,
	criterionChoices,
}) => {
	useSetFetchedChoices(criterionKey, criterionLabel, criterionChoices);

	return null;
};

SetFetchedChoicesInitializer.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	criterionLabel: PropTypes.string.isRequired,
	criterionChoices: PropTypes.array,
};
