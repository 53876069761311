const quickSearchCriteria = (state) =>
	state.getIn(["search", "quickSearchCriteria"]);

export const getQuickSearchCriteria = (state) => {
	return quickSearchCriteria(state).get("criteria");
};

export const isFetchingCriteria = (state) => {
	return quickSearchCriteria(state).get("isFetching");
};

export const isQuickSearchCriteriaLoaded = (state) => {
	return getQuickSearchCriteria(state) !== null;
};

export const getCustomizeQuickSearchInput = (state) => {
	return quickSearchCriteria(state).get("input");
};

export const getSelectedCriteria = (state) => {
	return quickSearchCriteria(state).get("selectedCriteria");
};

export function getInputVisibility(state, criterionKey, visibleOn) {
	const input = getCustomizeQuickSearchInput(state);
	return input?.get(criterionKey) === visibleOn;
}

export function isUpdatingCriteria(state) {
	return quickSearchCriteria(state).get("isUpdating");
}

export function isDeletingCriteria(state) {
	return quickSearchCriteria(state).get("isDeleting");
}

export function isLoadingCriteria(state) {
	return (
		isFetchingCriteria(state) ||
		isUpdatingCriteria(state) ||
		isDeletingCriteria(state)
	);
}

export function shouldRefreshInitialCriteria(state) {
	return quickSearchCriteria(state).get("refreshInitialCriteria");
}

export function getQuickSearchCriteriaSelectionType(state) {
	return quickSearchCriteria(state).get("criteriaSelectionType");
}
