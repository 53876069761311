export const getDefaultSuggestions = (options, value) => {
	if (!value) {
		return [];
	}

	const inputValue = value.trim().toLowerCase();
	const inputCode = value.toUpperCase();
	return inputValue.length === 0
		? []
		: options
				.valueSeq()
				.sortBy((option) => {
					if (option.iata_code === inputCode) {
						return "0";
					}
					if (option.icao_code === inputCode) {
						return "1";
					}
					return option.name;
				})
				.take(20)
				.toArray();
};
