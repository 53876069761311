import { useDispatch, useSelector } from "react-redux";
import useSearchState from "../useSearchState";
import {
	addAllResults,
	addSelectedResults,
	favoritesCodesChanged,
	favoritesListAdded,
	favoritesUpdated,
	receiveDeleteEntitiesFailed,
	receiveEntitiesDeleted,
	requestDeleteEntities,
	requestUpdateFavorites,
	setFavoritesModalContent,
	updateFavoritesFailed,
} from "./actions";
import { api } from "./api";
import { modalContent } from "./favorites";
import {
	getAddAllResults,
	getFavorites,
	getModalContent,
	getSelectedCodes,
	isDeletingFromFavorites,
	isUpdatingFavorites,
} from "./selectors";

export default function useFavorites() {
	const dispatch = useDispatch();
	const favorites = useSelector(getFavorites);
	const modal = useSelector(getModalContent);
	const selectedCodes = useSelector(getSelectedCodes);
	const updatingFavorites = useSelector(isUpdatingFavorites);
	const deletingFromFavorites = useSelector(isDeletingFromFavorites);
	const allResults = useSelector(getAddAllResults);
	const { hashData } = useSearchState([""]);

	const selectedCode =
		selectedCodes.size === 1 ? selectedCodes.first() : null;

	const onInputChange = (input) => {
		dispatch(favoritesCodesChanged(input));
	};

	const handleModalContent = (type) => {
		dispatch(setFavoritesModalContent(modalContent(type)));
	};

	const handleAddAllResults = () => {
		dispatch(addAllResults());
	};

	const handleAddSelectedResults = () => {
		dispatch(addSelectedResults());
	};

	const handleAddEntities = (
		name,
		lists,
		codes,
		params = {},
		onSuccess = undefined,
		onFailure = undefined,
	) => {
		// Only update chosen favorites lists at a time.
		if (!updatingFavorites) {
			dispatch(requestUpdateFavorites());

			// CHA-7217: To filter start-ups only
			const input = allResults
				? hashData.mergeIn(["input"], params)
				: null;

			api().update(
				name,
				lists,
				codes,
				input,
				(data) => {
					dispatch(favoritesUpdated(data));

					if (name) {
						dispatch(favoritesListAdded());
					}

					if (onSuccess) {
						onSuccess(data);
					}
				},
				(error) => {
					dispatch(updateFavoritesFailed(error));
					if (onFailure) {
						onFailure(error);
					}
				},
			);
		}
	};

	const handleDeleteEntities = (
		lists,
		codes,
		onSuccess = undefined,
		onFailure = undefined,
	) => {
		// Only delete codes from favorites lists at a time.
		if (!deletingFromFavorites) {
			dispatch(requestDeleteEntities());

			api().delete(
				lists,
				codes,
				() => {
					dispatch(receiveEntitiesDeleted());
					if (onSuccess) {
						onSuccess();
					}
				},
				(error) => {
					dispatch(receiveDeleteEntitiesFailed(error));
					if (onFailure) {
						onFailure(error);
					}
				},
			);
		}
	};

	return {
		favorites,
		selectedCodes,
		selectedCode,
		modal,
		allResults,
		onInputChange,
		handleModalContent,
		handleAddAllResults,
		handleAddSelectedResults,
		handleAddEntities,
		handleDeleteEntities,
	};
}
