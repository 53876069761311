import PropTypes from "prop-types";
import React from "react";
import { FavoriteListNameInput } from "./FavoriteListNameInput";
import { FavoritesAlert } from "./FavoritesAlert";

export function CreateNewList({ error, onChange, onCreateAlert }) {
	return (
		<>
			<p>You have not saved any favourite lists yet.</p>
			<div className="modal__body">
				<section className="modal__section">
					<FavoriteListNameInput error={error} onChange={onChange} />
				</section>

				<FavoritesAlert onCreateAlert={onCreateAlert} />
			</div>
		</>
	);
}

CreateNewList.propTypes = {
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onCreateAlert: PropTypes.func.isRequired,
};
