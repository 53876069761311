import PropTypes from "prop-types";
import React from "react";

export const CriterionLabel = ({ label, prefix }) => {
	if (!label) {
		return null;
	}

	return (
		<span className="criterion__label">
			{prefix && (
				<span className="criterion__label__prefix">{prefix}</span>
			)}
			{label}
		</span>
	);
};

CriterionLabel.propTypes = {
	label: PropTypes.string,
	prefix: PropTypes.string,
};
