import { useEffect, useState } from "react";

/**
 * @param value any - value should be of primitive type
 * @param [delay] number - delay in ms, if empty, value will be set immediately
 */
export default function useDebounceValue(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
			setLoading(false);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return { debouncedValue, loading };
}
