import { Map } from "immutable";
import queryString from "query-string";
import React from "react";
import { FavoritesEmptyCell } from "../../favorites/result/FavoritesEmptyCell";
import { FavoritesIndicatorCell } from "../../favorites/result/FavoritesIndicatorCell";
import { FavoritesSelectAllCell } from "../../favorites/result/FavoritesSelectAllCell";
import { FavoritesSelectCell } from "../../favorites/result/FavoritesSelectCell";
import { scrollFootnotesIntoView } from "../../utils";
import {
	getColumnClasses,
	getColumnsRenderers,
	getValueSelectors,
} from "../../views/edit/utils";
import { SearchResultsHeaderCell } from "./header/SearchResultsHeaderCell";
import { EmptyHeaderCell } from "./partials/EmptyHeaderCell";
import { FeedbackCell } from "./partials/FeedbackCell";
import { SearchResultsRestrictedCell } from "./partials/SearchResultsRestrictedCell";
import useChangeSort from "./useChangeSort";

export const generateHeaderCells = (columns, columnsVisible) => {
	const { handleSortClick } = useChangeSort();
	const columnRenderers = getColumnsRenderers(columns);

	return columnsVisible
		.filter((column) => columnRenderers[column.get("key")] !== null)
		.map((column) => {
			const columnKey = column.get("key");
			return (
				<SearchResultsHeaderCell
					key={columnKey}
					column={column}
					columnRenderers={columnRenderers}
					onSortClick={handleSortClick}
				/>
			);
		});
};

export const generateBodyCells = (columns, columnsVisible, item, footnotes) => {
	const columnRenderers = getColumnsRenderers(columns);
	const valueSelectors = getValueSelectors(columns);
	const columnClasses = getColumnClasses(columns);

	function generateCell(column, columnRenderers) {
		const columnKey = column.get("key");
		const value =
			item.get(valueSelectors[columnKey]) || item.get(columnKey);

		let content;

		if (columnRenderers[columnKey] === undefined) {
			content = "No renderer for column " + columnKey;
		} else if (!value || value === "") {
			content = "–";
		} else if (Map.isMap(value) && value.has("hidden")) {
			content = (
				<SearchResultsRestrictedCell
					packageName={value.get("package_name")}
				/>
			);
		} else {
			content = columnRenderers[columnKey](value, item) || "–";
		}

		const marker = footnotes ? footnotes.marker(columnKey, value) : null;

		if (marker !== null) {
			content = (
				<>
					{content}{" "}
					<span
						className="footnotes__marker footnotes__marker__clickable"
						onClick={scrollFootnotesIntoView}
					>
						<sup>{marker}</sup>
					</span>
				</>
			);
		}

		return (
			<td key={columnKey} className={columnClasses[columnKey]}>
				{content}
			</td>
		);
	}

	return columnsVisible
		.filter((column) => columnRenderers[column.get("key")] !== null)
		.map((column) => generateCell(column, columnRenderers));
};

export const resultWithFavoritesRenderConfig = (columns) => {
	return {
		columnHeaderGenerator: (columnsVisible) =>
			generateHeaderCells(columns, columnsVisible)
				.unshift(<FavoritesSelectAllCell key="selectAll" />)
				.push(<FavoritesEmptyCell key="empty" />),
		columnCellGenerator: (columnsVisible, item, footnotes) => {
			const code = item.getIn(["airline_default", "code"]);
			const name = item.getIn(["airline_default", "name"]);

			return generateBodyCells(columns, columnsVisible, item, footnotes)
				.unshift(
					<FavoritesSelectCell
						key={`select${code}`}
						code={code}
						name={name}
					/>,
				)
				.push(
					<FavoritesIndicatorCell
						key={`indicator${code}`}
						code={code}
						name={name}
					/>,
				);
		},
	};
};

export const resultWithFeedbackRenderConfig = (columns, getFeedbackQuery) => {
	return {
		columnHeaderGenerator: (columnsVisible) => {
			return generateHeaderCells(columns, columnsVisible).push(
				<EmptyHeaderCell key="feedbackHeaderCell" />,
			);
		},
		columnCellGenerator: (columnsVisible, item, footnotes) => {
			const feedbackURL =
				"/feedback?" + queryString.stringify(getFeedbackQuery(item));

			return generateBodyCells(
				columns,
				columnsVisible,
				item,
				footnotes,
			).push(<FeedbackCell key="feedbackCell" url={feedbackURL} />);
		},
	};
};

export const defaultRenderConfig = (columns) => {
	return {
		columnHeaderGenerator: (columnsVisible) =>
			generateHeaderCells(columns, columnsVisible),
		columnCellGenerator: (columnsVisible, item, footnotes) =>
			generateBodyCells(columns, columnsVisible, item, footnotes),
	};
};
