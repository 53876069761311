// -------------------- SEARCH TYPES -------------------- //
export const ADVANCED_SEARCH = "advancedSearch";
export const QUICK_SEARCH = "quickSearch";

// -------------------- NAMESPACES -------------------- //
export const ACCIDENTS_AND_INCIDENTS_NAMESPACE = "accidents-and-incidents";
export const ADS_B_NAMESPACE = "flights";
export const AIRCRAFT_NAMESPACE = "aircraft";
export const AIRCRAFT_HISTORY_NAMESPACE = "aircraft-history";
export const AIRCRAFT_ORDER_NAMESPACE = "orders";
export const AIRPORT_NAMESPACE = "airports";
export const CAPACITY_NAMESPACE = "capacity";
export const CONTACTS_NAMESPACE = "contacts";
export const LESSORS_NAMESPACE = "lessors";
export const OPERATOR_NAMESPACE = "airlines";
export const MRO_EVENTS_NAMESPACE = "mro-events";
export const MRO_PROVIDER_NAMESPACE = "mro-providers";
export const NEWS_NAMESPACE = "news";
export const ROUTES_NAMESPACE = "routes";
export const SCHEDULES_NAMESPACE = "schedules";
