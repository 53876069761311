import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useDropdown } from "../../../common/components/dropdown/useDropdown";
import { rolePackageNames } from "../../../common/roles/messages";
import { CriterionLabel } from "./common/CriterionLabel";
import useDropdownPosition from "./useDropdownPosition";

export function RestrictedCriterion({ label, prefix, quickSearch, roles }) {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const {
		isDropdownBelowViewport,
		isDropdownRightOfViewport,
		isDropdownHidden,
	} = useDropdownPosition(dropdownRef, dropdownIsOpened, quickSearch);

	const packages = roles.map((role, index) => {
		if (index > 0) {
			return ` or ${rolePackageNames[role]}`;
		}

		return rolePackageNames[role];
	});

	const content = (
		<>
			<p>Requires {packages} subscription.</p>
			<a href="/account-management/subscription">Add to Subscription</a>
		</>
	);

	if (!quickSearch) {
		return (
			<div className="criterion criterion-restricted">
				<section className="criterion-restricted__content">
					<p className="title">{label}</p>
					{content}
				</section>
			</div>
		);
	}

	return (
		<div
			ref={dropdownRef}
			className="criterion dropdown criterion-restricted"
		>
			<button
				type="button"
				className="dropdown-toggle"
				aria-expanded={dropdownIsOpened}
				onClick={onDropdownToggle}
			>
				<CriterionLabel label={label} prefix={prefix} />
			</button>

			<div
				className={clsx("dropdown-menu", {
					"dropdown-menu--opened": dropdownIsOpened,
					"dropdown-menu--inverted-y": isDropdownBelowViewport,
					"dropdown-menu--inverted-x": isDropdownRightOfViewport,
					"dropdown-menu--hidden": isDropdownHidden,
				})}
				data-criterion={true}
				tabIndex={-1}
			>
				{content}
			</div>
		</div>
	);
}

RestrictedCriterion.propTypes = {
	label: PropTypes.string.isRequired,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool.isRequired,
	roles: PropTypes.array.isRequired,
};
