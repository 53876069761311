import { combineReducers } from "redux";
import { INPUT_FIELD_CHANGED } from "../common/input/actions";
import { quickSearchCriteriaReducer } from "./criteria/quick-search/reducers";
import { criteriaReducer } from "./criteria/reducers";
import { validationReducer } from "./criteria/validation/reducers";
import { previewReducer } from "./preview/reducers";
import { resultReducer } from "./result/reducers";
import { columnsReducer } from "./result/table/columns/reducers";
import {
	FORM_SEARCHES_SEARCH_CREATED,
	FORM_SEARCHES_SEARCH_UPDATED,
	FORM_SEARCHES_SELECT_SEARCH,
} from "./searches/actions";
import { searchesReducer } from "./searches/reducers";
import { viewsReducer } from "./views/reducers";

const dirtyReducer = function (state = false, action) {
	switch (action.type) {
		case FORM_SEARCHES_SEARCH_CREATED:
		case FORM_SEARCHES_SELECT_SEARCH:
		case FORM_SEARCHES_SEARCH_UPDATED:
			return false;
		case INPUT_FIELD_CHANGED:
			return true;
		default:
			return state;
	}
};

export const createSearchReducers = combineReducers({
	criteria: criteriaReducer,
	columns: columnsReducer,
	dirty: dirtyReducer,
	preview: previewReducer,
	quickSearchCriteria: quickSearchCriteriaReducer,
	result: resultReducer,
	searches: searchesReducer,
	validation: validationReducer,
	views: viewsReducer,
});

export function isFormDirty(state) {
	return state.getIn(["search", "dirty"]);
}
