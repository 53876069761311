import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { MODAL_DELETE_VIEW } from "../../../common/components/modals/constants";
import useModal from "../../../common/components/modals/useModal";
import { viewType } from "../../../common/types";
import { CurrentViewBadge } from "./CurrentViewBadge";

export function ViewItem({
	view,
	current,
	selected,
	error,
	onSelect,
	onSelectViewToDelete,
}) {
	const { id, name } = view;
	const isSelected = selected === id;
	const isCurrentView = current?.id === id;
	const { onOpenModal } = useModal();

	const handleDelete = (id) => {
		onSelectViewToDelete(id);
		onOpenModal(MODAL_DELETE_VIEW);
	};

	return (
		<div className={clsx("radio-item", { error })}>
			<div className="radio-input">
				<input
					type="radio"
					id={`load-view-${id}`}
					name="load-view"
					value={name}
					checked={isSelected}
					onChange={() => onSelect(id)}
				/>
				<label htmlFor={`load-view-${id}`}>
					{name} <CurrentViewBadge isCurrentView={isCurrentView} />
				</label>
			</div>

			{error && (
				<span className="error-text" role="alert">
					An error occurred: {error}.
				</span>
			)}

			{!isCurrentView && (
				<button
					type="button"
					className="btn btn--secondary btn--icon btn--delete"
					onClick={() => handleDelete(id)}
				>
					Delete
				</button>
			)}
		</div>
	);
}

ViewItem.propTypes = {
	view: viewType.isRequired,
	current: viewType,
	selected: PropTypes.number.isRequired,
	error: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	onSelectViewToDelete: PropTypes.func.isRequired,
};
