export const ROLES_INIT = "ROLES_INIT";

export function initRoles(roles) {
	return {
		type: ROLES_INIT,
		data: {
			roles,
		},
	};
}
