import PropTypes, {
	arrayOf,
	instanceOf,
	objectOf,
	oneOfType,
	shape,
} from "prop-types";
import * as ImmutablePropTypes from "react-immutable-proptypes";
import Disclaimers from "../../search/components/disclaimers/Disclaimers";
import Footnotes from "../../search/components/footnotes/Footnotes";
import { ADVANCED_SEARCH, QUICK_SEARCH } from "../../search/constants";
import { RequiredRoles } from "../../search/criteria/components/common/requiredRoles";
import { VisibilitySelector } from "../../search/criteria/VisibilitySelector";
import { InputSearch } from "../components/modals/InputSearch";

export const visibilitySelectorType = instanceOf(VisibilitySelector);

export const actionsButtonDependsOnType = objectOf(PropTypes.func.isRequired);
export const additionalActionsType = PropTypes.oneOfType([
	PropTypes.arrayOf(PropTypes.element),
	PropTypes.element.isRequired,
]);
export const additionalSearchType = PropTypes.element;
export const additionalSearchLinkType = PropTypes.element;
export const autoSuggestType = PropTypes.string;

export const advancedSearchAdditionalLinkType = PropTypes.element;

export const checkboxesType = ImmutablePropTypes.listOf(PropTypes.string);

export const childrenType = PropTypes.oneOfType([
	PropTypes.arrayOf(PropTypes.element),
	PropTypes.element.isRequired,
]);

export const columnCellGeneratorType = PropTypes.func;
export const columnHeaderGeneratorType = PropTypes.func;

export const columnType = ImmutablePropTypes.mapContains({
	info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	key: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	sort: PropTypes.string,
});

export const columnsType = arrayOf(
	PropTypes.shape({
		key: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		render: PropTypes.func,
		group: PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
);

export const columnsVisibleType = ImmutablePropTypes.listOf(
	columnType.isRequired,
);

export const contentCreditsType = PropTypes.element;
export const criteriaElementsType = arrayOf(PropTypes.element);
export const criterionType = shape({
	defaultValue: PropTypes.string,
	factory: PropTypes.func.isRequired,
});

export const criteriaGroupType = shape({
	name: PropTypes.string.isRequired,
	criteria: PropTypes.objectOf(criterionType).isRequired,
	key: PropTypes.string.isRequired,
	visibilitySelector: visibilitySelectorType,
});

export const criteriaGroupsType = arrayOf(criteriaGroupType.isRequired);

export const criteriaType = shape({
	criteria: criteriaGroupsType.isRequired,
	quickSearchFallbackCriteria: PropTypes.arrayOf(PropTypes.string).isRequired,
	mapSearchCriterion: PropTypes.func.isRequired,
	shouldFetchQuickSearchCriteria: PropTypes.bool.isRequired,
	quickSearchCriteriaOrder: PropTypes.object,
});

export const criterionLinkType = shape({
	content: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
});

export const criterionTabsType = ImmutablePropTypes.mapOf(
	PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
);

export const currentInputType = ImmutablePropTypes.mapOf(
	PropTypes.any, // validation for values
	PropTypes.string, // validation for keys
);

export const currentInputAutoSuggestType = ImmutablePropTypes.mapContains({
	free_text: PropTypes.string,
	codes: ImmutablePropTypes.listOf(PropTypes.string),
});

export const destinationType = shape({
	destination: shape({
		arrival: shape({
			code: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		destination_country: shape({
			code: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		destination_subdivision_code: PropTypes.string,
		destination_subdivision: shape({
			code: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			country_code: PropTypes.string.isRequired,
		}),
	}).isRequired,
	flights: PropTypes.array.isRequired,
});

export const disclaimersType = instanceOf(Disclaimers);

export const downloadType = shape({
	downloadEnabled: PropTypes.bool.isRequired,
	downloadRoles: PropTypes.arrayOf(PropTypes.string),
	downloadDemoData: PropTypes.object,
});

export const downloadFormatsType = arrayOf(
	PropTypes.shape({
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		label_modal: PropTypes.string.isRequired,
		options: PropTypes.shape({
			csv_delimiter: PropTypes.string.isRequired,
		}),
	}).isRequired,
);

export const dropdownCriterionChoicesType = arrayOf(
	PropTypes.shape({
		key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		value: PropTypes.string.isRequired,
	}),
);

export const dropdownItemsType = arrayOf(
	PropTypes.shape({
		key: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
			.isRequired,
		value: PropTypes.string.isRequired,
	}),
);

export const editorialCommentType = ImmutablePropTypes.mapContains({
	date: PropTypes.string,
	value: PropTypes.string,
});
export const errorType = shape({
	message: PropTypes.string.isRequired,
});

export const faqType = shape({
	title: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
});

export const footerPresetsType = arrayOf(PropTypes.element);
export const footnotesType = instanceOf(Footnotes);
export const footnoteSingleType = PropTypes.element;

export const forecastingTypesType = arrayOf(
	PropTypes.shape({
		key: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	}),
);

export const forecastingInputType = ImmutablePropTypes.mapContains({
	amount: PropTypes.string,
	from_month: PropTypes.number,
	from_year: PropTypes.number,
	to_month: PropTypes.number,
	to_year: PropTypes.number,
	type: PropTypes.string,
});

export const fromToDropdownsType = ImmutablePropTypes.mapContains({
	from_month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	from_year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	to_month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	to_year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const layoutSwitcherType = PropTypes.element;
export const namespaceType = PropTypes.string;
export const newsDisplayIsSummaryType = PropTypes.bool;
export const newsPictureType = ImmutablePropTypes.mapContains({
	uri: PropTypes.string.isRequired,
});
export const newsSourcesType = ImmutablePropTypes.listOf(
	ImmutablePropTypes.mapContains({
		name: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	}),
);

export const newsItemType = ImmutablePropTypes.mapContains({
	editorial_comment: editorialCommentType.isRequired,
	is_exclusive: PropTypes.bool.isRequired,
	is_pro: PropTypes.bool.isRequired,
	news_type: PropTypes.number.isRequired,
	picture: newsPictureType.isRequired,
	published: PropTypes.string.isRequired,
	renderResult: PropTypes.string.isRequired,
	restriction_code: PropTypes.string,
	sources: newsSourcesType.isRequired,
	title: PropTypes.string.isRequired,
	uri: PropTypes.string.isRequired,
});

export const notDeletableType = arrayOf(PropTypes.string);
export const customizeQuickSearchRadiosType = arrayOf(PropTypes.string);
export const customizeQuickSearchType = shape({
	enabled: PropTypes.bool.isRequired,
	defaultInput: ImmutablePropTypes.map,
});
export const customizeQuickSearchSelectedChoicesType =
	ImmutablePropTypes.listOf(PropTypes.string);
export const pagingType = ImmutablePropTypes.mapContains({
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	results: PropTypes.number.isRequired,
	resultsUnfiltered: PropTypes.number,
});

export const searchesType = shape({
	searchesEnabled: PropTypes.bool.isRequired,
	searchAlertEnabled: PropTypes.bool,
	viewBindingEnabled: PropTypes.bool,
});

export const searchType = PropTypes.oneOf([ADVANCED_SEARCH, QUICK_SEARCH]);

export const radioChoicesType = arrayOf(
	PropTypes.shape({
		key: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	}),
);

export const resultRenderConfigType = shape({
	columnHeaderGenerator: columnHeaderGeneratorType.isRequired,
	columnCellGenerator: columnCellGeneratorType.isRequired,
});

export const resultTableConfigType = shape({
	columns: PropTypes.array.isRequired,
	resultRenderConfig: PropTypes.func.isRequired,
	resultsDisplay: PropTypes.func.isRequired,
});

export const resultsType = shape({
	counts: PropTypes.number,
	carriers: PropTypes.number,
	aircraft: PropTypes.number,
});

export const requiredFeatureFlagType = PropTypes.string;
export const requiredRolesType = PropTypes.instanceOf(RequiredRoles);

export const searchInputType = PropTypes.instanceOf(InputSearch);

export const searchTitleType = oneOfType([PropTypes.string, PropTypes.element]);

export const tabSearchType = PropTypes.element;
export const tableGroupType = ImmutablePropTypes.listOf(ImmutablePropTypes.map);
export const quickSearchInputType = ImmutablePropTypes.mapOf(
	PropTypes.string,
	PropTypes.string,
);

export const viewType = PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			order: PropTypes.string,
		}),
	).isRequired,
});

export const viewsType = PropTypes.shape({
	viewsEnabled: PropTypes.bool.isRequired,
	visibilitySelector: visibilitySelectorType,
});

export const warningsType = ImmutablePropTypes.listOf(PropTypes.string);

export const unitsType = arrayOf(
	PropTypes.shape({
		key: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	}),
);

export const utilisationInputType = ImmutablePropTypes.mapContains({
	check_avg_daily_ut: PropTypes.bool,
	check_avg_st_length: PropTypes.bool,
	check_cycles: PropTypes.bool,
	check_hours: PropTypes.bool,
	from_month: PropTypes.number,
	from_year: PropTypes.number,
	to_month: PropTypes.number,
	to_year: PropTypes.number,
	type: PropTypes.string,
	utilisation_data_historical: PropTypes.bool,
	utilisation_data_type_1: PropTypes.bool,
	utilisation_data_type_2: PropTypes.bool,
	utilisation_data_type_aircraft: PropTypes.bool,
});
