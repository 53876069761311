import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setColumns } from "./actions";

export default function useSetColumns(columns) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (columns) {
			dispatch(setColumns(columns));
		}
	}, []);
}
