import PropTypes from "prop-types";
import { namespaceType } from "../../common/types";
import useRequestSearch from "../result/useRequestSearch";

export const RequestSearchInitializer = ({
	namespace,
	showResultsOnInitialPageLoad,
	searchesEnabled,
}) => {
	useRequestSearch(namespace, showResultsOnInitialPageLoad, searchesEnabled);

	return null;
};

RequestSearchInitializer.propTypes = {
	namespace: namespaceType.isRequired,
	showResultsOnInitialPageLoad: PropTypes.bool.isRequired,
	searchesEnabled: PropTypes.bool.isRequired,
};
