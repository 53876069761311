import { applyMiddleware, createStore } from "redux";
import { combineReducers } from "redux-immutable";
import { thunk } from "redux-thunk";
import modalsReducers from "../common/components/modals/reducers";
import toastsReducers from "../common/components/toasts/reducers";
import { createFeatureFlagsReducer } from "../common/feature-flags/reducers";
import hashMiddleware from "../common/hash/middleware";
import { createHashReducers } from "../common/hash/reducers";
import { getInitialSearchState } from "../common/hash/util";
import { createInputReducer } from "../common/input/reducers";
import { layoutReducer } from "../common/layout/reducers";
import { createRolesReducer } from "../common/roles/reducers";
import { favoritesReducer } from "./favorites/reducers";
import { createSearchReducers } from "./reducers";

export function createSearchStore(roles, defaultInput, featureFlags = {}) {
	const { initialHashState, initialInputState } =
		getInitialSearchState(defaultInput);

	const rootReducer = combineReducers({
		favorites: favoritesReducer,
		hash: createHashReducers(initialHashState),
		input: createInputReducer(initialInputState, defaultInput),
		modals: modalsReducers,
		roles: createRolesReducer(roles),
		featureFlags: createFeatureFlagsReducer(featureFlags),
		search: createSearchReducers,
		toasts: toastsReducers,
		layout: layoutReducer,
	});

	return createStore(
		rootReducer,
		applyMiddleware((store) => hashMiddleware(store, defaultInput), thunk),
	);
}
