import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import useModal from "../../../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import { utilisationInputType } from "../../../../common/types";
import { getCriterionModalId } from "../../utils";
import useValidation from "../../validation/useValidation";
import SimpleCheckbox from "../checkboxes/SimpleCheckbox";
import CriterionFooter from "../common/CriterionFooter";
import { CriterionLabel } from "../common/CriterionLabel";
import { FromToDropdowns } from "../dropdown/FromToDropdowns";
import useDropdownPosition from "../useDropdownPosition";
import { useLoadUtilisationSearchRangeData } from "./useLoadUtilisationSearchRangeData";
import { dateRangeCheck } from "./validation";

export const UtilisationSearchRangeComponent = ({
	criterionKey,
	currentInput,
	endpoint,
	label,
	onInputChange,
	onClearInput,
	prefix,
	quickSearch,
}) => {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const { data, loading, error } = useLoadUtilisationSearchRangeData(
		endpoint,
		currentInput,
		onInputChange,
	);
	const { valid } = useValidation(criterionKey);
	const inputId = `${criterionKey}${quickSearch ? "_quick_search" : ""}`;
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);
	const modalId = getCriterionModalId(criterionKey);

	const handleCheckboxToggle = (key) =>
		onInputChange(currentInput.set(key, !currentInput.get(key)));

	const sourceCheckboxes = [
		{
			key: "utilisation_data_historical",
			value: "Actual Data from OEM/Operator",
		},
		{
			key: "utilisation_data_type_aircraft",
			value: "Est/Fcst based on msn",
		},
		{
			key: "utilisation_data_type_2",
			value: "Est/Fcst based on operator/variant",
		},
		{
			key: "utilisation_data_type_1",
			value: "Est/Fcst based on variant only",
		},
	];

	const displayCheckboxes = [
		{ key: "check_hours", value: "Display hours" },
		{ key: "check_cycles", value: "Display cycles" },
		{ key: "check_avg_st_length", value: "Display average stage length" },
		{
			key: "check_avg_daily_ut",
			value: "Display average daily utilisation",
		},
	];

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
	};

	const handleSaveInput = () => {
		onDropdownToggle();
		onCloseModal(modalId);
	};

	const generateCheckboxes = (checkboxes) =>
		checkboxes.map(({ key, value }) => (
			<SimpleCheckbox
				key={key}
				itemKey={key}
				value={value}
				checked={currentInput.get(key, false)}
				onToggle={handleCheckboxToggle}
				inputId={`${inputId}_${key}`}
			/>
		));

	const handleClearInput = () => {
		onClearInput();
		onDropdownToggle();
	};

	const getDropdownContent = () => {
		if (loading || (!error && currentInput.isEmpty())) {
			return <p className="search-range-fields">Loading...</p>;
		}

		if (error) {
			return (
				<p className="search-range-fields error">
					<span className="error-text">
						Failed to load search range data.
					</span>
				</p>
			);
		}

		const compactLayout = (
			<>
				<fieldset className="search-range-fields">
					{generateCheckboxes(sourceCheckboxes)}
				</fieldset>
				<FromToDropdowns
					apiData={data}
					currentInput={currentInput}
					onInputChange={onInputChange}
					dateRangeCheck={dateRangeCheck}
					disclaimer="Search date range is limited to 3 years."
				/>
				<fieldset className="search-range-fields">
					{generateCheckboxes(displayCheckboxes)}
				</fieldset>
			</>
		);

		const fullLayout = (
			<>
				<div className="search-range-fields__container">
					<fieldset className="search-range-fields">
						{generateCheckboxes(sourceCheckboxes)}
					</fieldset>
					<fieldset className="search-range-fields">
						{generateCheckboxes(displayCheckboxes)}
					</fieldset>
				</div>
				<FromToDropdowns
					apiData={data}
					currentInput={currentInput}
					onInputChange={onInputChange}
					dateRangeCheck={dateRangeCheck}
					disclaimer="Search date range is limited to 3 years."
				/>
			</>
		);

		return (
			<>
				{isMobile || quickSearch ? compactLayout : fullLayout}
				<CriterionFooter
					onSaveInput={onDropdownToggle}
					onClearInput={handleClearInput}
				/>
			</>
		);
	};

	const classes = clsx(
		"criterion input-field utilisation-search-range-criterion dropdown grid__col-12",
		{ "criterion-error": !valid },
	);

	return (
		<div
			ref={dropdownRef}
			className={classes}
			data-criterion={true}
			tabIndex={-1}
		>
			<button
				type="button"
				className="dropdown-toggle"
				aria-expanded={dropdownIsOpened}
				data-criterion={true}
				onClick={handleDropdownToggleClick}
			>
				<CriterionLabel label={label} prefix={prefix} />
			</button>
			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={modalId}
						title={label}
						onClose={handleSaveInput}
					>
						{children}
					</ActionsMenu>
				)}
			>
				<div
					className={clsx("dropdown-menu", {
						"dropdown-menu--opened": dropdownIsOpened,
						"dropdown-menu--inverted-y":
							isDropdownBelowViewport && !quickSearch,
						"dropdown-menu--hidden":
							isDropdownHidden && !quickSearch,
					})}
				>
					{getDropdownContent()}
				</div>
			</ConditionalWrapper>
		</div>
	);
};

UtilisationSearchRangeComponent.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	endpoint: PropTypes.string.isRequired,
	currentInput: utilisationInputType.isRequired,
	quickSearch: PropTypes.bool,
	onInputChange: PropTypes.func.isRequired,
	onClearInput: PropTypes.func.isRequired,
	prefix: PropTypes.string,
};
