import { useSelector } from "react-redux";
import { isFeatureFlagEnabled } from "./selectors";

export default function useFeatureFlags(featureFlag) {
	const hasFeatureFlagEnabled = featureFlag
		? useSelector((state) => isFeatureFlagEnabled(state, featureFlag))
		: true;

	return {
		hasFeatureFlagEnabled,
	};
}
