import Immutable from "immutable";
import {
	QUICK_SEARCH_CRITERIA_DELETE,
	QUICK_SEARCH_CRITERIA_DELETE_FAILED,
	QUICK_SEARCH_CRITERIA_FETCHED,
	QUICK_SEARCH_CRITERIA_INPUT,
	QUICK_SEARCH_CRITERIA_INPUT_ARE_DELETED,
	QUICK_SEARCH_CRITERIA_SET_SELECTION_TYPE,
	QUICK_SEARCH_CRITERIA_UPDATE_FAILED,
	QUICK_SEARCH_CRITERIA_UPDATED,
	QUICK_SEARCH_FETCH_CRITERIA,
	QUICK_SEARCH_REFRESH_INITIAL_CRITERIA,
	QUICK_SEARCH_SELECTED_CRITERIA,
	REQUEST_UPDATE_QUICK_SEARCH_CRITERIA,
} from "./actions";
import { quickSearchCriteriaSelectionType } from "./useFetchQuickSearchCriteria";

const initialState = Immutable.Map({
	isUpdating: false,
	updateError: undefined,

	isFetching: true,
	criteria: null,
	selectedCriteria: Immutable.List(),
	input: null,

	isDeleting: false,
	deleteError: undefined,
	refreshInitialCriteria: false,
	criteriaSelectionType: null,
});

export const quickSearchCriteriaReducer = (state = initialState, action) => {
	switch (action.type) {
		case QUICK_SEARCH_FETCH_CRITERIA:
			return state.merge({ isFetching: true });
		case QUICK_SEARCH_CRITERIA_FETCHED:
			return state.merge({
				criteria: action.data.criteria,
				isFetching: false,
			});
		case QUICK_SEARCH_CRITERIA_SET_SELECTION_TYPE:
			return state.merge({
				criteriaSelectionType: action.data.type,
			});
		case REQUEST_UPDATE_QUICK_SEARCH_CRITERIA:
			return state.merge({
				isUpdating: true,
				updateError: undefined,
				refreshInitialCriteria: false,
			});
		case QUICK_SEARCH_CRITERIA_UPDATED:
			return state.merge({
				criteria: state.get("selectedCriteria"),
				criteriaSelectionType: quickSearchCriteriaSelectionType.CUSTOM,
				isUpdating: false,
				updateError: undefined,
			});
		case QUICK_SEARCH_CRITERIA_UPDATE_FAILED:
			return state.merge({
				isUpdating: false,
				createError: action.data.error,
			});
		case QUICK_SEARCH_SELECTED_CRITERIA:
			return state.set("selectedCriteria", action.data.criteria);
		case QUICK_SEARCH_CRITERIA_INPUT:
			return state.set("input", action.data.input);
		case QUICK_SEARCH_CRITERIA_DELETE:
			return state.merge({
				isDeleting: true,
				deleteError: undefined,
			});
		case QUICK_SEARCH_CRITERIA_DELETE_FAILED:
			return state.merge({
				isDeleting: false,
				deleteError: action.data.error,
			});
		case QUICK_SEARCH_CRITERIA_INPUT_ARE_DELETED:
			return state.merge({
				criteria: null,
				selectedCriteria: Immutable.List(),
				input: null,
				isDeleting: false,
				deleteError: undefined,
				refreshInitialCriteria: true,
			});
		case QUICK_SEARCH_REFRESH_INITIAL_CRITERIA:
			return state.merge({
				isFetching: false,
			});
		default:
			return state;
	}
};
