import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../common/hash/actions";
import {
	receiveDeleteViewFailed,
	receiveViewDeleted,
	receiveViewSaved,
	requestDeleteView,
	selectViewToDelete,
} from "./actions";
import { deleteView } from "./api";
import {
	getSelectedView,
	getViews,
	getViewToDelete,
	isLoadingViews,
} from "./selectors";
import { getViewParameters } from "./useSelectView";

export default function useView(namespace) {
	const dispatch = useDispatch();
	const views = useSelector(getViews);
	const loading = useSelector(isLoadingViews);
	const current = useSelector(getSelectedView);
	const viewToDelete = useSelector(getViewToDelete);

	const [deletingError, setDeletingError] = useState(undefined);
	const [viewsForBinding, setViewsForBinding] = useState([]);

	const onSaveViewAs = (view) => {
		dispatch(receiveViewSaved(view));
		dispatch(updateSearchParameters(getViewParameters(view)));
	};

	const onSelectViewToDelete = (id) => {
		dispatch(selectViewToDelete(id));
	};

	const onDeleteView = (id) => {
		setDeletingError(undefined);
		dispatch(requestDeleteView(id));

		deleteView(
			id,
			namespace,
			() => {
				dispatch(receiveViewDeleted(id));
			},
			(error) => {
				dispatch(receiveDeleteViewFailed(id));
				setDeletingError(error.message);
			},
		);
	};

	useEffect(() => {
		if (!views.isEmpty()) {
			setViewsForBinding(
				views
					.valueSeq()
					.map((view) => ({ key: view.id, value: view.name }))
					.toArray(),
			);
		}
	}, [views]);

	return {
		views,
		viewsForBinding,
		loading,
		current,
		deletingError,
		viewToDelete,
		onSaveViewAs,
		onDeleteView,
		onSelectViewToDelete,
	};
}
