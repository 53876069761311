import Immutable from "immutable";
import {
	CLEAR_SEARCH_DATA,
	RESET_SEARCH_DATA,
	UPDATE_SEARCH_INPUT,
} from "../../../common/hash/actions";
import { CLEAR_INPUT } from "../../../common/input/actions";
import { SET_VALIDATION_RESULT, SET_VALIDATION_RULES } from "./actions";

const initialState = Immutable.Map({
	criteria: Immutable.Map(),
	rules: Immutable.Map(),
});

export function validationReducer(state = initialState, action) {
	switch (action.type) {
		case SET_VALIDATION_RESULT:
			return state.set("criteria", action.data.validationResult);
		case SET_VALIDATION_RULES:
			return state.set("rules", action.data.rules);
		case RESET_SEARCH_DATA:
		case CLEAR_SEARCH_DATA:
		case UPDATE_SEARCH_INPUT:
		case CLEAR_INPUT:
			return initialState;
		default:
			return state;
	}
}
