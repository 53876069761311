import PropTypes from "prop-types";
import React from "react";
import { getCriteriaKeys, sortChoices } from "./choices";
import { CriterionChoices } from "./CriterionChoices";
import useSelectedChoices from "./useSelectedChoices";

export function SelectedChoices({ groupCriteria, ...props }) {
	const { choices, choicesEmpty } = useSelectedChoices();
	let selectedChoices = sortChoices(choices);

	if (choicesEmpty) {
		return null;
	}

	if (groupCriteria) {
		const criteria = getCriteriaKeys(groupCriteria);
		choices.filter((v, key) => criteria.includes(key));
		selectedChoices = sortChoices(choices).filter((v, key) =>
			criteria.includes(key),
		);
	}

	if (selectedChoices.size === 0) {
		return null;
	}

	const labels = selectedChoices.entrySeq().map(([key, value]) => {
		const label = value.get("label");
		const criterionChoices = value.get("choices");

		if (criterionChoices.length === 0) {
			return null;
		}

		return (
			<CriterionChoices
				key={key}
				choices={criterionChoices}
				criterionKey={key}
				label={label}
				{...props}
			/>
		);
	});

	return <ul className="search-selected-choices">{labels}</ul>;
}

SelectedChoices.propTypes = {
	groupCriteria: PropTypes.object,
};
