import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CloseButton } from "../../../common/components/modals/CloseButton";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { Switch } from "../../../common/components/Switch";
import { namespaceType, searchesType } from "../../../common/types";
import useSearchState from "../../useSearchState";
import useLoadViews from "../../views/load/useLoadViews";
import useView from "../../views/useView";
import { newView } from "../../views/View";
import { ViewNameInput } from "../../views/ViewNameInput";
import { SearchNameInput } from "../SearchNameInput";
import useSearches from "../useSearches";
import { ViewMode } from "./ViewMode";
import { ViewSelector } from "./ViewSelector";

export function SaveSearch({ modalKey, namespace, searches }) {
	useLoadViews(namespace);
	const { createSearch } = useSearches(namespace);
	const { resultExists, columnsVisible, currentInput } = useSearchState();
	const { viewsForBinding, loading, current } = useView();
	const { onCloseModal, clearModals } = useModal();

	const { searchAlertEnabled, viewBindingEnabled } = searches;

	const [searchState, setSearchState] = useState({
		dirty: false,
		loading: false,
		search: "",
		searchError: undefined,
		view: "",
		viewError: undefined,
		viewId: current
			? current.id
			: viewsForBinding.length > 0
				? viewsForBinding[0].key
				: undefined,
		viewMode:
			loading || viewsForBinding.length > 0
				? "existing"
				: resultExists
					? "new"
					: "none",
		createAlert: false,
	});

	useEffect(() => {
		if (viewsForBinding.length > 0) {
			setSearchState((searchState) => ({
				...searchState,
				viewMode: "existing",
				viewId: viewsForBinding[0]?.key,
			}));
		}
	}, [viewsForBinding]);

	const handleInputChange = (e) => {
		if (searchState.loading) {
			return;
		}

		const inputName = e.target.name;
		const inputValue = e.target.value;

		setSearchState((searchState) => ({
			...searchState,
			[inputName]: inputValue,
			dirty: true,
			[inputName + "Error"]: inputValue ? undefined : "Name is required.",
		}));
	};

	const handleViewModeChange = (e) => {
		if (searchState.loading) {
			return;
		}

		setSearchState((searchState) => ({
			...searchState,
			viewMode: e.target.value,
		}));
	};

	const handleBindToView = (id) => {
		setSearchState((searchState) => ({
			...searchState,
			viewId: id,
		}));
	};

	const handleCreateAlert = () => {
		const createAlert = searchState.createAlert;

		setSearchState((searchState) => ({
			...searchState,
			createAlert: !createAlert,
		}));
	};

	const saveSearch = () => {
		const {
			view: viewName,
			search: name,
			createAlert,
			viewMode,
		} = searchState;
		const fields = currentInput.toJS();

		const view =
			viewBindingEnabled &&
			(viewMode === "new"
				? newView(viewName, columnsVisible)
				: viewMode === "existing" && { id: searchState.viewId });

		const beforeLoadHandler = () => {
			setSearchState((searchState) => ({
				...searchState,
				dirty: false,
			}));
		};

		const successHandler = (data) => {
			setSearchState((searchState) => ({
				...searchState,
				loading: false,
			}));

			if (searchAlertEnabled && createAlert) {
				window.open(
					`/account-management/alerts/saved-search/${data.id}`,
					"_blank",
				);
			}

			clearModals();
		};

		const failureHandler = (error) => {
			if (error.field === "view.name") {
				setSearchState((searchState) => ({
					...searchState,
					viewError: error.message,
					loading: false,
				}));
			} else {
				setSearchState((searchState) => ({
					...searchState,
					searchError: error.message,
					loading: false,
				}));
			}
		};

		createSearch(
			name,
			fields,
			view,
			beforeLoadHandler,
			successHandler,
			failureHandler,
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!searchState.search) {
			setSearchState((searchState) => ({
				...searchState,
				searchError: "Name is required.",
				viewError: undefined,
			}));
			return;
		}

		if (
			searchState.viewMode === "new" &&
			viewBindingEnabled &&
			!searchState.view
		) {
			setSearchState((searchState) => ({
				...searchState,
				searchError: undefined,
				viewError: "Name is required.",
			}));
			return;
		}

		setSearchState((searchState) => ({
			...searchState,
			searchError: undefined,
			viewError: undefined,
			loading: true,
		}));

		saveSearch();
	};

	let viewModes = null;

	if (viewBindingEnabled) {
		const viewMode = searchState.viewMode;
		const error = searchState.viewError;
		const existingDisabled = viewsForBinding.length === 0;
		const newDisabled = !resultExists;

		viewModes = (
			<section className="modal__section">
				<fieldset>
					<ViewMode
						value={viewMode}
						mode="existing"
						id="search-bind-to-existing-view"
						label="Bind to existing view"
						onChange={handleViewModeChange}
						disabled={existingDisabled}
					>
						{!existingDisabled && (
							<ViewSelector
								views={viewsForBinding}
								loading={loading}
								current={current}
								handleBindToView={handleBindToView}
							/>
						)}
					</ViewMode>
					<ViewMode
						value={viewMode}
						mode="new"
						id="search-bind-to-new-view"
						label="Bind to new view"
						onChange={handleViewModeChange}
						disabled={newDisabled}
					>
						{!newDisabled && (
							<ViewNameInput
								error={error}
								onChange={handleInputChange}
							/>
						)}
					</ViewMode>
					<ViewMode
						value={viewMode}
						mode="none"
						id="search-save-without-view"
						label="Save without view"
						onChange={handleViewModeChange}
					/>
				</fieldset>
			</section>
		);
	}

	const title = "Save Search";
	const alertSwitcher = searchAlertEnabled && (
		<section className="modal__section">
			<Switch
				id="create-alert-for-search"
				name="create-alert-for-search"
				label="Alert via Email"
				onChange={handleCreateAlert}
				reverse={true}
			/>
		</section>
	);

	return (
		<Modal modalKey={modalKey} title={title}>
			<div className="modal__content">
				<div className="modal__body">
					<section className="modal__section">
						<SearchNameInput
							error={searchState.searchError}
							onChange={handleInputChange}
						/>
					</section>

					{viewModes}

					{alertSwitcher}
				</div>
			</div>

			<ModalFooter>
				<CloseButton
					classes="btn btn--secondary"
					onClick={onCloseModal}
					modalKey={modalKey}
					label="Cancel"
				/>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleSubmit}
				>
					{searchState.loading ? "Saving..." : "Save"}
				</button>
			</ModalFooter>
		</Modal>
	);
}

SaveSearch.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
	searches: searchesType.isRequired,
};
