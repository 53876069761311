import Immutable from "immutable";
import { ROLES_INIT } from "./actions";

export function createRolesReducer(roles) {
	return function (state = Immutable.Map(roles), action) {
		switch (action.type) {
			case ROLES_INIT:
				return Immutable.Map(action.data.roles);
			default:
				return state;
		}
	};
}
