const ValidationStatus = {
	SUCCESS: "success",
	ERROR: "error",
};

export const FieldStatus = {
	ACTIVE: "active",
	DISABLED: "disabled",
};

export class ValidationResult {
	constructor(status, message, fieldStatus) {
		this.status = status;
		this.message = message;
		this.fieldStatus = fieldStatus;
	}

	isError() {
		return this.status === ValidationStatus.ERROR;
	}

	isSuccess() {
		return this.status === ValidationStatus.SUCCESS;
	}

	isFieldDisabled() {
		return this.fieldStatus === FieldStatus.DISABLED;
	}

	getMessage() {
		return this.message;
	}

	static error = (message = "", fieldStatus = FieldStatus.ACTIVE) => {
		return new ValidationResult(
			ValidationStatus.ERROR,
			message,
			fieldStatus,
		);
	};

	static success = (message = "", fieldStatus = FieldStatus.ACTIVE) => {
		return new ValidationResult(
			ValidationStatus.SUCCESS,
			message,
			fieldStatus,
		);
	};
}
