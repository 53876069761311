import PropTypes from "prop-types";
import React from "react";
import { resultsType } from "../../common/types";
import { numberWithSpaces } from "../utils";

export function SearchResultsPreview({
	results,
	loading,
	error,
	label = "Results",
}) {
	const { counts, carriers, aircraft } = results;

	let content;
	if (loading) {
		content = "Loading...";
	} else if (error || counts === undefined) {
		content = "0 Results";
	} else if (carriers !== undefined) {
		content =
			numberWithSpaces(counts) +
			" aircraft and " +
			numberWithSpaces(carriers) +
			" carrier(s)";
	} else if (aircraft !== undefined) {
		content =
			numberWithSpaces(counts) +
			" records and " +
			numberWithSpaces(aircraft) +
			" aircraft";
	} else {
		content = numberWithSpaces(counts) + ` ${label}`;
	}

	return <span className="search-results-count">{content}</span>;
}

SearchResultsPreview.propTypes = {
	results: resultsType.isRequired,
	loading: PropTypes.bool,
	error: PropTypes.bool,
	label: PropTypes.string,
};
