import PropTypes from "prop-types";
import React from "react";
import { SearchResultsPreview } from "./SearchResultsPreview";
import useAccessResultsPreview from "./useAccessResultsPreview";

export const SearchResultsPreviewContainer = ({ label }) => {
	const { results, loading, error } = useAccessResultsPreview();

	return (
		<SearchResultsPreview
			results={results}
			loading={loading}
			error={error}
			label={label}
		/>
	);
};

SearchResultsPreviewContainer.propTypes = {
	label: PropTypes.string,
};
