import { useDispatch, useSelector } from "react-redux";
import { setDownloadFormat } from "../result/actions";
import { getDownloadFormat } from "../result/selectors";

export default function useDownload() {
	const dispatch = useDispatch();
	const downloadFormat = useSelector(getDownloadFormat);

	const selectFormat = (format) => {
		dispatch(setDownloadFormat(format));
	};

	return { downloadFormat, selectFormat };
}
