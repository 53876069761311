import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { MENU_ACTIONS } from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import {
	actionsButtonDependsOnType,
	searchTitleType,
} from "../../common/types";
import { ActionsMenuButton } from "../actions/ActionsMenuButton";

export function ResultsHeader({
	actionsButtonDependsOn,
	searchTitle,
	resultsPreview,
	pagination,
	hasAdditionalSearch,
}) {
	const { onOpenModal } = useModal();

	return (
		<header
			className={clsx("search-header", {
				"header-additional-search": hasAdditionalSearch,
			})}
		>
			<div className="results-count">
				<h1 className="h5" id="searchTitle">
					{searchTitle}
				</h1>
				{resultsPreview}
			</div>

			{pagination}

			<ActionsMenuButton
				actionsButtonDependsOn={actionsButtonDependsOn}
				onClick={() => onOpenModal(MENU_ACTIONS)}
			/>
		</header>
	);
}

ResultsHeader.propTypes = {
	actionsButtonDependsOn: actionsButtonDependsOnType,
	searchTitle: searchTitleType.isRequired,
	resultsPreview: PropTypes.node.isRequired,
	pagination: PropTypes.node,
	hasAdditionalSearch: PropTypes.bool.isRequired,
};
