import PropTypes from "prop-types";
import React from "react";
import { FormComponent } from "./FormComponent";

export function TextareaField({
	id,
	name,
	label,
	placeholder,
	required,
	errors,
	classes,
	rows,
	register,
}) {
	const component = { id, name, label, required, errors, classes };

	return (
		<FormComponent {...component}>
			<textarea
				{...register(name, { required })}
				id={id}
				placeholder={placeholder}
				rows={rows || 4}
				autoComplete="off"
			/>
		</FormComponent>
	);
}

TextareaField.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool.isRequired,
	errors: PropTypes.object,
	classes: PropTypes.string,
	rows: PropTypes.number,
	register: PropTypes.func.isRequired,
};
