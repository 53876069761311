import { useDispatch, useSelector } from "react-redux";
import { updateSearchParameters } from "../../common/hash/actions";
import { getCurrentHashData } from "../../common/hash/reducers";
import { isFormDirty } from "../reducers";
import { getViews } from "../views/selectors";
import { getViewParameters } from "../views/useSelectView";
import {
	createSearchFailed,
	deleteSearchFailed,
	requestCreateSearch,
	requestDeleteSearch,
	searchCreated,
	searchDeleted,
	searchUpdated,
	selectSearch,
	selectSearchToDelete,
} from "./actions";
import { api } from "./api";
import {
	getSearchDeleteError,
	getSearches,
	getSearchToDelete,
	getSelectedSearch,
	isCreatingSearch,
	isDeletingSearch,
} from "./selectors";

export default function useSearches(namespace) {
	const dispatch = useDispatch();
	const creatingSearch = useSelector(isCreatingSearch);
	const searches = useSelector(getSearches);
	const deleting = useSelector(isDeletingSearch);
	const deletingError = useSelector(getSearchDeleteError);
	const searchToDelete = useSelector(getSearchToDelete);
	const current = useSelector(getSelectedSearch);
	const searchDirty = useSelector(isFormDirty);
	const views = useSelector(getViews);
	const hashData = useSelector(getCurrentHashData).toJS();

	const onSelectSearch = (search, isInitial = false, storedView = null) => {
		const viewFromHash = hashData.parameters?.selectedViewId;
		search.viewId = determineSearchViewId(
			isInitial,
			viewFromHash,
			storedView,
			search,
		);
		const view = determineView(
			isInitial,
			viewFromHash,
			search,
			storedView,
			views,
		);
		const viewParameters = determineViewParameters(
			view,
			isInitial,
			viewFromHash,
			storedView,
		);
		dispatch(selectSearch(search));
		view && dispatch(updateSearchParameters(viewParameters));
	};

	const onSelectSearchToDelete = (id) => {
		dispatch(selectSearchToDelete(id));
	};

	const determineSearchViewId = (
		isInitial,
		viewFromHash,
		storedView,
		search,
	) => {
		if (isInitial && viewFromHash) {
			return viewFromHash;
		} else if (storedView?.selectedViewId) {
			return storedView.selectedViewId;
		}
		return search.viewId;
	};

	const determineView = (
		isInitial,
		viewFromHash,
		search,
		storedView,
		views,
	) => {
		return views.get(
			(isInitial ? viewFromHash : null) ||
				search.viewId ||
				storedView?.selectedViewId,
		);
	};

	const determineViewParameters = (
		view,
		isInitial,
		viewFromHash,
		storedView,
	) => {
		const viewParameters = getViewParameters(view);
		if (isInitial && view && viewFromHash) {
			viewParameters.ascending = hashData.parameters.ascending;
			viewParameters.sort = hashData.parameters.sort;
			viewParameters.columns = hashData.parameters.columns;
		} else if (view && storedView) {
			for (const [key, value] of Object.entries(storedView)) {
				viewParameters[key] = value;
			}
		}
		return viewParameters;
	};

	const createSearch = (
		name,
		fields,
		view = undefined,
		onBeforeLoad = undefined,
		onSuccess = undefined,
		onFailure = undefined,
	) => {
		// Only create one search at a time.
		if (!creatingSearch) {
			dispatch(requestCreateSearch());

			if (onBeforeLoad) {
				onBeforeLoad();
			}

			api(namespace).create(
				name,
				fields,
				view,
				(data) => {
					dispatch(searchCreated(data));
					if (onSuccess) {
						onSuccess(data);
					}
				},
				(error) => {
					dispatch(createSearchFailed(error));
					if (onFailure) {
						onFailure(error);
					}
				},
			);
		}
	};

	const updateSearch = (search, fields) => {
		api(namespace).update(
			search.id,
			search.name,
			fields,
			(data) => {
				dispatch(searchUpdated(data));
			},
			(error) => {
				// Ignore error message.
				console.error(error.message);
			},
		);
	};

	const deleteSearch = (id) => {
		// Only delete one search at a time.
		if (!deleting) {
			dispatch(requestDeleteSearch(id));
			api(namespace).delete(
				id,
				() => {
					dispatch(searchDeleted(id));
				},
				(error) => {
					dispatch(deleteSearchFailed(id, error));
				},
			);
		}
	};

	return {
		current,
		searches,
		deleting,
		deletingError,
		searchDirty,
		searchToDelete,
		createSearch,
		updateSearch,
		deleteSearch,
		onSelectSearch,
		onSelectSearchToDelete,
	};
}
