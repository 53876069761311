import PropTypes from "prop-types";
import React from "react";

export function DownloadLink({
	format,
	label,
	getDownloadUrl,
	onLinkClick,
	classes = "",
}) {
	const { type } = format;

	const extraParameters = format.options
		? {
				csv_delimiter: format.options.csv_delimiter,
			}
		: {};

	const downloadUrl = getDownloadUrl(type, extraParameters);

	return (
		<a
			href={downloadUrl}
			target="_blank"
			className={classes}
			onClick={onLinkClick}
			rel="noreferrer"
		>
			<span>{label}</span>
		</a>
	);
}

DownloadLink.propTypes = {
	format: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	getDownloadUrl: PropTypes.func.isRequired,
	onLinkClick: PropTypes.func.isRequired,
	classes: PropTypes.string,
};
