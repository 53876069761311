import PropTypes from "prop-types";
import React from "react";
import { columnType } from "../../../../common/types";

export function SearchResultsHeaderCell({
	column,
	columnRenderers,
	onSortClick,
}) {
	const columnKey = column.get("key");
	const currentSort = column.get("sort");

	const handleSortClick = () => {
		onSortClick(columnKey, currentSort);
	};

	if (columnRenderers[columnKey] === undefined) {
		return (
			<th key={columnKey}>{`No renderer for column '${columnKey}'`}</th>
		);
	} else {
		let ariaSort = "none";

		if (currentSort === "asc") {
			ariaSort = "ascending";
		} else if (currentSort === "desc") {
			ariaSort = "descending";
		}

		return (
			<th key={columnKey} aria-sort={ariaSort}>
				<button
					type="button"
					onClick={handleSortClick}
					className="btn--sort"
				>
					{column.get("label")}
				</button>
			</th>
		);
	}
}

SearchResultsHeaderCell.propTypes = {
	column: columnType.isRequired,
	columnRenderers: PropTypes.object.isRequired,
	onSortClick: PropTypes.func.isRequired,
};
