import jquery from "jquery";
import { failureToError } from "../api";

export function api(namespace) {
	return {
		create: function (name, fields, view, onSuccess, onError) {
			const data = JSON.stringify({
				name,
				input: fields,
				view,
			});
			jquery
				.ajax({
					type: "POST",
					url: `/api/v1/me/searches/${namespace}`,
					data,
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(failureToError(onError, "Failed to create search."));
		},

		load: function (onSuccess, onError = () => {}) {
			jquery
				.ajax({
					type: "GET",
					url: `/api/v1/me/searches/${namespace}`,
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(failureToError(onError, "Failed to load searches."));
		},

		update: function (id, name, fields, onSuccess, onError) {
			const data = JSON.stringify({
				name,
				input: fields,
			});
			jquery
				.ajax({
					type: "PUT",
					url: `/api/v1/me/searches/${namespace}/${id}`,
					data,
					contentType: "application/json; charset=utf-8",
					dataType: "json",
				})
				.done((data) => {
					onSuccess(data);
				})
				.fail(failureToError(onError, "Failed to update search."));
		},

		delete: function (id, onSuccess, onError) {
			jquery
				.ajax({
					type: "DELETE",
					url: `/api/v1/me/searches/${namespace}/${id}`,
				})
				.done(onSuccess)
				.fail(failureToError(onError, "Failed to delete search."));
		},
	};
}
