import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { criterionTabsType } from "../../../../common/types";

export default function CriterionTabs({
	tabsKey,
	input,
	items,
	currentInput,
	onInputChange,
}) {
	if (!items) {
		return null;
	}

	const onChange = (value) => {
		onInputChange(currentInput.set(tabsKey, value));
	};

	const tabs = items.map((item) => {
		const { key, value } = item;
		const classes = clsx("btn--tab", { active: key === input });

		return (
			<button
				key={key}
				type="button"
				className={classes}
				data-criterion={true}
				onClick={() => onChange(key)}
			>
				{value}
			</button>
		);
	});

	return <div className="criterion__tabs flex">{tabs}</div>;
}

CriterionTabs.propTypes = {
	tabsKey: PropTypes.string.isRequired,
	input: PropTypes.string.isRequired,
	items: PropTypes.array,
	currentInput: criterionTabsType.isRequired,
	onInputChange: PropTypes.func.isRequired,
};
