import PropTypes from "prop-types";
import React, { useState } from "react";

export function Accordion({ accordionKey, label, headerContent, children }) {
	const [groupCollapsed, setGroupCollapsed] = useState(false);

	return (
		<div className="accordion" aria-expanded={!groupCollapsed}>
			<div className="accordion-header">
				<button
					type="button"
					className="btn accordion-toggle"
					id={accordionKey}
					aria-controls={`section-${accordionKey}`}
					onClick={() => setGroupCollapsed(!groupCollapsed)}
				>
					<span>{label}</span>
				</button>
				{headerContent}
			</div>

			<section
				className="accordion-content"
				id={`section-${accordionKey}`}
				aria-labelledby={accordionKey}
			>
				{children}
			</section>
		</div>
	);
}

Accordion.propTypes = {
	accordionKey: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	headerContent: PropTypes.element,
	label: PropTypes.string.isRequired,
};
