import { useEffect } from "react";

export default function useMatchInputToChoices(
	currentInput,
	choices,
	onInputChange,
) {
	useEffect(() => {
		if (!choices || currentInput.isEmpty()) {
			return;
		}

		const newInput = currentInput.filter((input) =>
			choices.find((choice) => choice.key === input),
		);
		onInputChange(newInput);
	}, [choices]);
}
