import Immutable from "immutable";

const View = Immutable.Record({
	id: undefined,
	name: undefined,
	columns: [],
});

export default View;

export function prepareColumns(columns) {
	return columns
		.map((column) => {
			return {
				name: column.get("key"),
				order: column.get("sort"),
			};
		})
		.toArray();
}

export function mergeColumns(columnsVisible, columnsInput) {
	const sort = prepareColumns(columnsVisible).find(
		(column) => column.order !== null,
	);

	const input = columnsInput.split(",").map((column) => {
		return {
			name: column,
			order: null,
		};
	});

	for (let i = 0, l = input.length; i < l; i++) {
		if (input[i].name === sort.name) {
			input.splice(i, 1, sort);
			break;
		}
	}

	return input;
}

export function newView(name, columns) {
	return new View({
		name,
		columns: prepareColumns(columns),
	});
}
