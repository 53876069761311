import Immutable from "immutable";
import {
	SEARCH_CLEAR_RESULT,
	SEARCH_GET_RESULT,
	SEARCH_PRINT_RESULTS,
	SEARCH_PRINT_RESULTS_ERROR,
	SEARCH_PRINT_RESULTS_LOADING,
	SEARCH_REQUEST_PRINT_RESULTS,
	SEARCH_RESULT,
	SEARCH_RESULT_DOWNLOAD_FORMAT,
	SEARCH_RESULT_FAILED,
} from "./actions";

const initialState = Immutable.Map({
	downloadFormat: Immutable.Map(),
	error: undefined,
	loading: false,
	result: undefined,

	printResults: undefined,
	printResultsLoaded: false,
	printResultsLoading: false,
	printResultRequest: false,
});

export function resultReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_GET_RESULT:
			return state.set("loading", true);
		case SEARCH_RESULT:
			return state.merge({
				result: Immutable.fromJS(action.data.result),
				loading: false,
				error: undefined,
			});
		case SEARCH_RESULT_FAILED:
			return state.merge({
				loading: false,
				error: action.data.error,
			});
		case SEARCH_RESULT_DOWNLOAD_FORMAT:
			return state.merge({
				downloadFormat: Immutable.Map(action.data.format),
			});
		case SEARCH_REQUEST_PRINT_RESULTS:
			return state.merge({
				printResultRequest: true,
				printResultsLoaded: false,
			});
		case SEARCH_PRINT_RESULTS_LOADING:
			return state.merge({
				printResultsLoading: true,
			});
		case SEARCH_PRINT_RESULTS:
			return state.merge({
				printResults: Immutable.fromJS(action.data.result),
				printResultsLoaded: true,
				printResultsLoading: false,
				printResultRequest: false,
			});
		case SEARCH_PRINT_RESULTS_ERROR:
			return state.merge({
				printResultsLoading: false,
				printResultsLoaded: false,
				printResultRequest: false,
			});
		case SEARCH_CLEAR_RESULT:
			return state.merge({
				result: undefined,
			});
		default:
			return state;
	}
}
