import Immutable from "immutable";
import { useEffect, useState } from "react";

export default function useSelectAll(
	choices,
	currentInput,
	onInputChange,
	mandatoryChoices = [],
) {
	const isChecked = (key) => currentInput.includes(key);
	const isCheckedAll = (choices) =>
		choices.length > 0 &&
		choices.every((element) => isChecked(element.key));

	const [allChecked, setAllChecked] = useState(() => isCheckedAll(choices));

	useEffect(() => {
		setAllChecked(isCheckedAll(choices));
	}, [currentInput, choices]);

	const onSelectAll = (value) => {
		setAllChecked(value);

		let newInput;

		for (const key in choices) {
			let choiceKey = choices[key].key;

			if (currentInput instanceof Immutable.List) {
				let choiceIndex = currentInput.indexOf(choiceKey);

				if (value) {
					if (!isChecked(choiceKey)) {
						newInput = currentInput.push(choiceKey);
					}
				} else {
					if (
						isChecked(choiceKey) &&
						!mandatoryChoices.includes(choiceKey)
					) {
						newInput = currentInput.delete(choiceIndex);
					}
				}
			} else if (currentInput instanceof Immutable.Set || Immutable.Map) {
				if (value) {
					if (!isChecked(choiceKey)) {
						newInput = currentInput.add(choiceKey);
					}
				} else {
					if (
						isChecked(choiceKey) &&
						!mandatoryChoices.includes(choiceKey)
					) {
						newInput = currentInput.delete(choiceKey);
					}
				}
			}

			currentInput = newInput ? newInput : currentInput;
		}

		onInputChange(newInput);
	};

	return {
		allChecked,
		onSelectAll,
	};
}
