import React from "react";
import {
	MODAL_EDIT_VIEW,
	MODAL_LOAD_VIEW,
	MODAL_SAVE_VIEW,
} from "../../common/components/modals/constants";
import useModal from "../../common/components/modals/useModal";
import { namespaceType } from "../../common/types";
import useSearchState from "../useSearchState";
import useSaveView from "./useSaveView";
import { prepareColumns } from "./View";
import { ViewActionsDropdown } from "./ViewActionsDropdown";

export function ViewActions({ namespace }) {
	const { current, viewDirty, onSaveView } = useSaveView(namespace);
	const { columnsVisible } = useSearchState([""]);
	const { onOpenModal } = useModal();

	const saveViewEnabled = current ? current && viewDirty : false;

	const handleEditViewClick = (e) => {
		e.currentTarget.blur();
		onOpenModal(MODAL_EDIT_VIEW);
	};

	const onSaveViewAsClick = () => {
		onOpenModal(MODAL_SAVE_VIEW);
	};

	const onSaveViewClick = () => {
		const columns = prepareColumns(columnsVisible);
		onSaveView(columns);
	};

	const onLoadViewClick = () => {
		onOpenModal(MODAL_LOAD_VIEW);
	};

	return (
		<div className="btn-group-divided btn-group-toggle">
			<button
				id="btn--edit-columns"
				className="btn btn--secondary btn--has-icon btn--edit-view"
				onClick={handleEditViewClick}
			>
				<span>Edit View</span>
			</button>

			<ViewActionsDropdown
				saveViewEnabled={saveViewEnabled}
				onSaveViewAsClick={onSaveViewAsClick}
				onSaveViewClick={onSaveViewClick}
				onLoadViewClick={onLoadViewClick}
			/>
		</div>
	);
}

ViewActions.propTypes = {
	namespace: namespaceType.isRequired,
};
