import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Scrollbar from "react-scrollbars-custom";
import { childrenType } from "../../../../common/types";
import { toggleScrollbarVisibility } from "../toggleScrollbarVisibility";

export function ResultsScrollbar({ children, forPrint, tableClass }) {
	toggleScrollbarVisibility();

	const classes = clsx("results", tableClass, {
		"results-for-print": forPrint,
	});

	return (
		<Scrollbar
			createContext={!forPrint}
			renderer={(props) => {
				const { elementRef, ...restProps } = props;
				return (
					<div {...restProps} ref={elementRef} className={classes} />
				);
			}}
			wrapperProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							className="results-scroller-wrapper"
						/>
					);
				},
			}}
			scrollerProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							className="results-scroller"
						/>
					);
				},
			}}
			contentProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							className="results-scroller-content"
						>
							{children}
						</div>
					);
				},
			}}
			trackXProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							className="results-scroller-track-x"
						/>
					);
				},
			}}
			thumbXProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							className="results-scroller-thumb-x"
						/>
					);
				},
			}}
			trackYProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<span
							{...restProps}
							ref={elementRef}
							className="results-scroller-track-y"
						/>
					);
				},
			}}
			thumbYProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<span
							{...restProps}
							ref={elementRef}
							className="results-scroller-thumb-y"
						/>
					);
				},
			}}
			noScrollY={true}
		/>
	);
}

ResultsScrollbar.propTypes = {
	createContext: PropTypes.bool,
	tableClass: PropTypes.string,
	children: childrenType.isRequired,
	forPrint: PropTypes.bool,
	elementRef: PropTypes.object,
};
