import PropTypes from "prop-types";
import React from "react";
import { namespaceType } from "../../../common/types";
import { EditViewActions } from "./EditViewActions";

export function EditViewFooter({
	columnsInput,
	namespace,
	allChecked,
	onInvertClick,
	onSaveClick,
	onSelectAll,
	onUseDefaultClick,
}) {
	return (
		<footer className="modal__actions">
			<div className="actions__container">
				<div className="actions__group">
					<EditViewActions
						namespace={namespace}
						columnsInput={columnsInput}
					/>

					<div className="columns__select-all checkbox-input">
						<input
							type="checkbox"
							id="select-all-columns"
							name="select-all-columns"
							checked={allChecked}
							onChange={(e) => onSelectAll(e.target.checked)}
						/>
						<label htmlFor="select-all-columns">Select all</label>
					</div>
				</div>

				<div className="actions__group">
					<button
						className="btn btn--tertiary btn--divided btn--invert-selection btn--divided"
						onClick={onInvertClick}
					>
						Invert selection
					</button>

					<button
						className="btn btn--tertiary btn--divided"
						onClick={onUseDefaultClick}
					>
						Use default
					</button>
					<button className="btn btn--primary" onClick={onSaveClick}>
						Save
					</button>
				</div>
			</div>
		</footer>
	);
}

EditViewFooter.propTypes = {
	allChecked: PropTypes.bool.isRequired,
	columnsInput: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
	onInvertClick: PropTypes.func.isRequired,
	onSaveClick: PropTypes.func.isRequired,
	onSelectAll: PropTypes.func.isRequired,
	onUseDefaultClick: PropTypes.func.isRequired,
};
