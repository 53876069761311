import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Loader } from "../../../common/components/Loader";
import { CloseButton } from "../../../common/components/modals/CloseButton";
import { MODAL_DELETE_SEARCH } from "../../../common/components/modals/constants";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import { useLocalSearchView } from "../../../common/useLocalSearchView";
import { visibleOnUserInput } from "../../utils";
import { SearchNameInput } from "../SearchNameInput";
import useSearches from "../useSearches";
import { SearchItem } from "./SearchItem";
import useLoadSearches from "./useLoadSearches";

export function LoadSearch({
	modalKey,
	namespace,
	searchAlertEnabled,
	searchesEnabled,
}) {
	const {
		searches,
		current,
		deleting,
		deletingError,
		onSelectSearch,
		onSelectSearchToDelete,
	} = useSearches(namespace);
	const { fetching, fetchError } = useLoadSearches(
		namespace,
		searchesEnabled,
	);
	const [selected, setSelected] = useState(current || undefined);
	const [value, setValue] = useState("");
	const { onOpenModal, onCloseModal, clearModals } = useModal();
	const { getStoredSearchView } = useLocalSearchView(namespace);

	const handleSelect = (search) => {
		setSelected(search);
	};

	const handleInputChange = (e) => {
		setValue(e.target.value);
	};

	const handleLoadClick = () => {
		const [defaultSearch] = searches.values();
		onSelectSearch(selected || defaultSearch, false, getStoredSearchView());
		clearModals();
	};

	const handleDelete = (id) => {
		onSelectSearchToDelete(id);
		onOpenModal(MODAL_DELETE_SEARCH);
	};

	let body;
	let buttons;
	const loading = fetching || deleting;

	if (loading) {
		body = <Loader label="Loading searches..." />;
	} else if (fetchError) {
		body = (
			<p className="error-text" role="alert">
				An error occurred: {fetchError.message}
			</p>
		);
		buttons = <CloseButton onClick={onCloseModal} modalKey={modalKey} />;
	} else if (searches.isEmpty()) {
		body = <p>You have not saved any searches yet.</p>;
		buttons = <CloseButton onClick={onCloseModal} modalKey={modalKey} />;
	} else {
		const searchesList = searches
			.valueSeq()
			.map((search, index) => {
				const visible = visibleOnUserInput(search.name, value);

				return (
					visible && (
						<SearchItem
							key={search.id}
							search={search}
							index={index}
							current={current}
							selected={selected}
							error={deletingError}
							searchAlertEnabled={searchAlertEnabled}
							handleSelect={handleSelect}
							handleDelete={handleDelete}
						/>
					)
				);
			})
			.toArray();

		body = (
			<div className="modal__body">
				<SearchNameInput onChange={handleInputChange} />

				<fieldset
					className={clsx("radio-list", {
						"list-scrollable": searchesList.length > 5,
					})}
				>
					{searchesList}
				</fieldset>
			</div>
		);

		buttons = (
			<>
				<CloseButton
					classes="btn btn--secondary"
					onClick={onCloseModal}
					modalKey={modalKey}
					label="Cancel"
				/>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleLoadClick}
				>
					Load Search
				</button>
			</>
		);
	}

	const alignContent = clsx({
		"modal__container--center": loading || fetchError || searches.isEmpty(),
	});

	return (
		<Modal
			modalKey={modalKey}
			title="Load Search"
			alignContent={alignContent}
		>
			<div
				className={clsx("modal__content", {
					loading,
					error: fetchError,
				})}
			>
				{body}
			</div>

			<ModalFooter>{buttons}</ModalFooter>
		</Modal>
	);
}

LoadSearch.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
	searchAlertEnabled: PropTypes.bool,
	searchesEnabled: PropTypes.bool,
};
