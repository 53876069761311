export const INPUT_CHANGED = "INPUT_CHANGED";
export const INPUT_FIELD_CHANGED = "INPUT_FIELD_CHANGED";
export const CLEAR_INPUT = "CLEAR_INPUT";
export const SET_DEFAULT_INPUT = "SET_DEFAULT_INPUT";

export function inputChanged(input) {
	return {
		type: INPUT_CHANGED,
		data: {
			input,
		},
	};
}

export function inputFieldChanged(criterionKey, input) {
	return {
		type: INPUT_FIELD_CHANGED,
		data: {
			criterionKey,
			input,
		},
	};
}

export function clearInput() {
	return {
		type: CLEAR_INPUT,
		data: {},
	};
}

export function setDefaultInput(input) {
	return {
		type: SET_DEFAULT_INPUT,
		data: {
			input,
		},
	};
}
