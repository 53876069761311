import PropTypes from "prop-types";
import React from "react";

export function PagingLink({
	page,
	selectedPage,
	onPageSelected,
	navBtn,
	children,
}) {
	const handleClick = (page) => {
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
		onPageSelected(page);
	};

	if (page === selectedPage) {
		return <span aria-current="true">{children}</span>;
	} else if (navBtn) {
		return (
			<button
				type="button"
				className="btn--nav"
				onClick={() => handleClick(page)}
			>
				{children}
			</button>
		);
	} else {
		return (
			<button type="button" onClick={() => handleClick(page)}>
				{children}
			</button>
		);
	}
}

PagingLink.propTypes = {
	page: PropTypes.number.isRequired,
	selectedPage: PropTypes.number.isRequired,
	onPageSelected: PropTypes.func.isRequired,
	navBtn: PropTypes.bool,
	children: PropTypes.node,
};
